@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  padding: 10px;
  border: 1px solid #ebeaea;
  border-radius: 4px;
  cursor: pointer;
  transition: all .3s linear;

  &:hover{
    border-color: $jazzy_jade;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & .header {
    padding: 0 0 11px 3px;
    height: 33px;
    width: 100%;
    @include display-flex(space-between);

    & span {
      @include fonts(500, 12px, 14px, $accent);
      white-space: nowrap;
      overflow: hidden;
      padding-right: 10px;
      text-overflow: ellipsis;
    }

    & .icon {
      width: 24px;
      height: 24px;
      background-color: #787e91;
      border-radius: 4px;
      @include display-flex;
      transition: all .3s linear;

      &:hover{
        background: white;
        & svg{
          color: $form_red;
        }
      }

      & svg{
        color: white;
        transition: all .3s linear;
      }
    }
  }

  & .content {
    @include display-flex;
    padding: 0 8px 0 10px;

    & .left_block {
      border: 1px solid #ebeaea;
      @include display-flex;
      width: 77px;
      height: 77px;
      background-color: white;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 7px;
      padding: 0 8px;

      & img {
        width: 71px;
        height: 71px;
        object-fit: contain;
      }
    }

    & .right_block {
      width: 188px;
      @include display-flex;

      & button {
        @include display-flex;
        flex-direction: column;
        border: none;
        background-color: transparent;
        padding: 0 21px;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid #f5f5f5;
        }

        & svg {
          margin-bottom: 8px;
          color: #DADEE6;
        }

        &.active svg {
          color: $jazzy_jade;
        }

        & span {
          white-space: nowrap;
          @include fonts(400, 12px, 14px, #787e91);
        }
      }
    }
  }
}
