@import "src/styles/colors";
@import "src/styles/mixins";

.streamers {
  width: 100%;
  height: 447px;
  background: #fff4ea;
  margin-top: 91px;

  @include display-flex;

  & .left_side {
    padding: 74px 0 0 48px;
    width: 484px;
    height: 100%;

    & h1 {
      @include fonts(700, 40px, 47px, $accent);
    }

    & p {
      margin-top: 15px;
      width: 416px;
      height: 66px;

      @include fonts(400, 22px, 33px, #969696);
    }

    & img {
      margin-top: 19px;

      width: 251px;
      height: 100px;
      object-fit: cover;
    }
  }

  & .slider {
    padding-top: 58px;
    width: calc(100% - 484px);
    height: 100%;
    position: relative;
    overflow: hidden;

    @include display-flex(flex-start, center);
    flex-direction: column;
  }

  @media screen and (max-width: 500px) {
    height: 547px;
    margin-top: 46px;

    @include display-flex(flex-start);
    flex-direction: column;

    & .left_side {
      padding: 43px 16px 27px;
      width: 100%;
      height: 249px;

      & h1 {
        @include fonts(700, 24px, 28px, $accent);
      }

      & p {
        margin-top: 12px;
        width: 100%;
        height: 48px;
        @include fonts(400, 16px, 24px, #969696);
      }

      & img {
        margin-top: 18px;

        width: 184px;
        height: 73px;
      }
    }

    & .slider {
      padding-top: 0;
      width: 100%;
      height: calc(100% - 249px);
      position: relative;
      overflow: hidden;

      @include display-flex(flex-start, center);
      flex-direction: column;
    }
  }
}
