@import "src/styles/colors";
@import "src/styles/mixins";

.container{
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  line-height: 0;
  @include display-flex(flex-start);
  @include tablet {
    width: 740px;
  }
  @include desktop{
    width: 1165px;
    padding: 0 32px;
  }
  .label {
    position: relative;
    .icon {
      position: absolute;
      z-index: 0;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }
    .select {
      position: relative;
      z-index: 1;
      width: 130px;
      height: 36px;
      padding: 9px 9px 9px 30px;
      border: 1px solid #979797;
      border-radius: 6px;
      background-color: transparent;
      margin-right: 19px;
      @include fonts(500, 14px, 16px, #979797);

      .option{
        @include fonts(500, 14px, 16px, #979797);
      }
    }
  }
}
