@import "src/styles/colors";
@import "src/styles/mixins";

.event {
  padding: 22px 0 0 0;
  background: transparent;
  position: relative;
  max-width: 1126px;
  width: 100%;
  margin: 0 auto;
  //z-index: -1;

  @media screen and (max-width: 500px) {
    padding: 33px 0 66px 0;
  }
}
