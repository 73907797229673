@import "src/styles/colors";
@import "src/styles/mixins";

.dateField {
  width: calc(50% - 12px);
}

.title {
  display: flex;
  align-items: center;
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;
  @media screen and (max-width: 500px) {
    margin-bottom: 18px;
  }
}

.gallery {
  margin-top: 16px;
  margin-bottom: 5px;
  @include fonts(400, 14px, 20px, $carbon);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: space-between;

  & div {
    margin-right: 0;
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    gap: 0;
    row-gap: 12px;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 15px;
  }
  & div {
    margin-right: 0;
  }
}

.textarea {
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0;

    & textarea {
      width: 100%;
      height: 89px;
    }
  }
}

.eventInner {
  max-height: 44px;
  padding: 10px 12px;
  overflow: hidden;
  transition: max-height 0.3s ease;

  &.active {
    max-height: 600px;
  }
}

.eventWrap {
  margin-bottom: 22px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.input {
  & input,
  & select {
    width: 355px;
  }
  @media screen and (max-width: 500px) {
    & input,
    & select {
      width: 100%;
    }
  }
}

.menu {
  position: absolute;
  top: 100%;
  right: calc(100% - 20px);
  background-color: white;
  z-index: 3;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.menuItem {
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  & span {
    font-size: 14px;
    line-height: 1;
  }

  &:hover {
    background-color: $jazzy_jade;
  }
}

.menuIcon {
  flex: 0 0 auto;
  min-width: 20px;
  font-size: 12px;
  margin-right: 5px;
}

.actionButton {
  border-radius: 4px;
  background-color: #e1e1e1;
  width: 20px;
  height: 20px;
  margin-left: auto;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    transform: translateY(-4px);
    font-size: 20px;
    color: #9f9f9f;
  }
}
