@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  width: 410px;
  padding: 24px 0 24px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  @include desktop {
    width: 784px;

  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & input,
    select,
    textarea {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;

  @media screen and (max-width: 1050px) {
    margin: 12px 0 14px;
    font-size: 14px;
  }
}

.currency_block{
  margin-right: 24px;
  @include display-flex;
  flex-wrap: wrap;
  column-gap: 63.3px;
  row-gap: 16px;

  & .currency{
    width: 136px;
    height: 104px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
    @include display-flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;

    &:hover{
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    }

    & span{
      @include fonts(400, 16px, 24px, $carbon);
    }
  }

  @media screen and (max-width: 1050px){
    margin: 14px auto 25px;
    @include display-flex(flex-start);
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 13px;
    width: 337px;

    & .currency{
      width: 96px;
      height: 89px;
      gap: 5px;
      cursor: pointer;

      &:hover{
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
      }

      & span{
        font-size: 14px;
      }

      & img{
        width: 24px;
      }
    }
  }
}
