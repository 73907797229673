@import "src/styles/colors";
@import "src/styles/mixins";

.container {
}

.footer {
  padding: 50px 53px;
  border-top: 1px solid $carbon;
  border-bottom: 1px solid $carbon;

  @media screen and (max-width: 500px) {
    padding: 13px 16px 12px;
  }
}

.footer_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  padding: 0;
  @include fonts(700, 26px, 20px, $carbon);

  @media screen and (max-width: 500px) {
    @include fonts(700, 18px, 20px, $carbon);
  }
}

.link {
  @include fonts(400, 18px, 20px, $carbon);
  transition: 0.1s all 0.1s linear;
  margin: 0 20px;

  &:hover {
    font-weight: 500;
    color: $carbon;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.socials {
  display: flex;
}

.social {
  color: $carbon;
  display: flex;
  justify-content: center;
  align-items: center;
  @include circle(44px, 1px, $carbon);
  margin: 0 10px;
  transition: 0.1s all 0.1s linear;

  & svg {
    width: 20px;
  }

  &:hover {
    background-color: $carbon;
    color: #fff;
  }

  @media screen and (max-width: 500px) {
    @include circle(34px, 1px, $carbon);
    margin: 0 8px;
    transition: 0.1s all 0.1s linear;

    & svg {
      width: 18px;
    }
  }
}

.subFooter {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 12px 0;
    @include fonts(400, 14px, 20px, #000000);
  }
}
