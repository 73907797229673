@import "src/styles/colors";
@import "src/styles/mixins";

.sidebar {
  width: 354px;
  height: 265px;
  padding: 39px 21px 30px;
  background-color: #fff;
  border: 1px solid #EAECEE;
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.02);
  margin-bottom: 15px;


  .title {
    @include fonts(500, 20px, 24px, $main_text);
  }
  .text {
    margin-top: 29px;
    @include display-flex(space-between);

    .info{
      @include fonts(400, 18px, 24px, #4F4F4F);
      opacity: 0.8;
    }
  }
  .line{
    height: 1px;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.29);
    margin-top: 25px;
  }
  .total {
    margin-top: 18px;
    @include display-flex(space-between);

    .totalInfo{
      @include fonts(600, 20px, 23px, $main_text);
    }
  }
}
.btn{
  width: 100%;
  height: 60px;
  background-color: $accent;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  @include fonts(500, 20px, 28px, #fff);
}
