@import "src/styles/colors";
@import "src/styles/mixins";

.header {
  background: linear-gradient(
    180deg,
    #f8f5e6 32.69%,
    rgba(248, 245, 230, 0.17) 100%
  );
  //height: calc(100vh - 92px);
  padding-top: 138px;

  @include display-flex(flex-start, center);
  flex-direction: column;

  .verify {
    @include display-flex;

    svg {
      margin-right: 10px;
    }

    span {
      @include fonts(600, 18px, 29px, $jazzy_jade);
    }
  }

  .title {
    margin-top: 29px;
    width: 874px;
    position: relative;

    @include fonts(700, 86px, 88px, $accent);
    letter-spacing: -0.04em;
    text-align: center;

    & .curve_left {
      position: absolute;
      top: -50px;
      left: 6px;
    }

    & .arrow_right {
      position: absolute;
      top: 85px;
      left: 630px;
    }
  }

  .description {
    margin-top: 41px;
    width: 404px;

    @include fonts(500, 20px, 32px, #4d4d4d);
    text-align: center;
  }

  & .button {
    margin-top: 41px;
    width: 170px;
    height: 55px;
    background: #333333;
    transition: all 0.5s ease-out;

    box-shadow: -2px -2px 5px rgba(255, 255, 255, 0.1),
      2px 2px 5px rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    border: none;

    @include fonts(500, 20px, 10px, white);
    text-align: center;
    @include display-flex;

    cursor: pointer;

    &:hover {
      color: $jazzy_jade;
    }
  }

  @media screen and (max-width: 500px) {
    padding-top: 40px;

    .verify {
      svg {
        margin-right: 2px;
      }
      span {
        @include fonts(600, 10px, 16px, $jazzy_jade);
      }
    }

    .title {
      margin-top: 46px;
      width: 313px;
      position: relative;

      @include fonts(700, 35px, 36px, $accent);
      letter-spacing: -0.04em;
      text-align: center;

      & .curve_left {
        position: absolute;
        width: 63px;
        top: -40px;
        left: -20px;
      }

      & .arrow_right {
        height: 137px;
        position: absolute;
        top: 40px;
        left: 145px;
      }
    }

    .description {
      margin-top: 16px;
      width: 295px;

      @include fonts(500, 14px, 22px, #4d4d4d);
    }

    & .button {
      margin-top: 23px;
      width: 119px;
      height: 43px;
      border-radius: 12px;

      @include fonts(500, 14px, 10px, white);
      text-align: center;
    }
  }
}

.sport_events {
  margin-top: 109px;
  padding-left: 50px;
  width: 100%;

  @media screen and (max-width: 500px) {
    margin-top: 51px;
    padding-left: 16px;
  }
}

.button_block {
  margin-top: 61px;
  height: 50px;
  margin-left: 45px;
  position: relative;
  z-index: 0;

  & .hover_right,
  .hover_left {
    position: absolute;
    top: -25px;
    width: 100px;
    height: 100px;
    z-index: 2;
  }

  & .hover_right {
    right: 0;
    background-image: linear-gradient(to right, transparent, white);
  }
  & .hover_left {
    left: 551px;
  }

  .button_block_wrapper {
    @include display-flex(flex-start);
    position: absolute;
    width: 100%;
    height: 50px;
    top: 0;
    background: white;
    z-index: 1;
    transition: all 1s ease-out;

    & button {
      width: 180px;
      height: 49px;
      border-radius: 15px;
      margin-right: 15px;
      text-align: center;
      cursor: pointer;
      transition: all 0.5s ease-out;

      &:last-child {
        margin-right: 21px;
      }
      &:hover {
        color: $jazzy_jade;
      }
    }

    & .active {
      @include fonts(600, 16px, 19px, #ffffff);
      border: none;
      background: $accent;
    }

    & .inactive_static {
      @include fonts(500, 16px, 19px, $accent);
      border: 1px solid #d4d3d3;
      background: white;
    }

    & .inactive_dynamic {
      @include fonts(500, 16px, 19px, $accent);
      border: none;
      background: #f5f5f5;
    }

    & .static {
      position: absolute;
      left: 4px;
      width: 591px;
      @include display-flex(flex-start);
      flex-wrap: nowrap;
      border-right: 1px solid #d4d3d3;
      z-index: 3;
      background: white;
    }

    & .dynamic {
      position: absolute;
      width: calc(100% - 591px);
      left: 636px;
      height: 49px;
      margin-right: 20px;
      overflow-x: scroll;

      & .shift_block {
        position: absolute;
        top: 0;
        left: 0;
        @include display-flex(flex-start);
        flex-wrap: nowrap;
        transition: all 1s ease-out;
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 26px;
    padding-left: 16px;
    margin-left: 0;
    height: 36px;
    overflow-x: scroll;
    overflow-y: hidden;

    & .hover_right,
    .hover_left {
      display: none;
    }



    .button_block_wrapper {
      @include display-flex(flex-start);
      position: absolute;
      height: 36px;
      bottom: 6px;
      margin-right: 19px;
      width: auto;

      & button {
        width: 104px;
        height: 36px;
        border-radius: 8px;
        margin-right: 8px;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s ease-out;

        &:last-child {
          margin-right: 5px;
        }
        &:hover {
          color: $jazzy_jade;
        }
      }

      & .active {
        @include fonts(600, 14px, 16px, #ffffff);
        border: none;
        background: $accent;
      }

      & .inactive_static,
      .inactive_dynamic {
        @include fonts(600, 14px, 16px, $accent);
        border: 1px solid #d4d3d3;
        background: white;
      }

      & .static {
        width: 333px;
        @include display-flex(flex-start);
        flex-wrap: nowrap;
        border-right: 1px solid #d4d3d3;
      }

      & .dynamic {
        position: relative;
        @include display-flex(flex-start);
        width: auto;
        height: 36px;
        margin-left: 5px;

        left: 349px;

        & .shift_block {
          position: relative;
          @include display-flex(flex-start);
          flex-wrap: nowrap;
          margin-right: 19px;
        }
      }
    }
  }
}
