@import "src/styles/colors";
@import "src/styles/mixins";

.mobile_title {
  display: none;

  @media screen and (max-width: 500px) {
    display: block;

    & h3 {
      margin-left: 22px;
      @include fonts(700, 16px, 24px, #313131);
    }
  }
}

.title {
  @include display-flex(space-between);
  max-width: 1126px;
  width: 100%;
  position: fixed;
  z-index: 10;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -22px;
    z-index: 9;
    height: 86px;
    width: 100%;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.26) 100%
    );
  }

  span {
    margin: 10px 12px;
    @include fonts(700, 20px, 24px, #313131);
    z-index: 10;
  }

  button {
    width: 218px;
    border-radius: 8px;
    border: none;
    background-color: $ambrosia_cake;
    padding: 12px 0;
    cursor: pointer;
    z-index: 10;

    @include fonts(500, 14px, 20px, $accent);
  }

  @media screen and (max-width: 500px) {
    background: white;
    bottom: 0;
    left: 0;
    padding: 11px 0;

    &::before {
      content: none;
    }

    span {
      display: none;
    }

    button {
      width: 340px;
      padding: 12px 0;
      margin: 0 auto;

      @include fonts(500, 16px, 20px, $accent);
    }
  }
}

.progress {
  @include display-flex(space-between);
  padding: 20px 24px;
  background: white;
  border-radius: 8px;
  position: relative;
  max-width: 1114px;
  margin: 0 auto 0;

  span {
    @include fonts(500, 16px, 24px, $accent);
    z-index: 1;

    span.price {
      @include fonts(700, 20px, 24px, $accent);
      margin: 0 19px 0 14px;
    }
  }

  div {
    position: absolute;
    height: 100%;
    background: #ffdc8e;
    border-radius: 8px;
    left: 0;
  }
}
