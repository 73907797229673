@import "src/styles/colors";
@import "src/styles/mixins";

.block {
  padding: 23px 0 21px;

  & h3 {
    @include fonts(500, 18px, 21px, rgba(51, 51, 51, 0.91));
  }

  & .group {
    margin-top: 29px;
    width: 396px;
    height: 95px;

    @include display-flex;
    flex-wrap: wrap;

    & label {
      width: 190px;
      @include display-flex(flex-start);

      & span {
        @include fonts(400, 18px, 21px, rgba(51, 51, 51, 0.91));
      }
    }

    & :global {
      .ant-radio-inner {
        width: 23px;
        height: 23px;
        border-radius: 0;

        &:before {
          width: 0;
        }

        &:after {
          content: "✔";
          position: absolute;
          color: $jazzy_jade;
          width: 0;
          font-size: 40px;
          top: 9px;
          left: 10px;
        }
      }
      .ant-radio {
        margin-bottom: 9px;
        margin-right: 4px;

        &:after {
          width: 0;
        }
      }

      & .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: $jazzy_jade;
      }

      & .ant-radio-checked::after {
        border: 1px solid $jazzy_jade;
      }

      & .ant-radio-checked .ant-radio-inner {
        border: 1px solid $jazzy_jade;
      }
    }
  }

  @media screen and (max-width: 500px){
    padding: 35px 0 0;

    & h3 {
      @include fonts(500, 16px, 19px, rgba(51, 51, 51, 0.91));
    }

    & .group {
      margin: 30px 0 17px;
      width: 100%;
      height: auto;
      row-gap: 20px;
      @include display-flex(flex-start);
      flex-wrap: wrap;

      & label {
        width: 128px;
        @include display-flex(flex-start);

        & span {
          @include fonts(400, 14px, 16px, rgba(51, 51, 51, 0.91));
        }
      }

      & :global {
        .ant-radio-inner {
          width: 22px;
          height: 22px;
          border-radius: 0;

          &:before {
            width: 0;
          }

          &:after {
            content: "✔";
            position: absolute;
            color: $jazzy_jade;
            width: 0;
            font-size: 40px;
            top: 9px;
            left: 10px;
          }
        }
        .ant-radio {
          margin-bottom: 9px;
          margin-right: 4px;

          &:after {
            width: 0;
          }
        }

        & .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner {
          border-color: $jazzy_jade;
        }

        & .ant-radio-checked::after {
          border: 1px solid $jazzy_jade;
        }

        & .ant-radio-checked .ant-radio-inner {
          border: 1px solid $jazzy_jade;
        }
      }
    }
  }
}
