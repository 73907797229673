@import "src/styles/colors";
@import "src/styles/mixins";

.dateField {
  width: calc(50% - 24px);
}

.container {
  width: 410px;
  padding: 24px 0 9px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  @include desktop {
    width: 784px;
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & input,
    select,
    textarea {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;

  @media screen and (max-width: 1050px) {
    display: none;
  }
}

.subtitle {
  @include fonts(400, 14px, 21px, #33333380);
  margin-bottom: 29px;

  @media screen and (max-width: 1050px) {
    @include fonts(400, 12px, 18px, rgba(51, 51, 51, 0.5));
    margin: 10px 0 12px;
  }
}

.fullWidth {
  width: 100%;

  & input {
    width: 100%;
  }
}

.group {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & .description {
    & textarea {
      height: 54px;
      @include mid-tablet {
        width: 362px;
      }
    }

    & span {
      @include mid-tablet {
        width: 362px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1050px) {
    display: block;

    & div {
      margin-right: 0;
    }
  }
}

.input__width {
  width: 100%;
  & input,
  & select {
    width: 100%;
  }
}
