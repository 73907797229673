@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 800px;

  background-color: $fresh_guacamole;
  position: relative;
  margin-bottom: 500px;
}

.headers {
  margin-top: 120px;
  margin-bottom: 40px;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
}

.video {
  max-width: 1208px;
  width: 100%;
  height: 599px;
  box-shadow: 0 10px 40px 0 #00000033;
  border-radius: 16px;

  position: absolute;
  bottom: -30%;
}
