@import "src/styles/colors";
@import "src/styles/mixins";

.reviews {
  margin-top: 106px;
  width: 100%;
  height: 506px;
  padding: 106px 70px 106px;
  @include display-flex;
  gap: 80px;
  background: rgba(244, 246, 252, 0.5);

  & .title {
    width: 374px;
    height: 243px;

    & h3 {
      @include fonts(600, 42px, 56px, $main_text);
    }

    & p {
      margin-top: 19px;
      @include fonts(400, 20px, 28px, $main_text);
      opacity: 0.7;
    }
  }

  & .reviews_item {
    width: 845.2px;
    height: 246px;

    & .description {
      @include fonts(500, 32px, 48px, $main_text);
      height: 144px;
    }

    & .footer {
      width: 100%;
      margin-top: 44px;
      @include display-flex(space-between);

      & .user {
        width: 249px;
        height: 56px;
        @include display-flex(flex-start);

        & img {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 14px;
        }

        & .text {
          & div:first-child {
            @include fonts(500, 18px, 32px, $main_text);
          }
          & div:last-child {
            @include fonts(500, 12px, 28px, $main_text);
          }
        }
      }

      & .arrows {
        @include display-flex;
        gap: 11px;

        & button {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: white;
          border: none;
          cursor: pointer;
          @include display-flex;
        }

        .active {
          background: $midday_sun;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    @include display-flex(flex-start);
    flex-direction: column;
    gap: 28px;
    height: 540px;
    padding: 0 30px;


    .title {
      text-align: center;
      width: 100%;
      height: auto;
    }
    .reviews_item {
      width: 100%;
      .description {
        @include fonts(500, 22px, 30px, $main_text);
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 61px;
    height: 540px;
    padding: 54px 16px;
    @include display-flex(flex-start);
    flex-direction: column;
    gap: 28px;

    & .title {
      width: 100%;
      height: auto;

      & h3 {
        @include fonts(600, 22px, 33px, $main_text);
      }

      & p {
        margin-top: 9px;
        font-size: 16px;
      }
    }

    & .reviews_item {
      width: 100%;
      height: auto;

      & .description {
        @include fonts(500, 18px, 33px, $main_text);
        height: 165px;
      }

      & .footer {
        margin-top: 28px;

        & .user {
          max-width: 246px;
          height: 54px;
          @include display-flex(flex-start);

          & img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 11px;
          }

          & .text {
            & div:first-child {
              font-size: 16px;
            }
            & div:last-child {
              margin-top: -3px;
            }
          }
        }

        & .arrows {
          @include display-flex;
          gap: 14px;

          & button {
            width: 36px;
            height: 36px;

            & svg {
              height: 16px;
            }
          }

          .active {
            background: $midday_sun;
          }
        }
      }
    }
  }
}
