@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0.5);
  mix-blend-mode: normal;
  z-index: 1100;

  @include display-flex;

  & .modal {
    margin: 80px 0 0;
    width: 780px;
    max-height: 635px;
    background: #ffffff;
    border-radius: 15px;
    overflow: auto;


    & .header {
      @include display-flex(space-between);
      padding: 26px 35px 23px;
      border-bottom: 1px solid #e2e2e2;

      & span {
        @include fonts(600, 22px, 26px, $accent);
      }

      & svg {
        width: 36px;
        cursor: pointer;
        color: #202020;

        &:hover {
          color: $background-color;
        }
      }
    }

    & .content {
      padding: 23px 36px 19px;

      & .languages {
        padding-top: 23px;
      }

      & .line {
        width: 100%;
        height: 1px;
        background: #e2e2e2;
        margin-top: 50px;
      }

      & .see_more {
        @include fonts(500, 20px, 23px, $carbon);
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    & .footer {
      @include display-flex(space-between);
      padding: 14px 47px 16px 35px;
      border-top: 1px solid #e2e2e2;
      margin-top: 41px;

      & span {
        @include fonts(500, 20px, 23px, $carbon);
        text-decoration-line: underline;
        cursor: pointer;
      }

      & button {
        width: 134px;
        height: 45px;
        background: $jazzy_jade;
        border: 1px solid rgba(255, 255, 255, 0.13);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 15px;

        @include fonts(500, 18px, 21px, #ffffff);
      }
    }
  }

  @media screen and (max-width: 500px){

    & .modal {
      margin: auto auto;
      width: calc(100% - 38px);
      max-height: 543px;
      overflow: auto;

      & .header {
        @include display-flex(space-between, flex-start);
        padding: 15px 15px 15px 20px;
        border-bottom: 1px solid #e2e2e2;

        & span {
          margin-top: 17px;
          @include fonts(600, 16px, 19px, $accent);
        }

        & svg {
          width: 24px;
          cursor: pointer;
          color: #202020;

          &:hover {
            color: $background-color;
          }
        }
      }

      & .content {
        padding: 26px 20px 0;

        & .languages {
          padding-top: 35px;
        }

        & .line {
          margin-top: 8px;
        }

        & .see_more {
          @include fonts(500, 14px, 16px, $accent);
          text-decoration-line: underline;
          cursor: pointer;
        }
      }

      & .footer {
        padding: 18px 24px 16px 22px;
        margin-top: 25px;

        & span {
          @include fonts(500, 16px, 19px, $accent);
        }

        & button {
          width: 114px;
          height: 39px;
          border-radius: 10px;

          @include fonts(500, 16px, 19px, #ffffff);
        }
      }
    }

  }
}
