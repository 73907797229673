@import "src/styles/colors";
@import "src/styles/mixins";

.tools_title {
  max-width: 1114px;
  margin: 0 auto 17px;

  & h2 {
    @include fonts(500, 16px, 24px, $accent);
  }

  & h3 {
    @include fonts(400, 16px, 24px, $accent);
    margin-top: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #f5f5f5;
  }
  @media screen and (max-width: 500px) {
    margin: 0 auto 24px;

    & h2 {
      @include fonts(700, 14px, 21px, $accent);
    }

    & h3 {
      @include fonts(400, 16px, 24px, $accent);
      padding-bottom: 10px;
    }
  }
}
