@import "src/styles/colors";
@import "src/styles/mixins";

.spinner {
  @include display-flex;
  flex-direction: column;

  & h5 {
    margin-top: 11px;
    width: 100%;
    text-align: center;
    @include fonts(400, 13px, 15px, $jazzy_jade);
  }

  & svg {
    width: 100px;
  }
}
