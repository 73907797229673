@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  max-width: 1114px;
  margin: 0 auto;
  border: 1px solid #f5f5f5;
  background: #ffffff;
  border-radius: 8px;
  padding: 34px 24px 24px;

  & .title {
    @include fonts(700, 16px, 24px, $accent);
  }

  & .card_block {
    @include display-flex(flex-start);
    flex-wrap: nowrap;
  }

  .pagination {
    @include display-flex(space-between);
    border-top: 1px solid #e5e7eb;
    padding: 0 2px;
    margin-top: 24px;

    button.arrow_prev,
    button.arrow_next {
      @include display-flex;
      padding-top: 18px;
      cursor: pointer;
      color: $accent;

      & span {
        margin-top: 2px;
        @include fonts(400, 14px, 20px, inherit);
      }

      & svg {
        color: inherit;
      }
    }

    .arrow_prev {
      & svg {
        margin-right: 12px;
      }
    }

    .arrow_prev_end {
      color: #51c9c2;
    }

    .arrow_next {
      & svg {
        margin-left: 12px;
      }
    }
    button {
      border: none;
      background-color: inherit;
    }

    & .pagination_button {
      padding-top: 18px;
      width: 36px;
      @include fonts(500, 14px, 20px, $accent);
      cursor: pointer;

      &:hover {
        border-top: 1px solid $jazzy_jade;
        color: $jazzy_jade;
      }
    }

    & .mobile_number_pagin {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    border: none;
    padding: 0;
    margin-top: 38px;

    & .title {
      font-size: 14px;
    }

    & .card_block {
      @include display-flex;
    }

    .pagination {
      @include display-flex(space-between);
      border-top: 1px solid #e5e7eb;
      padding: 0 2px;
      margin-top: 24px;

      button.arrow_prev,
      button.arrow_next {
        @include display-flex;
        padding-top: 18px;
        cursor: pointer;
        color: $accent;

        & span {
          margin-top: 2px;
          @include fonts(400, 14px, 20px, inherit);
        }

        & svg {
          color: inherit;
        }
      }

      .arrow_prev {
        & svg {
          margin-right: 12px;
        }
      }

      .arrow_prev_end {
        color: #51c9c2;
      }

      .arrow_next {
        & svg {
          margin-left: 12px;
        }
      }
      button {
        border: none;
        background-color: inherit;
      }

      & .number_pagin {
        display: none;
      }

      & .mobile_number_pagin {
        display: block;

        & .pagination_button {
          padding-top: 18px;
          width: 36px;
          @include fonts(500, 14px, 20px, $accent);
          cursor: pointer;

          &:hover {
            border-top: 1px solid $jazzy_jade;
            color: $jazzy_jade;
          }
        }
      }
    }
  }
}
