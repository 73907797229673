@import "src/styles/colors";
@import "src/styles/mixins";

.sidebar {
  padding: 16px;
  width: 250px;
  height: calc(100vh - 68px);
  position: fixed;
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e5e5e5;
  z-index: 14;

  @media screen and (max-width: 1050px) {
    background: #FAFAFA;
    border: none;
    height: 100vh;
    width: 100%;
    z-index: 100;
    top: 0;
    padding: 0 19px;
    justify-content: flex-start;
  }
}

.group {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media screen and (max-width: 1050px){
    margin: 0;
    gap: 4px
  }
}

.menu_item {
  width: 218px;
  height: 44px;
  padding: 12px 19px;
  border-radius: 8px;
  margin-bottom: 3px;

  display: flex;
  align-items: center;

  & svg {
    width: 20px;
    color: $carbon;
    background-color: #f8f8fa;
    border-radius: 4px;
    margin-right: 15px;
  }

  @media screen and (max-width: 1050px){
    width: 100%;
    margin-bottom: 4px;

    display: flex;
    align-items: center;

    & svg {
      width: 20px;
      color: $carbon;
      background-color: #f8f8fa;
      border-radius: 4px;
      margin-right: 15px;
    }
  }
}

.menu_item_title {
  @include fonts(400, 14px, 20px, $accent);

  @media screen and (max-width: 1050px){
    font-weight: 500;
  }
}

.menu_item:hover,
.active {
  background-color: $accent;

  & .menu_item_title {
    color: white;
  }

  & svg {
    color: white;
    background-color: $accent;
  }
}

.title {
  @include fonts(400, 13px, 16px, $accent);
  padding: 0;
  margin: 0 0 8px;

  @media screen and (max-width: 1050px){
    margin: 0;
  }
}

.upgrade {
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts(500, 14px, 20px, $accent);
  white-space: nowrap;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  padding: 12px 56px;
  border-radius: 8px;
  background-color: #ffdc8e;

  &:hover {
    color: $carbon;
  }

  @media screen and (max-width: 1050px){
    margin-top: 8px;
  }
}

.logout {
  all: unset;
  display: flex;
  align-items: center;
  margin-top: 37px;
  margin-bottom: 39px;
  margin-left: 19px;
  cursor: pointer;
  @include fonts(500, 16px, 22.4px, #7c7c7a);

  & svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
  &:hover {
    color: $accent;
  }

  @media screen and (max-width: 1050px){
    margin-top: 16px;
    color: $accent;

    & svg{
      color: #7C7C7A;
    }
  }
}


.account{
  display: none;
  @media screen and (max-width: 1050px) {
    position: relative;
    display: block;
    padding: 21px 19px 21px;
    height: 132px;
  }
}

.header{
  display: none;
  @media screen and (max-width: 1050px) {
    width: calc(100% + 38px);
    @include display-flex(space-between);
    background: white;
    margin: 0 -19px;
    padding: 22px 19px 23px;

    & .arrow_title{
      cursor: pointer;
      @include display-flex(flex-start);

      & svg{
        height: 44px;
        margin-right: 20px;
        color: $accent;
      }

      & span{
        @include fonts(600, 18px, 24px, #313131);
      }
    }

    & button{
      width: 92px;
      height: 36px;
      background: #FAFAFA;
      mix-blend-mode: normal;
      border-radius: 23px;
      border: none;
      cursor: pointer;
      @include display-flex;
      @include fonts(500, 14px, 24px, $accent);
    }
  }
}

.sidebar_link{
  @media screen and (max-width: 1050px){
    padding: 12px 11px 6px;
    background: white;
    border-radius: 4px;
    @include display-flex(flex-start, stretch);
    //align-items: stretch;
    flex-direction: column;
    gap: 16px;
  }
}
