@import "antd/dist/antd.css";
@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/fonts";
@import "src/styles/components";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Roboto", sans-serif;
}

html {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
