@import "src/styles/colors";
@import "src/styles/mixins";

.modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0.5);
  mix-blend-mode: normal;
  padding: 20px;

  @include display-flex;
  z-index: 1002;

  & .content {
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    width: 1298px;
    height: 100%;
    //min-height: 600px;

    filter: blur(0);

    display: grid;
    grid-template-areas:
      "leftside center   rightside"
      "footer footer footer";
    grid-template-columns: 350px auto 363px;
    grid-template-rows: calc(100vh - 108px) 68px;
    overflow: hidden;
  }

  & .footer {
    grid-area: footer;
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    padding: 12px 104px 12px 47px;
    @include display-flex(space-between);

    border-top: 1px solid #dddddd;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(5px);

    & .arrowLeft {
      @include display-flex;
      cursor: pointer;

      & span {
        @include fonts(700, 14px, 24px, $accent);
      }

      & svg {
        margin-right: 11px;
      }
    }

    & .total {
      @include fonts(500, 14px, 24px, #676b79);

      & span:first-child {
        @include fonts(500, 14px, 24px, #676b79);
      }

      & span:last-child {
        @include fonts(700, 14px, 24px, $accent);
      }
    }

    & button {
      background: #eee9cf;
      border-radius: 8px;
      width: 155px;
      height: 44px;
      border: none;
      cursor: pointer;

      @include fonts(500, 14px, 20px, $accent);
    }
  }
}
