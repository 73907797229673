@import "src/styles/colors";
@import "src/styles/mixins";

.label {
  position: relative;
  .icon {
    position: absolute;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
  .dta {
    position: relative;
    z-index: 1;
    height: 36px;
    width: 130px;
    padding: 10px 10px 10px 40px;
    border: 1px solid #979797;
    border-radius: 6px;
    //background-color: #F8F8FA;
    @include fonts(500, 14px, 16px, #979797);
    background-color: transparent;
  }
}
