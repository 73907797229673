@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  padding-top: 10px;
  width: 33.3%;
  //height: 372px;
  cursor: pointer;

  margin-right: 24px;
  margin-top: 24px;

  transition: all 0.3s linear;
  &:hover{
    border-color: $jazzy_jade;
  }

  h5 {
    @include fonts(600, 16px, 123%, $accent);
    padding: 15px 12px 10px 13px;
    margin-bottom: 0;

    border-bottom: 1px solid #f5f5f5;
  }

  figure {
    width: 100%;
    padding: 16px 12px;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 164px;
      object-fit: cover;
      border-radius: 8px;
    }

    figcaption {
      padding-top: 17px;
      height: 50px;
      @include fonts(400, 14px, 122%, #999999);
    }
  }

  hr {
    color: #999999;
    outline: 3px dashed white;
    outline-offset: -3px;
    overflow: hidden;
  }

  .footer {
    @include display-flex(space-between);
    padding: 16px 12px;
    height: 57px;

    div,
    div > span {
      @include fonts(400, 14px, 122%, #595959);

      span:last-child {
        @include fonts(500, 15px, 17px, #595959);
        letter-spacing: 0.2px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    padding-top: 0;
    width: 100%;
    //height: 372px;
    cursor: pointer;

    margin-right: 0;
    margin-top: 11px;

    h5 {
      @include fonts(500, 14px, 23px, $accent);
      padding: 13px 13px 11.5px 13px;
      margin-bottom: 0;
      border-bottom: 1px solid #f5f5f5;
    }

    figure {
      width: 100%;
      padding: 15.5px 12px 0;

      img {
        border-radius: 4px;
      }

      figcaption {
        padding-top: 13px;
        @include fonts(400, 12px, 20px, #999999);
      }
    }

    hr {
      color: #999999;
      outline: 3px dashed white;
      outline-offset: -3px;
      overflow: hidden;
    }

    .footer {
      @include display-flex(space-between);
      height: auto;

      & div,
      div > span {
        @include fonts(400, 12px, 20px, #595959);

        span:last-child {
          font-size: 14px;
        }
      }
    }
  }
}

.card:last-child {
  margin-right: 0;
}
