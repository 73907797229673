@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  position: relative;
  width: 100%;
}

.wrapper {
  padding-top: 68px;
  @media screen and (max-width: 500px) {
    padding-top: 35px;
  }
}

.content {
  //padding-left: 250px;
  height: calc(100vh - 68px);
  overflow-y: scroll;

  @media screen and (max-width: 500px) {
    padding-left: 0;
    height: calc(100vh - 35px);
  }
}
