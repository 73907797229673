@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  flex: 0 1 317px;
  height: 389px;
  margin: 0 0 34px 0;

  background: #333333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.101421),
    0 1px 4px rgba(0, 0, 0, 0.101421);
  border-radius: 15px;
  cursor: pointer;

  & img {
    width: 317px;
    height: 200px;
    object-fit: cover;

    border-radius: 15px 15px 0 0;
  }

  .text {
    padding: 20px 23px 18px;

    & .country {
      @include display-flex(flex-start);

      & svg {
        margin-right: 7px;
      }

      & span {
        @include fonts(400, 16px, 19px, #c4c4c4);
        margin-top: 1px;
      }
    }

    & .title {
      margin-top: 13px;

      width: 271px;
      height: 54px;
      @include fonts(500, 18px, 27px, #ffffff);
      letter-spacing: 0.02em;
    }

    & hr {
      margin-top: 17px;
      color: #979797;
      size: 1px;
      opacity: 0.2;
    }

    & .footer {
      margin-top: 17px;
      @include display-flex(space-between);

      & .date {
        @include fonts(400, 16px, 24px, #c4c4c4);
        letter-spacing: -0.011em;
      }

      & .price {
        @include display-flex;

        & span:first-child {
          @include fonts(500, 20px, 26px, #ffffff);
        }
        & span:last-child {
          @include fonts(400, 14px, 26px, #656668);
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    height: 341px;
    margin: 0 16px 0 0;
    width: calc(100vw - 32px);
    flex: auto;

    & img {
      width: 100%;
      height: 185px;
    }

    .text {
      padding: 17px 18px 13px;

      & .country {
        & svg {
          margin-right: 5px;
        }

        & span {
          @include fonts(400, 12px, 14px, #c4c4c4);
          margin-top: 0;
        }
      }

      & .title {
        margin-top: 10px;
        width: 100%;
        @include fonts(500, 16px, 27px, #ffffff);
      }

      & hr {
        margin-top: 5px;
      }

      & .footer {
        margin-top: 10px;

        & .date {
          @include fonts(400, 12px, 18px, #c4c4c4);
        }

        & .price {
          & span:first-child {
            @include fonts(500, 16px, 26px, #ffffff);
          }
          & span:last-child {
            @include fonts(400, 12px, 26px, #656668);
          }
        }
      }
    }
  }
}
