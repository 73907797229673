@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  width: 410px;
  padding: 24px 0 9px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: relative;
  margin-right: 0;

  @include desktop {
    width: 784px;

  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & input,
    select {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;

  @media screen and (max-width: 1050px) {
    margin: 12px 0 20px;
    font-size: 14px;
  }
}

.group {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1050px) {
    display: block;

    & div {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

.disable {
  position: relative;
  z-index: 1;

  & div:first-child {
    position: absolute;
    background: white;
    opacity: 0.5;
    z-index: 2;
    top: 25px;
    right: 26px;
    bottom: 15px;
    left: 0;

    @media screen and (max-width: 1050px) {
      bottom: 0;
    }
  }
}

//.input_phone{
//
//  & select{
//    width: 108px!important;
//  }
//  & input{
//    width: 100%!important;
//  }
//}
