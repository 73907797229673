@import "src/styles/colors";
@import "src/styles/mixins";

.calendar_block {
  margin: 64px auto 0 auto;
  max-width: 1126px;
  width: 100%;

  & .title{
    display: none;
  }

  & .filter {
    width: 100%;
    height: 52px;
    background: #ffffff;
    border: 1px solid #f1f2f7;
    border-radius: 8px;
    padding: 0 16px 0 32px;
    @include display-flex(space-between);

    & span {
      @include fonts(400, 14px, 16px, #8b8b8b);
      cursor: pointer;
    }

    & .left_side {
      @include display-flex(flex-start);
      gap: 30px;

      & .active {
        @include fonts(700, 14px, 16px, $jazzy_jade);
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background: $jazzy_jade;
          top: -6px;
          left: 50%;
        }
      }
    }

    & .right_side {
      @include display-flex(flex-start);
      gap: 13px;

      & img {
        height: 24px;
      }
    }
  }

  & .block {
    margin-top: 20px;
    width: 100%;


    & .shift_block{
      @include display-flex(flex-start);
      flex-wrap: wrap;
      column-gap: 22px;
      row-gap: 32px;
    }
  }

  @media screen and (max-width: 500px){
    margin: 0;
    max-width: 1126px;
    width: 100%;
    position: relative;
    z-index: 0;

    & .title{
      @include display-flex(space-between);
      padding: 0 11px 0 22px;

      & div:first-child{
        @include fonts(700, 16px, 24px, #313131);
      }

      & div:last-child{
        @include display-flex(flex-start);
        gap: 13px;

        & span {
          @include fonts(400, 14px, 16px, #8b8b8b);
          cursor: pointer;
        }

        & img {
          height: 24px;
        }
      }
    }

    & .filter {
      margin-top: 18px;
      padding: 0;
      @include display-flex(flex-start);
      position: relative;
      overflow-x: scroll;

      & span {
        @include fonts(400, 14px, 16px, #8b8b8b);
        cursor: pointer;
        white-space: nowrap;
      }

      & .left_side {
        padding: 0 52px 0 52px;
        position: absolute;
        left: 0;

        & .active {
          @include fonts(700, 14px, 16px, $jazzy_jade);
          white-space: nowrap;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: $jazzy_jade;
            top: -6px;
            left: 50%;
          }
        }
      }

      & .right_side {
        display: none;
      }
    }

    & .block {
      margin: 22px 0 0 21px;
      width: 100%;
      height: 488px;

      position: relative;
      overflow-x: scroll;

      & .shift_block{
        position: absolute;
        left: 0;
        top: 0;
        @include display-flex(flex-start);
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 15px;
      }
    }
  }
}
