@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  position: relative;
  border-radius: 16px;
  width: 449px;
  height: 440px;
  box-shadow: 0 1px 4px 0 #3131311a;

  & img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: 16px;
  }
}

.big {
  width: 920px;
}

.content {
  position: absolute;
  bottom: -2px;
  left: -1px;
  right: -1px;
  background-color: #f7f8f9;
  border-radius: 0 0 16px 16px;
  padding: 24px;
  text-align: left;
}

.title {
  @include fonts(400, 22px, 32px, #212121);
  margin-bottom: 30px;
}

.date,
.view {
  @include fonts(700, 10px, 11.72px, #212121);

  & svg {
    width: 12px;
    color: $carbon;
    margin-right: 8px;
  }

  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
