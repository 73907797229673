@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  .label {
    display: block;
    margin-bottom: 5px;
    @include fonts(400, 14px, 20px, $carbon);
  }

  .block {
    position: relative;

    .input {
      position: relative;
      background-color: transparent;
      height: 40px;
      border: 1px solid #f5f5f5;
      padding: 8px 44px 8px 12px;
      @include fonts(400, 16px, 24px, $carbon);
      z-index: 2;
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      z-index: 1;
    }
  }
}

.errorDate {
  & label {
    color: $form_red !important;
  }

  & input {
    border-color: $form_red !important;
  }
}

.errorText {
  font-size: 12px;
  margin-top: 3px;
  color: $form_red;
}
