@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  background-color: $diamond_dust;
  position: relative;
  padding: 89px 0;
}

.content {
  padding-left: 90px;
}

.line {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 4;

  & svg {
    width: 401px;
    height: 353px;
    margin: 0;
    padding: 0;
  }
}

.title {
  max-width: 647px;
  @include fonts(400, 48px, 57.6px, #000b33);
}

.description {
  max-width: 454px;
  @include fonts(400, 16px, 26px, #000b33);
  margin-top: 13px;
}

.list {
  max-width: 410px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 38px;
}

.col {
  display: flex;
  align-items: center;

  & svg {
    color: #00bb98;
    width: 14px;
  }
}

.list_title {
  @include fonts(300, 14px, 22px, #000b33);
  margin-left: 8px;
}

.group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & img {
    width: 466px;
    border-radius: 16px;
    margin-top: 16px;
    z-index: 8;
  }
}

.button {
  display: block;
  width: 180px;
  height: 55px;

  @include fonts(500, 20px, 10px, white);

  background: $accent;
  @include display-flex;
  box-shadow: -2px -2px 5px rgba(255, 255, 255, 0.1),
    2px 2px 5px rgba(0, 0, 0, 0.19);
  border-radius: 15px;

  &:hover {
    color: $jazzy_jade;
  }
}
