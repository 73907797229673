@import "src/styles/colors";
@import "src/styles/mixins";

.control {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 460px);
  background: #f8f8fa;
  border-radius: 16px;
  border: 1px solid $background-color;
  filter: drop-shadow(-4px -4px 13px rgba(0, 0, 0, 0.05))
    drop-shadow(4px 4px 7px rgba(0, 0, 0, 0.05));
  overflow-x: auto;

  & .slider {
    position: absolute;
    top: 18px;
    height: 168px;
    @include display-flex(flex-start);

    & .position {
      & h3 {
        margin-left: 19px;
        @include fonts(500, 16px, 24px, $accent);
      }
      & .block_cards {
        margin: 16px 0 0 12px;
        @include display-flex(flex-start, center);
        column-gap: 12px;
      }
    }
  }

  & .proceed {
    position: fixed;
    top: 186px;
    left: 14px;

    & .bar {
      @include display-flex(space-between);
      width: 100%;
      height: 40px;
      padding: 0 28px;

      & .items {
        background: #8f919c;

        & span:first-child {
          @include fonts(500, 15px, 24px, #676b79);
        }
        & span:last-child {
          @include fonts(500, 15px, 24px, $accent);
        }
      }
    }

    & button {
      width: 337px;
      height: 44px;
      background: #eee9cf;
      border-radius: 8px;
      @include fonts(500, 16px, 20px, $accent);
      @include display-flex;
    }
  }
}
