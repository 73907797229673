@import "src/styles/colors";
@import "src/styles/mixins";

.footer {
  grid-area: footer;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  padding: 12px 104px 12px 47px;
  @include display-flex(space-between);

  border-top: 1px solid #dddddd;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(5px);
  height: 68px;

  & .arrowLeft {
    @include display-flex;
    cursor: pointer;

    & span {
      @include fonts(700, 14px, 24px, $accent);
    }

    & svg {
      margin-right: 11px;
    }
  }

  & .total {
    @include fonts(500, 14px, 24px, #676b79);

    & span:first-child {
      @include fonts(500, 14px, 24px, #676b79);
    }

    & span:last-child {
      @include fonts(700, 14px, 24px, $accent);
    }
  }

  & button {
    background: $midday_sun;
    border-radius: 8px;
    width: 155px;
    height: 44px;
    border: none;
    cursor: pointer;

    @include fonts(500, 14px, 20px, $accent);

    &:disabled {
      cursor: initial;
      background: darken($diamond_dust, 10);
    }
  }
}
