@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  margin: 0 auto;
  position: relative;
  max-width: 800px;
  @include desktop {
    max-width: 1190px;
    width: 100%;
  }
}

.content {
  display: flex;
  position: relative;
  top: 88px;
  width: 100%;

  @media screen and (max-width: 1050px) {
    display: block;
    top: 0;
    padding-bottom: 66px;
    margin-top: 32px;
  }
}

.wrapper {
  margin-left: 360px;

  & > div {
    margin-bottom: 20px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1050px) {
    margin-left: 0;
    width: 100%;

    & > div {
      margin-bottom: 8px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}

.title {
  display: none;

  @media screen and (max-width: 1050px) {
    display: block;
    padding: 0 0 15px 19px;
    @include fonts(700, 16px, 24px, $accent);
  }
}

.account_wrapper {
  display: none;

  @media screen and (max-width: 1050px) {
    margin: 0 19px 15px;
    padding: 25px 4px 30px;
    background: #f8f5e6;
    border-radius: 4px;
    display: block;
  }
}
