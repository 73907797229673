@import "src/styles/colors";
@import "src/styles/mixins";

.navbar {
  z-index: 1;
  height: 62px;
  padding: 0 0 0 20px;
  margin-top: 0;
  @include display-flex(space-between);
  .hidden_el {
    @include less-tablet {
      display: none;
    }
  }
  @include tablet {
    height: 79px;
    position: relative;
    overflow: hidden;
    z-index: 1002;
  }
  @include desktop {
    padding: 0 57px;
  }

  .main_title {
    @include display-flex();

    @include less-tablet {
      display: none;
    }
    @media screen and (min-width: 900px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .logo_link {
      height: 33px;
      display: block;
    }

  }

  .basket {
    position: relative;
    display: block;

    .notification {
      position: absolute;
      top: 5.5px;
      right: -5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $jazzy_jade;
      @include display-flex;

      @include fonts(500, 8px, 9px, #ffffff);
    }
    @include tablet {
      display: none;

    }
  }

  //@media screen and (max-width: 1200px) {
  //  padding: 0 15px;
  //}
  //
  //@media screen and (max-width: 800px) {
  //  z-index: 1;
  //  height: 62px;
  //  padding: 0 16px 0 12px;
  //  margin-top: 0;
  //
  //  .hidden_el {
  //    display: none;
  //  }
  //
  //  .basket {
  //    position: relative;
  //    display: block;
  //
  //    .notification {
  //      position: absolute;
  //      top: 5.5px;
  //      right: -5px;
  //      width: 12px;
  //      height: 12px;
  //      border-radius: 50%;
  //      background: $jazzy_jade;
  //      @include display-flex;
  //
  //      @include fonts(500, 8px, 9px, #ffffff);
  //    }
  //  }
  //
  //  h1 {
  //    @include fonts(700, 18px, 20px, $carbon);
  //  }
  //}
}

.button {
  @include fonts(400, 18px, 21.09px, $carbon);
  z-index: 10;
  transition: color .25s cubic-bezier(0.4, 0, 0.2, 1);


  &:hover {
    color: #1598FF;
  }
}

.solid {
  @include fonts(500, 18px, 21.09px, white);
  background-color: #1598FF;
  border: 1px solid #1598FF;
  padding: 12px 25px;
  box-shadow: 0 4px 4px 0 #0000000d;
  border-radius: 13px;
  margin-left: 35px;
  margin-right: 23px;
  transition: border .25s cubic-bezier(0.4, 0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0, 0.2, 1), color .25s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover, &:focus {
    border: 1px solid #1598FF;
    background-color: transparent;
    color: #1598FF;

  }
}

.menu {
  z-index: 1002;
  @include fonts(500, 18px, 20px, $carbon);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;

  & svg {
    animation: fade 0.75s linear forwards;
    margin-right: 18px;
  }

  @media screen and (max-width: 500px) {
    @include fonts(500, 16px, 20px, $carbon);
  }
}

.open_menu{
  z-index: 1002;
  @include fonts(500, 18px, 20px, $carbon);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;

  & svg {
    animation: fade 0.75s linear forwards;
    width: 28px;
    height: 28px;
    margin-right: 18px;
  }

}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.leftMenu {
  display: flex !important;
  align-items: center;
  white-space: nowrap;

  & > * {
    margin-right: 44px;
  }

  :global {
    .active {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1100px) {
    & > * {
      margin-right: 10px;
    }
  }
}

.link {
  display: block;
  @include fonts(600, 19px, 39px, #1598FF);
  white-space: nowrap;
  transition: color .25s cubic-bezier(0.4, 0, 0.2, 1), font-weight .25s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    font-weight: 800;
    color: $carbon;
  }
}

.rightMenu {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;

  :global {
    .ant-select-selection-item {
      @include fonts(400, 16px, 18.75px, $carbon);
    }

    .ant-select-arrow span svg {
      width: 16px;
      color: $carbon;
    }
  }

  & .login_reg {
    @include display-flex;
  }
}

:global {
  .ant-select-item-option-content {
    @include fonts(400, 16px, 18.75px, $carbon);
  }
}

.language {
  border: none;
  background: inherit;
  height: 22px;
  @include display-flex;
  color: $accent;
  cursor: pointer;

  & .world {
    width: 22px;
    height: 22px;
    color: inherit;
  }

  & span {
    @include fonts(400, 16px, 19px, inherit);
    opacity: 0.8;
    margin: 0 3px;
    width: 25px;
  }

  & svg {
    color: inherit;
  }

  & div {
    width: 12px;
    height: 100%;
    @include display-flex;

    & svg {
      color: inherit;
      width: 12px;
    }
  }
}

.account {
  width: 241px;
  height: 100%;
  @media screen and (max-width: 500px) {
    display: none;
  }
}
