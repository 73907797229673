@import "src/styles/colors";
@import "src/styles/mixins";

.social {
  width: 410px;
  padding: 24px;
  background: #ffffff;

  border: 1px solid #e5e5e5;
  border-radius: 8px;

  @include desktop {
    width: 784px;
  }

  & .title {
    @include fonts(700, 16px, 24px, $accent);
    margin-bottom: 16px;
  }

  & .description {
    @include fonts(400, 14px, 24px, $accent);
    margin-bottom: 16px;
  }

  .wrapper {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
      margin: -8px -12px;
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & .title {
      @include fonts(700, 14px, 24px, $accent);
    }
    & .description {
      @include fonts(400, 12px, 24px, $accent);
      margin-top: 10px;
    }
  }
}

.social_block {
  @include display-flex(space-between);
  flex-wrap: wrap;

  @media screen and (max-width: 1050px) {
    margin-top: 16px;
  }
}

.medias__group {
  margin: 24px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    margin: 0 0 10px;
    width: calc(50% - 12px);

    & div {
      width: 100%;
    }
  }

  @include less-desktop {
    margin-top: 16px;

    & > div {
      width: calc(50% - 6px);
    }
  }
}
