@import "src/styles/colors";
@import "src/styles/mixins";

.Landing {
  overflow: hidden;

  //@media screen and (max-width: 400px){
  //  background: linear-gradient(180.01deg, #F8F5E6 24.17%, rgba(248, 245, 230, 0.17) 46.07%);
  //}
}
