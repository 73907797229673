@import "src/styles/colors";
@import "src/styles/mixins";

.header {
  width: 100%;
  padding: 22px 30px 0 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 12;

  @include mid-tablet {
    width: 783px;
  }

  @include desktop {
    max-width: 1190px;
    width: 100%;
  }

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -22px;
    z-index: 9;
    height: 128px;
    width: 100%;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.26) 90%,
        rgba(255, 255, 255, 0.1) 95%,
        rgba(255, 255, 255, 0.01) 100%
    );
  }

  @media screen and (max-width: 1050px) {
    bottom: 0;
    padding: 10px 19px;
    justify-content: center;
    background: white;

    &::before {
      content: none;
    }
  }
}

.title {
  @include fonts(700, 20px, 24px, #313131);
  margin: 0;
  padding: 0;
  z-index: 12;

  @media screen and (max-width: 1050px) {
    display: none;
  }
}

.link {
  @include fonts(500, 14px, 20px, $carbon);
  @include display-flex;
  height: 44px;
  width: 218px;
  border-radius: 8px;
  background-color: $ambrosia_cake;
  z-index: 12;

  &:hover {
    color: $carbon;
  }

  @media screen and (max-width: 1050px) {
    width: 300px;
    margin: 0 auto;
  }
}
