@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  width: 410px;
  padding: 24px 0 24px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  @include desktop {
    width: 784px;

  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & input,
    select,
    textarea {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $accent);
  margin-bottom: 16px;

  @media screen and (max-width: 1050px) {
    margin: 12px 0 0;
    font-size: 14px;
  }
}

.block{
  margin-top: 20px;
  @include display-flex(flex-start);
  flex-direction: column;
  row-gap: 16px;
  padding-right: 24px;

  & .item{
    width: 100%;
    @include display-flex(space-between);
    padding-top: 21px;
    border-top: 1px solid #F5F5F5;


    & .text{

      & h4{
        @include fonts(600, 14px, 24px, $accent);
      }

      & div{
        @include fonts(400, 14px, 23px, $accent);
        font-feature-settings: 'salt' on;
      }
    }

    & button{
      width: 77px;
      height: 36px;
      background: $midday_sun;
      border-radius: 6px;
      border: none;
      @include display-flex;
      @include fonts(600, 12px, 12px, $accent);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1050px){
    padding-right: 0;

    & .item{
      padding-top: 16px;

      & .text{

        & h4{
          font-size: 12px;
        }

        & div{
          font-size: 12px;
        }
      }
    }
  }
}
