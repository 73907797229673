@import "src/styles/colors";
@import "src/styles/mixins";

.documentary {
  width: 410px;
  padding: 24px 0 24px 24px;
  background: #ffffff;

  border: 1px solid #e5e5e5;
  border-radius: 8px;
  @include desktop {
    width: 784px;
  }

  & .input_block {
    margin-top: 10px;
    width: 50%;

    & input {
      width: 100%;
    }
  }

  .title {
    @include fonts(700, 16px, 24px, $accent);
    margin-top: 3px;
  }

  .document {
    width: 100%;
    @include display-flex(stretch, center);
    margin-bottom: 29px;
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    .title {
      @include fonts(700, 14px, 24px, $accent);
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .document {
      @include display-flex;
      flex-direction: column;
      margin-bottom: 20px;

      & div {
        margin-right: 0;
      }
    }

    & .input_block {
      margin: 0 0 14px 0;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      & input {
        width: 100%;
      }
    }
  }
}
