@import "src/styles/colors";
@import "src/styles/mixins";

.range {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;

  & h3 {
    @include fonts(500, 18px, 21px, rgba(51, 51, 51, 0.91));
  }

  & p {
    @include fonts(400, 16px, 19px, #717171);
    margin: 9px 0 18px;
  }

  & .input_block {
    @include display-flex;
    margin-top: 9px;

    & .line {
      width: 5px;
      height: 2px;
      background-color: #b0b0b0;
      margin: 0 10px;
    }

    & .block {
      width: 317px;
      height: 57px;
      border: 1px solid #b0b0b0;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 8px 12px;

      @include display-flex(center, flex-start);
      flex-direction: column;

      & label {
        @include fonts(400, 14px, 10px, #717171);
        margin-bottom: 6px;
      }

      & .as_input {
        @include display-flex;
        @include fonts(400, 16px, 19px, #000000);

        & span {
          @include fonts(400, 16px, 19px, #000000);
          margin-right: 4px;
        }

        & input {
          border: none;
          height: auto;
        }
      }
    }
  }

  & :global {
    & .ant-slider-step {
      height: 2px;
      background: #b0b0b0;
    }
    & .ant-slider-track {
      background: transparent;
    }

    & .ant-slider-handle {
      width: 25px;
      height: 25px;
      border: 1px solid #b0b0b0;
      margin-top: -11px;
      box-shadow: none;

      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 9px;
        border-left: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        top: 7px;
        left: 8px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 9px;
        border-left: 1px solid #c4c4c4;
        top: 7px;
        left: 14px;
      }

      &:hover {
        box-shadow: none;
        border-color: $jazzy_jade;
      }

      &:focus {
        box-shadow: none;
        border-color: $jazzy_jade;
      }

      &::selection {
        box-shadow: none;
        border-color: $jazzy_jade;
      }
    }

    .ant-slider:hover .ant-slider-track {
      background-color: $jazzy_jade;
    }

    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border: 1px solid #b0b0b0;
    }
  }

  @media screen and (max-width: 500px){
    padding-bottom: 5px;
    border-bottom: none;

    & h3 {
      @include fonts(500, 16px, 19px, rgba(51, 51, 51, 0.91));
    }

    & p {
      @include fonts(400, 14px, 16px, #717171);
      margin: 9px 0 14px;
    }

    & .input_block {
      @include display-flex;
      margin-top: 20px;

      & .line {
        display: none;
      }

      & .block {
        width: 143px;
        height: 56px;
        padding: 9px 14px;

        @include display-flex(center, flex-start);
        flex-direction: column;

        & label {
          @include fonts(400, 12px, 14px, #717171);
          margin-bottom: 6px;
        }

        & .as_input {
          @include display-flex;
          @include fonts(400, 14px, 16px, #000000);

          & span {
            @include fonts(400, 14px, 16px, #000000);
            //margin-right: 4px;
          }

          & input {
            border: none;
            height: auto;
          }
        }

        &:first-child{
          margin-right: 11px;
        }
      }
    }

    & :global {
      & .ant-slider-handle {
        width: 22px;
        height: 22px;
        margin-top: -10px;

        &::before {
          width: 4px;
          height: 9px;
          top: 6px;
          left: 6.5px;
        }

        &::after {
          content: "";
          position: absolute;
          width: 4px;
          height: 9px;
          border-left: 1px solid #c4c4c4;
          top: 6px;
          left: 12.5px;
        }
      }

      .ant-slider:hover .ant-slider-track {
        background-color: $jazzy_jade;
      }

      .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border: 1px solid #b0b0b0;
      }
    }
  }
}
