@import "src/styles/colors";
@import "src/styles/mixins";



.item {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px 10px 10px;
  display: flex;

  @include tablet {
    position: relative;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 25px 74px 30px 71px;
  }

  &:not(:first-child)::before {
    @include tablet {
      position: absolute;
      content: "";
      left: 44px;
      top: -61px;
      width: 2px;
      height: 42px;
      background-color: $jazzy_jade;
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;

    @include tablet {
      margin-bottom: 60px;
    }
  }

  .icon {
    margin-right: 10px;

    @include tablet {
      position: absolute;
      top: -20px;
      left: 28px;
    }


  }

  .meta {
    width: 100%;


    &.not {
      opacity: .3;
    }
    .block {
      @include display-flex(space-between);

      @include tablet {
        padding: 0 20px 0 3px;
        margin-bottom: 14px;
      }

      .title {
        @include fonts(500, 13px, 20px, $accent);

        @include tablet {
          @include fonts(500, 16px, 24px, $accent);
        }
      }
      .date {
        @include fonts(400, 8px, 12px, $accent);

        @include tablet {
          display: none;
        }
      }
      .desc_time {
        display: none;

        @include tablet {
          display: block;
          @include fonts(400, 14px, 21px, #787E91);
        }
      }
    }

    .line {
      display: none;

      @include tablet {
        display: block;
        height: 1px;
        width: 100%;
        background-color: $background-color;
        margin-bottom: 24px;
      }
    }

    .data {
      @include display-flex(space-between);

      @include tablet {
        padding: 0 20px 0 3px;
      }

      .description {
        @include fonts(400, 12px, 18px, #777D90);

        @include tablet {
          @include fonts(400, 14px, 21px, #727A84)
        }
      }
      .time {
        display: none;

        @include tablet {
          display: block;
          @include fonts(400, 14px, 21px, #787E91)
        }
      }
    }
  }
  }


