@import "src/styles/colors";
@import "src/styles/mixins";

.container {

  padding: 0 20px;
  width: 100%;

  @include tablet {
    padding: 0 30px;
  }
  .wrapper {

    @include tablet {
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      padding: 30px 24px 200px;
    }

    .info {
      display: none;

      @include tablet {
        display: block;
        @include fonts(500, 14px, 16px, #787E91);
        text-align: right;
        padding-right: 18px;
        margin-bottom: 18px;

      }
    }

    .list {
      list-style: none;
      margin: 0;

      @include tablet {
        margin-bottom: 21px;
      }

    }

    .more {
      display: none;

      @include tablet {
        display: block;
        @include fonts(500, 14px, 20px, #787E91);
        border: none;
        cursor: pointer;
        background-color: transparent;
        margin-left: auto;
        padding-right: 18px;
      }
    }
  }
}

