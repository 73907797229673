@import "src/styles/colors";
@import "src/styles/mixins";

.block {

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 480px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1165px;
      padding: 0 32px;
    }
  }
}


