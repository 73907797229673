@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  position: relative;
  height: 1360px;
  padding: 57px 0;
  overflow: hidden;

  @include desktop {
    padding: 91px 0;
    height: 900px;

  }

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 500px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1440px;
      padding: 0 51px;
    }

    .pretitle {
      @include fonts(500, 14px, 16px, #1598FF);
      text-transform: uppercase;
      margin-bottom: 6px;
      padding-left: 3px;

      @include tablet {
        @include fonts(500, 18px, 20px, #1598FF);
      }

      @include desktop {
        @include fonts(500, 20px, 23px, #1598FF);
      }
    }

    .title {
      @include fonts(600, 24px, 33px, #1D1D1D);

      @include tablet {
        @include fonts(600, 40px, 48px, #1D1D1D);
      }

      @include desktop {
        width: 640px;
        @include fonts(600, 62px, 68px, #1D1D1D);
      }
    }

    .info {
      @include fonts(400, 18px, 35px, #1D1D1D);

      @include tablet {
        @include fonts(400, 20px, 36px, #1D1D1D);
      }

      @include desktop {
        width: 454px;
        @include fonts(400, 22px, 37px, #1D1D1D);
      }
    }
  }

  .block {
    position: absolute;
    width: 1032px;
    height: 438px;
    top: 0;
    left: 0;

    @include desktop {
      width: 1032px;
      height: 438px;
      top: 158px;
      left: 45%;
      transform: translateX(-50%);
    }

    .img {
      position: absolute;
      top: 10px;
      left: 25px;

      @include desktop {
        width: 100%;
        height: 100%;
        transform: rotate(-2.29deg);
      }
    }

    .data_first {

      position: absolute;
      top: 420px;
      left: 85px;
      transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      //animation: antRotate 2s none linear;

      &:hover {
        transform: scale(1.1);
      }

      @include desktop {
        position: absolute;
        top: 400px;
        left: 85px;
      }

      &__out {
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 20px;
        @include display-flex();
        margin-bottom: 43px;

        .in {
          width: 23px;
          height: 23px;
          background: #1598FF;
          border: 1px solid #EEEEEE;
          border-radius: 10px;
        }
      }

      &__subtitle {
        @include fonts(600, 18px, 30px, #1d1d1d);
        margin-bottom: 3.1px;
        @include desktop {
          @include fonts(700, 32px, 38px, #1d1d1d);
        }
      }

      &__subinfo {
        @include fonts(400, 16px, 30px, #1d1d1d);
        width: 235px;
        @include desktop {
          @include fonts(400, 22px, 37px, #1d1d1d);
        }
      }

      .number1 {
        width: 83px;
        height: 178px;
        position: absolute;
        top: 55px;
        left: 200px;
        z-index: -1;
      }
    }

    .data_second {
      position: absolute;
      top: 740px;
      left: 17px;
      transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        transform: scale(1.1);
      }

      @include tablet {
        left: 300px;
      }

      @include desktop {
        position: absolute;
        top: 279px;
        left: 571px;
      }

      &__out {
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 20px;
        @include display-flex();
        margin-bottom: 12px;

        .in {
          width: 23px;
          height: 23px;
          background: #1598FF;
          border: 1px solid #EEEEEE;
          border-radius: 10px;
        }
      }

      &__subtitle {
        @include fonts(600, 18px, 30px, #1d1d1d);
        margin-bottom: 3.42px;
        @include desktop {
          @include fonts(700, 32px, 38px, #1d1d1d);
        }
      }

      &__subinfo {
        @include fonts(400, 16px, 30px, #1d1d1d);
        width: 280px;
        @include desktop {
          width: 413px;
          @include fonts(400, 22px, 37px, #1d1d1d);
      }


      }

      .number2 {
        width: 127px;
        height: 178px;
        position: absolute;
        top: 29px;
        left: 166px;
        z-index: -1;
        @include desktop {
          left: 294px;
        }
      }
      .line_img2 {
        position: absolute;
        top: -310px;
        left: 40%;
        transform: translateX(-50%);
        z-index: -1;
        @include desktop {
          display: none;
        }

      }
    }

    .data_third {
      position: absolute;
      top: 1070px;
      left: 65px;
      transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        transform: scale(1.1);
      }

      @include tablet {
        left: 50%;
        transform: translateX(-50%);
      }

      @include desktop {
        position: absolute;
        transform: unset;
        top: -14px;
        left: 892px;
      }

      &__out {
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 20px;
        @include display-flex();
        margin-bottom: 20px;

        .in {
          width: 23px;
          height: 23px;
          background: #1598FF;
          border: 1px solid #EEEEEE;
          border-radius: 10px;
        }
      }

      &__subtitle {
        @include fonts(600, 18px, 30px, #1d1d1d);
        margin-bottom: 3.2px;
        @include desktop {
          @include fonts(700, 32px, 38px, #1d1d1d);
        }
      }

      &__subinfo {
        @include fonts(400, 16px, 30px, #1d1d1d);
        width: 301px;
        @include desktop {
          width: 380px;
          @include fonts(400, 22px, 37px, #1d1d1d);
        }
      }


      .number3 {
        width: 133px;
        height: 191px;
        position: absolute;
        top: 39px;
        left: 167px;
        z-index: -1;
        @include desktop {
          left: 197px;
        }
      }

      .line_img3 {
        position: absolute;
        top: -107px;
        left: -154%;
        transform: rotate(-164.34deg);
        z-index: -1;
        @include desktop {
          display: none;
        }

      }
    }

  }
}

@keyframes antRotate {
  0% {
    transform: rotate(0deg);
    transform-origin: 32px 32px;
  }
  25% {
    transform: rotate(90deg);
    transform-origin: 32px 32px;
  }
  50% {
    transform: rotate(180deg);
    transform-origin: 32px 32px;


  }
  75% {
    transform: rotate(270deg);
    transform-origin: 32px 32px;


  }
  100% {
    transform: rotate(360deg);
    transform-origin: 32px 32px;


  }
}
