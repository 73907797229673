@import "src/styles/colors";
@import "src/styles/mixins";

.Landing {
  overflow: hidden;
}

.wrapper_slider1 {
  margin: 84px 0 0 16px;
}

.wrapper_slider2 {
  margin: 84px 0 0 16px;
}
