@import "src/styles/colors";
@import "src/styles/mixins";

.footer {
  width: 100%;
  height: 500px;
  background: #14103E;
  padding: 55px 70px 43px;

  & .main {
    height: 335px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    @include display-flex(space-between, flex-start);
    gap: 125px;

    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
    }

    & .mission {
      margin-top: 3px;
      width: 369px;

      .logo {

      }

      & h3 {
        @include fonts(700, 24px, 20px, #ffffff);
      }

      & .desc {
        margin-top: 20px;
        @include fonts(400, 20px, 34px, #ffffff);



        & .arrow{
          display: inline;

          &:hover > .shift{
            margin-left: 72px;
          }


          & span {
            @include fonts(400, 20px, 34px, #2196F3);
            cursor: pointer;
          }

          & .shift{
            margin-left: 6px;
            transition: margin-left 1s ease-in-out ;
          }
        }
      }
      .social_list {
        display: flex;
        margin-top: 72px;

        .social_item {
          &:not(:last-child) {
            margin-right: 12px;
          }

          .social_link {
            @include display-flex();
            width: 40px;
            height: 40px;
            background: #2196F3;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

    }

    & .platform {
      width: 138px;
      margin: 4px 160px 0 0;

      & h3 {
        @include fonts(400, 20px, 22px, #ffffff);
        letter-spacing: 0.19008px;
      }

      & .list {
        margin-top: 12px;

        & li {
          list-style-type: none;
          margin-bottom: 18px;
          @include fonts(400, 16px, 22px, #bcbcbd);
          cursor: pointer;

          &:hover{
            color: white;
          }
        }

        & li:last-child {
          margin-bottom: 0;
        }
      }
    }

    & .sign {
      width: 383px;

      & h3 {
        @include fonts(400, 20px, 28px, #dddddd);
        width: 366px;
      }

      & button {
        width: 383px;
        height: 56px;
        border-radius: 4px;
        @include display-flex;
        cursor: pointer;
      }


      & .input_email{
        margin-top: 30px;

        & input{
          width: 383px;
          height: 56px;
          border: 1px solid #60636E;
          @include fonts(400, 20px, 30px, #bcbcbd);
          background-color: transparent;

          &::placeholder{
            @include fonts(400, 20px, 30px, #bcbcbd);
            text-align: center;
          }
        }
      }

      & .subscibe {
        margin-top: 16px;
        background: #2196F3;
        border: none;
        @include fonts(500, 20px, 30px, #fff);
        border-radius: 4px;
      }
    }
  }

  & .bottom_side {
    height: 66px;
    width: 100%;
    @include display-flex(flex-start, flex-end);
    @include desktop {
      max-width: 1440px;
      margin: 0 auto;
    }

    & a {
      margin-left: 19px;
      @include fonts(400, 15px, 24px, #908f91);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    height: 1100px;


    .main {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      height: unset;
      padding-bottom: 30px;
    }

  }

  @media screen and (max-width: 500px) {
    height: 766px;
    padding: 34px 16px 69px;

    & .main {
      height: 640px;
      @include display-flex(flex-start, flex-start);
      flex-direction: column;
      gap: 44px;

      & .mission {
        margin-top: 0;
        width: 100%;

        & h3 {
          @include fonts(700, 20px, 20px, #ffffff);
        }

        & .desc {
          margin-top: 43px;
          width: 300px;
          @include fonts(400, 18px, 34px, #ffffff);

          & .arrow{
            display: inline;

            &:hover > .shift{
              margin-left: 24px;
            }


            & span {
              @include fonts(400, 18px, 34px, #2196F3);
              cursor: pointer;
            }

            & .shift{
              margin-left: 6px;
              transition: margin-left 1s ease-in-out ;
            }
          }

        }

        & .social {
          margin-top: 23px;
          @include display-flex(flex-start);
          gap: 10px;

          & div {
            width: 36px;
            height: 36px;

            & svg {
              height: 18px;
              color: #fff;
            }

            & img {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      & .platform {
        width: 100%;
        margin: 0;
      }

      & .sign {
        width: 100%;

        & h3 {
          @include fonts(400, 20px, 28px, #DDDDDD);
        }

        & button {
          width: 343px;
        }

        & .input_email{
          margin: 30px 0 0;

          & input{
            width: 100%;
            font-size: 18px!important;

            &::placeholder{
              font-size: 18px;
            }
          }
        }


        & .subscibe {
          margin: 16px auto 0 auto;
          @include fonts(500, 18px, 30px, $main_text);
          width: 100%;
        }
      }
    }

    & .bottom_side {
      height: 56px;
      width: 100%;
      @include display-flex(flex-start, flex-end);

      & a {
        margin-left: 19px;
        @include fonts(400, 15px, 24px, #908f91);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
