@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  padding: 22px 44px 0 32px;
  background: #fdfdfd;
  width: 100%;
  height: 100%;
  position: relative;


  @media screen and (max-width: 500px) {
    padding: 0 19px;
  }
}

.position{
  padding-bottom: 50px;
}
