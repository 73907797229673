@import "src/styles/colors";
@import "src/styles/mixins";

.center {
  grid-area: center;
  padding: 16px;
  background: white;
  height: calc(100vh - 250px);
  max-height: 712px;

  & .title {
    width: 100%;
    height: 20%;
    border-bottom: 1px solid #ebeaea;
    @include display-flex(flex-end, flex-start);
    flex-direction: column;
    padding-bottom: 2.7%;

    & h2 {
      width: 100%;
      @include fonts(500, 20px, 30px, $accent);
    }

    & h2 span {
      text-transform: capitalize;
      @include fonts(300, 20px, 30px, $accent);
    }

    & h3 {
      width: 100%;
      margin-top: 14px;
      @include fonts(300, 15px, 19px, #787e91);
    }
  }

  & .img_block {
    width: 100%;
    height: 53%;
    @include display-flex(center, flex-end);

    & img {
      height: 73%;
      width: 279px;
      object-fit: contain;
    }
  }

  & .all_img {
    width: 100%;
    padding-top: 12%;
    @include display-flex;

    & .active {
      border: 1px solid $jazzy_jade;
    }

    & button {
      width: 80px;
      height: 90px;
      border: 1px solid rgba(245, 245, 245, 1);
      border-radius: 8px;
      margin-right: 12px;
      @include display-flex;
      background: white;

      & img {
        height: 60px;
        width: 65px;
        object-fit: contain;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
