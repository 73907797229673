@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  max-width: 1440px;
  margin: 100px auto 0 auto;
  padding: 0 45px;

  & h2 {
    @include fonts(600, 34px, 64px, $accent);
    //margin-left: 45px;
  }

  & .follow {
    margin-top: 40px;
    @include display-flex;
    padding-top: 16px;

    & .item {
      width: 311px;
      height: 158px;
      margin-right: 30px;
      position: relative;
      padding: 56px 28px 16px;

      & :before {
        content: "";
        position: absolute;
        top: 16px;
        left: 1px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $jazzy_jade;
      }

      & :after {
        content: "";
        position: absolute;
        top: 28px;
        left: 48px;
        width: 183px;
        height: 0;
        border-top: 2px dashed $jazzy_jade;
      }

      &:last-child {
        margin-right: 0;
      }

      & h4 {
        @include fonts(500, 20px, 30px, $accent);
      }

      & .desc {
        margin-top: 4px;
        @include fonts(400, 16px, 26px, $accent);
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 90px auto 0 auto;
    padding: 0 15px;

    & h2 {
      @include fonts(600, 18px, 64px, $accent);
    }

    & .follow {
      margin-top: 21px;
      @include display-flex(flex-start, flex-start);
      flex-direction: column;
      padding-top: 0;
      row-gap: 40px;

      & .item {
        //width: 283px;
        height: 118px;
        margin-right: 0;
        padding: 0 0 0 41px;

        & :before {
          top: 7px;
          left: 0;
          width: 17px;
          height: 17px;
        }

        & :after {
          top: 41px;
          left: 8.5px;
          width: 1px;
          height: 75px;
          border-left: 2px dashed $jazzy_jade;
        }

        & h4 {
          @include fonts(500, 16px, 30px, $accent);
          font-feature-settings: "salt" on, "liga" off;
        }

        & .desc {
          margin-top: 4px;
          @include fonts(400, 14px, 26px, $accent);
          font-feature-settings: "salt" on, "liga" off;
        }
      }
    }
  }
}
