@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  width: 410px;
  padding: 24px 0 24px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  @include desktop {
    width: 784px;

  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & input,
    select,
    textarea {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);

  @media screen and (max-width: 1050px) {
    margin: 12px 0 0;
    font-size: 14px;
  }
}

.subtitle {
  @include fonts(400, 16px, 24px, rgba(51, 51, 51, 0.7));
  margin: 16px 0 20px;

  @media screen and (max-width: 1050px) {
    @include fonts(400, 12px, 24px, rgba(51, 51, 51, 0.7));
    margin: 12px 0 20px;
  }
}

.group {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & div{
    margin-bottom: 20px;
  }


  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1050px) {
    display: block;

    & div {
      margin-right: 0;
    }

    & div:last-child{
      margin-bottom: 16px;
    }
  }
}

.input__width {
  width: 100%;
  & input,
  & select {
    width: 100%;
  }
}

.footer{
  margin-top: 20px;
  @include display-flex(space-between);

  & p{
    @include fonts(400, 16px, 24px, rgba(51, 51, 51, 0.7));
    width: 439px;
  }

  & button{
    width: 218px;
    height: 44px;
    filter: drop-shadow(0px 40px 60px rgba(0, 0, 0, 0.02));
    background: $midday_sun;
    border-radius: 8px;
    border: none;
    @include display-flex;
    @include fonts(500, 14px, 20px, $accent);
    cursor: pointer;
    margin-right: 24px;
  }

  @media screen and (max-width: 1050px){
    margin-top: 0;
    @include display-flex(flex-start);
    flex-direction: column;
    row-gap: 37px;
    margin-bottom: 25px;

    & p{
      font-size: 12px;
      width: 100%;
    }

    & button{
      filter: none;
      margin: 0 auto;
    }
  }
}

.input_grey{
  & input{
    background: rgba(51, 51, 51, 0.03);;
  }
}
