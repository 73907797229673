@import "src/styles/colors";
@import "src/styles/mixins";


.item {
  height: 265px;
  background: #ffffff;
  border: 1px solid #eaecee;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  padding: 21px 32px 10px;
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .content {
    display: flex;
    margin-bottom: 13px;

    .photo {
      width: 135px;
      height: 168px;
      border: 1px solid #EAECEE;
      border-radius: 8px;
      margin-right: 16px;
      @include display-flex();
      flex-direction: column;

      .img {
        width: 80%;
      }
    }

    .wrapper {
      position: relative;
      top: 15px;
      display: flex;

      .data {
        width: 55%;
        .title {
          @include fonts(500, 20px, 27px, $accent);
          display: flex;
          align-items: center;
          margin-bottom: 26px;

          .eventIcon {
            margin-right: 7px;
          }
        }

        .subtitle {
          margin-bottom: 4px;
          @include fonts(500, 18px, 27px, #4F5B67);
        }
        .description {
          @include fonts(500, 18px, 27px, #4F5B67);

        }
      }
      .meta {
        width: 45%;
        @include display-flex(normal, flex-end);
        flex-direction: column;
        .btn {
          @include fonts(400, 18px, 27px, $accent);
          border: 0;
          background-color: transparent;
          cursor: pointer;
          margin-bottom: 78px;


          @include display-flex();
          .btnIcon {
            margin-right: 10px;
          }
        }
        .price{
          @include fonts(500, 24px, 26px, $accent);


          .per {
            @include fonts(400, 20px, 26px, $accent);
          }
        }
      }
    }
  }
  .footer {
    border-top: 1px solid #EAECEE;
    height: 63px;
    @include display-flex(flex-end);

    .position {
      @include display-flex();

      .init{
        @include fonts(400px, 18px, 27px, $accent);
        margin-right: 8px;
      }

      .block {
        border: 1px solid #EAECEE;
        border-radius: 4px;
        padding: 9px 14px;
        @include display-flex();

        .value {
          @include fonts(400, 18px, 24px, #4F5B67);
          margin-right: 39px;
        }
      }
    }
  }
}


