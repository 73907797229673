@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  margin: 20% 100px 200px;
}

.btn {
  all: unset;
  height: 48px;
  width: 48px;
  color: $carbon;
  cursor: pointer;
}

.headers {
  display: flex;
  align-items: center;
  position: relative;

  & h3 {
    padding: 0;
    @include fonts(600, 72px, 90px, $carbon);
    letter-spacing: -2.2%;
    margin: 0 31px 0 0;
  }
}

.line {
  position: absolute;
  left: 0;
  bottom: -35px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #c4c4c4;
  padding-bottom: 82px;
}

.content {
  margin-top: 105px;
}

.block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;
  border-bottom: 2px solid #c4c4c4;
  margin-bottom: 82px;
  position: relative;
}

.blockTitle {
  @include fonts(400, 52px, 30px, $carbon);
  letter-spacing: -0.25px;
}

.show {
  opacity: 1;
  transition: all 250ms linear;
}

.hidden {
  opacity: 0;
  transition: all 250ms linear;
}

.fadeImage {
  position: absolute;
  top: -10px;
  right: 78px;
  width: 462px;
  height: 269px;
}
