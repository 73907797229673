@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  position: relative;
  width: 389px;
  height: 305px;
  box-shadow: 0 1px 4px 0 #3131311a;
  transition: all 0.3s linear;
  border-radius: 16px;
  margin: 0 12px;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 30px 60px 0 #31313133;
  }
}

.repeat {
  animation: scales 0.75s linear infinite;

  &:hover {
    animation: none;
  }
}

@keyframes scales {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
    box-shadow: 0px 30px 60px 0px #31313133;
  }
  100% {
    transform: scale(1);
  }
}

.image {
  height: inherit;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.logo {
  & img {
    width: 53px;
    height: 48px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fff;
  border-radius: 0 0 12px 12px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 42px;
}

.title {
  @include fonts(500, 22px, 27.5px, #313131);
}

.date {
  @include fonts(600, 12px, 18px, #a6a6a6);
}
