@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  background-color: #070233;
  padding: 50px 0 50px;
  background-image: url("../../../assets/images/hero/bg-hero.png");
  background-repeat: no-repeat;
  background-position: -150px 0;

  @include desktop {
    padding: 95px 0 184px;
    background-position: 0 0;


  }

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 500px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1440px;
      padding: 0 41px 0 44px;
    }

    .wrapper {
      @include desktop {
        @include display-flex(space-between, flex-start);
      }

      .data {

        @include less-desktop {
          text-align: center;
          margin-bottom: 40px;

        }
        @include desktop {
          padding-top: 180px;
        }




        .title {
          @include fonts(500, 35px, 45px, #fff);
          letter-spacing: 1.5px;
          margin-bottom: 10px;
          &__span {
            @include fonts(100, 35px, 45px, #fff);
          }

          @include tablet {
            @include fonts(600, 55px, 65px, #fff);
            &__span {
              @include fonts(100, 55px, 65px, #fff);
            }
          }

          @include desktop {
            @include fonts(700, 80px, 91px, #fff);

            &__span {
              @include fonts(100, 80px, 91px, #fff);
            }
          }
        }

        .description {
          margin-bottom: 36px;
          @include fonts(400, 16px, 24px, #A2A1AF);

          @include tablet {
            @include fonts(400, 18px, 26px, #A2A1AF);
          }

          @include desktop {
            width: 502px;
            @include fonts(400, 22px, 29px, #A2A1AF);
          }
        }

        .btn {
          border: none;
          cursor: pointer;
          background: #1598FF;
          border-radius: 10px;
          @include display-flex();
          width: 250px;
          height: 60px;
          @include fonts(500, 21px, 25px, #fff);
          transition: border .25s cubic-bezier(0.4, 0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0, 0.2, 1);

          &:hover, &:focus {
            border: 1px solid #1598FF;
            border-radius: 10px;
            background-color: transparent;
          }

          @include less-desktop {
            margin: 0 auto;
          }
        }
      }

    }

  }
}
