@import "src/styles/colors";
@import "src/styles/mixins";

.image {
  width: 362px;
  height: 200px;
  border: 1px dashed #e5e5e5;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include desktop {
    width: 734px;

  }

  @media screen and (max-width: 875px) {
    width: 100%;
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

.file {
  display: none;
}

.logo {
  position: absolute;
  @include circle(100px, 1px, #e5e5e5);
  display: flex;
  align-items: center;
  justify-content: center;
  @include fonts(700, 16px, 24px, #ebebeb);
  left: 24px;
  bottom: 24px;
  background-color: #fff;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and (max-width: 875px) {
    @include circle(71px, 1px, #e5e5e5);
    left: 14px;
    bottom: 14px;
    @include fonts(700, 12px, 24px, #ebebeb);
  }
}

.logo__set {
  border: unset !important;
  background-color: unset !important;
}

.upload {
  all: unset;
  cursor: pointer;
  width: 97px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;

  & svg {
    width: 26px;
    color: #7e92a2a6;
    margin-bottom: 9px;
  }

  @include fonts(400, 12px, 18px, #7e92a2a6);
}

.upload_logo {
  all: unset;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  @include circle(32px, 0, $jazzy_jade, true);
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 16px;
    color: #ffffffc2;
  }

  @media screen and (max-width: 875px) {
    @include circle(31px, 0, $jazzy_jade, true);
  }
}

.container {
  width: 100%;
  height: 100%;
}

.button__image {
  all: unset;
  cursor: pointer;
  transition: all 0.2s linear;

  position: absolute;
  top: 20px;
  right: 25px;

  width: 40px;
  height: 40px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
    color: #7e92a2;
  }

  &:hover {
    background-color: #fff;
  }

  background-color: rgba(51, 51, 51, 0.8);
}

.button__image_bottom {
  top: unset;
  bottom: 20px;
}
