@import "src/styles/colors";
@import "src/styles/mixins";

.text{
  width: 100%;
  height: 100%;
  @include display-flex;
  flex-direction: column;
  gap: 16px;

  & span{
    color: $accent;
  }

  & a{
    color: $jazzy_jade;
  }
}
