@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  padding: 50px 0 30px;
  background-color: #070233;
  overflow: hidden;

  @include desktop {
    padding: 100px 0;
  }

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 500px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1440px;
      padding: 0 83px 0 48px;
    }

    .title {
      @include fonts(600, 24px, 30px, #fff);
      margin-bottom: 5px;
      text-align: center;

      @include tablet {
        @include fonts(600, 36px, 42px, #fff);
        margin-bottom: 10px;

      }

      @include desktop {
        @include fonts(700, 62px, 73px, #fff);
        margin-bottom: 14px;

      }
    }

    .pretitle {
      @include fonts(600, 24px, 33px, #fff);
      margin-bottom: 14px;
      text-align: center;

      @include tablet {
        @include fonts(600, 36, 42px, #fff);
        margin-bottom: 20px;

      }

      @include desktop {
        @include fonts(700, 62px, 64px, #fff);
        margin-bottom: 36px;

      }
    }

    .info {
      @include fonts(400, 18px, 28px, #fff);
      text-align: center;
      margin-bottom: 20px;

      @include tablet {
        @include fonts(400, 20px, 28px, #fff);
        margin-bottom: 16px;

      }

      @include desktop {
        @include fonts(400, 22px, 29px, #fff);

      }
    }

    .btn_block {
      display: none;
      margin-bottom: 20px;

      @include tablet {
        @include display-flex();
      }

      @include desktop {
        margin-bottom: 88px;
      }

      .btn {
        @include display-flex();
        height: 62px;
        width: 208px;
        border: 1px solid #EBEBEB;
        border-radius: 10px;
        @include fonts(700, 20px, 59px, #fff);
        cursor: pointer;
        background-color: transparent;
        transition: border .25s cubic-bezier(0.4, 0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0, 0.2, 1), color .25s cubic-bezier(0.4, 0, 0.2, 1);

        @include less-tablet {
          margin: 0 auto;
        }

        &:hover {
          border: 1px solid #FF1700;
          color: #FF1700;
        }

        &:not(:last-child) {
          margin-bottom: 21px;

          @include tablet {
            margin-bottom: unset;
            margin-right: 21px;
          }
        }
      }

      .active {
        background: #FF1700;
        border: 1px solid #FF1700;
        &:hover, &:focus {
          border: 1px solid #FF1700;
          color: #fff;
        }
      }
    }

    .mobile_btn {
      @include display-flex();
      height: 62px;
      width: 208px;
      border: 1px solid #FF1700;
      background: #FF1700;
      border-radius: 10px;
      @include fonts(700, 20px, 59px, #fff);
      cursor: pointer;
      margin: 0 auto 20px;


      @include tablet {
        display: none;
      }
    }

    .wrapper {
      min-height: 1074px;

      @include tablet {
        min-height: 1219px;
      }
      @include desktop {
        min-height: 651px;
        @include display-flex(space-between, flex-start);
        position: relative;
      }

      .content {

        @include less-desktop {
          margin-bottom: 70px;
        }
        @include tablet {
          padding-top: 52px;
        }

        .minititle {
          @include fonts(700, 24px, 33px, #fff);
          letter-spacing: 1px;
          margin-bottom: 22px;
          @include tablet {
            @include fonts(700, 33px, 39px, #fff);

          }
          @include desktop {
            @include fonts(700, 55px, 65px, #fff);

          }
        }

        .minitext {
          @include fonts(400, 18px, 34px, rgba(255, 255, 255, .5));
          margin-bottom: 47px;

          @include tablet {
            @include fonts(400, 20px, 38px, rgba(255, 255, 255, .5));

          }
          @include desktop {
            @include fonts(400, 22px, 41px, rgba(255, 255, 255, .5));
            width: 586px;

          }
        }

        .list {
          list-style: none;
          position: relative;
          left: 66px;
          margin-bottom: 44px;

          .item {
            @include fonts(400, 18px, 36px, #fff);
            position: relative;
            width: 80%;
            @include tablet {
              @include fonts(400, 20px, 40px, #fff);

            }

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            &::before {
              content: "";
              position: absolute;
              left: -60px;
              top: 50%;
              width: 32px;
              height: 32px;
              transform: translateY(-50%);
              background-color: rgba(255, 255, 255, 0.08);
              background-image: url("../../../assets/images/organizersLanding/systemFn/check.png");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 24px 24px;
              border-radius: 30px;
            }
          }
        }
        .bot_btn {
          background: #1598FF;
          border-radius: 10px;
          border: 1px solid #1598FF;
          width: 249px;
          height: 60px;
          cursor: pointer;
          transition: border .25s cubic-bezier(0.4, 0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0, 0.2, 1);
          @include fonts(600, 21px, 25px, #fff);
          @include display-flex();

          @include less-tablet {
            margin: 0 auto;
          }

          &:focus, &:hover {
            border: 1px solid #1598FF;
            background-color: transparent;
          }
        }
      }

      .img_wrapper {
        width: 350px;
        margin: 0 auto;

        @include tablet {
          width: 560px;
        }

        @include desktop {
          margin: unset;
        }

        .img {
          width: 100%;
        }
      }
    }
  }
}
