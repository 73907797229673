@import "../../../styles/colors";
@import "../../../styles/mixins";

.sport {
  padding: 0 45px;
  margin: 139px auto 0 auto;
  max-width: 1440px;

  & .title {
    @include display-flex(space-between);

    & h3 {
      @include fonts(600, 34px, 40px, $accent);
      text-align: center;
    }
  }

  .button_block {
    padding-right: 9px;
    @include display-flex;

    & span {
      @include fonts(600, 17px, 20px, rgba(51, 51, 51, 0.8));
      text-align: center;
      text-decoration-line: underline;
      margin-right: 22px;
    }

    & .arrow {
      width: 32px;
      height: 32px;
      border: none;
      background: transparent;
      cursor: pointer;
      position: relative;

      &:nth-child(2) {
        margin-right: 13px;
      }
    }
  }

  .svg_cicl {
    & circle,
    path {
      transition: all 1s ease-in-out;
    }
  }

  .content {
    margin: 40px -15px 0 -15px;
    @include display-flex;
    flex-wrap: wrap;
    width: 1350px;
    gap: 27px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 13px;
    margin: 44px auto 0 auto;

    & .title {
      & h3 {
        @include fonts(600, 16px, 19px, $accent);
      }
    }

    .button_block {
      padding-right: 2px;

      & span {
        display: none;
      }

      & .arrow {
        width: 23px;
        height: 23px;

        &:nth-child(2) {
          margin-right: 13px;
        }
      }
    }

    .svg_cicl {
      position: absolute;
      top: -4px;
      left: -4px;
      transform: scale(0.75);
    }

    .content {
      margin: 21px auto 0 auto;
      width: 100%;
      gap: 0;
    }
  }
}
