@import "src/styles/colors";
@import "src/styles/mixins";

.start {
  margin-top: 106px;
  @include display-flex(flex-start);
  flex-direction: column;

  & h1 {
    width: 691px;
    @include fonts(600, 48px, 64px, $main_text);
    text-align: center;

    & span {
      @include fonts(600, 48px, 64px, $jazzy_jade);
    }
  }

  & button {
    width: 435px;
    border-radius: 10px;
    cursor: pointer;
    @include display-flex;
    position: relative;
  }

  & .google {
    margin-top: 55px;
    height: 54px;
    background: #1d2130;
    //box-shadow: 0 0 3px rgba(0, 0, 0, 0.084), 0 2px 3px rgba(0, 0, 0, 0.168);
    @include fonts(500, 18px, 21px, rgba(255, 255, 255, 0.54));
    border: none;

    & img {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 15px;
      left: 15px;
    }
  }

  & .email {
    margin-top: 39px;
    height: 51px;
    background: white;
    border: 1px solid #eeeeee;
    @include fonts(500, 18px, 21px, rgba(29, 33, 48, 0.5));

    & img {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 10px;
      left: 12px;
    }
  }

  @media screen and (max-width: 765px) {
    h1 {
      width: 100%;
      @include fonts(600, 28px, 36px, $main_text);
      text-align: center;
      span {
        @include fonts(600, 28px, 36px, $jazzy_jade);
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 100px;

    & h1 {
      width: 100%;
      @include fonts(600, 24px, 33px, $main_text);
      text-align: center;

      & span {
        @include fonts(600, 24px, 33px, $jazzy_jade);
      }
    }

    & button {
      width: 295px;
    }

    & .google {
      margin-top: 33px;
      height: 51px;
      @include fonts(500, 16px, 19px, rgba(255, 255, 255, 0.54));

      & img {
        width: 23px;
        height: 23px;
        top: 14px;
        left: 17px;
      }
    }

    & .email {
      margin-top: 33px;
      @include fonts(500, 16px, 19px, rgba(29, 33, 48, 0.5));

      & img {
        position: absolute;
        width: 23px;
        height: 23px;
        top: 14px;
        left: 17px;
      }
    }
  }
}
