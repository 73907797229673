@import "src/styles/colors";
@import "src/styles/mixins";

input:focus,
select:focus,
option:focus,
textarea:focus {
  outline: 1px solid #2196F3;
}

input,
select {
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  @include fonts(400, 16px, 24px, $accent);

  @media screen and (max-width: 1050px) {
    font-size: 12px !important;
  }
}

label {
  @include fonts(400, 14px, 20px, $accent);

  @media screen and (max-width: 1050px) {
    font-size: 12px;
  }
}

.basic {
  padding: 23px 24px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  //max-width: 1114px;
  margin-top: 68px;

  & .title {
    @include fonts(700, 16px, 24px, $accent);
  }

  .upload_footer {
    margin-top: 6px;
    @include fonts(400, 14px, 21px, rgba(51, 51, 51, 0.5));
  }

  .events_name {
    margin: 30px 0 0;

    input {
      width: 100%;
    }
  }

  .location_seats {
    margin-top: 23px;
    @include display-flex(stretch, flex-end);

    div {
      cursor: pointer;
      flex: 50%;
      margin-right: 20px;
      @include fonts(400, 14px, 20px, $accent);

      div.as_input {
        background: #ffffff;
        border: 1px solid #f5f5f5;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        padding-left: 15px;
        @include display-flex(flex-start);
        margin-top: 5px;

        & span {
          @include fonts(400, 16px, 24px, $jazzy_jade);
          margin-left: 5px;
        }

        & svg {
          margin-bottom: 2px;
          height: 20px;
          color: $jazzy_jade;
        }
      }
    }

    div:last-child {
      flex: 50%;
      margin: 0;

      select {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 0;
    border: none;
    margin-top: 27px;

    & .title {
      @include fonts(700, 14px, 24px, $accent);
    }

    .upload_footer {
      margin-top: 10px;
      @include fonts(400, 12px, 18px, rgba(51, 51, 51, 0.5));
    }

    .events_name {
      margin: 12px 0 0;

      input {
        width: 100%;
      }
    }

    .location_seats {
      margin-top: 12px;
      @include display-flex;
      flex-direction: column;
      gap: 12px;

      div {
        flex: 100%;
        width: 100%;
        margin-right: 0;
        @include fonts(400, 12px, 20px, $accent);

        div.as_input {
          padding-left: 10px;

          & span {
            @include fonts(400, 12px, 24px, $jazzy_jade);
          }
        }
      }

      div:last-child {
        flex: 100%;
        width: 100%;
        margin: 0;

        select {
          width: 100%;
        }
      }
    }
  }
}

.additional {
  padding: 22px 24px 17px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  margin: 24px auto 0;

  & .title {
    @include fonts(700, 16px, 24px, $accent);
  }

  .description {
    display: block;
    margin: 24px 0 0;

    textarea {
      width: 100%;
      height: 104px;
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
      border-radius: 4px;
      @include fonts(400, 14px, 24px, #979797);
      padding: 10px 17px;
    }

    span {
      display: block;
      width: 100%;
    }
  }

  .site {
    margin: 0;
  }

  @media screen and (max-width: 1050px) {
    padding: 0;
    border: none;
    margin: 20px auto 0;

    & .title {
      @include fonts(700, 14px, 24px, $accent);
    }

    & .description {
      margin: 18px 0 0;

      textarea {
        border: 1px solid #f5f5f5;
        box-sizing: border-box;
        border-radius: 4px;
        @include fonts(400, 12px, 24px, #979797);
        padding: 10px 12px;
      }
    }

    .site {
      margin: 0;

      & input {
        width: 100%;
      }
    }
  }
}

.block {
  margin-top: 24px;
  @include display-flex(stretch, flex-end);
  gap: 24px;

  & div {
    margin: 0;
    flex: 0 1 50%;

    & select,
    input {
      width: 100%;
    }
  }

  @media screen and (max-width: 1050px) {
    margin-top: 12px;
    @include display-flex;
    flex-direction: column;
    gap: 12px;

    & div {
      margin: 0;
      flex: 0 1 100%;
      width: 100%;

      & select,
      input {
        width: 100%;
      }
    }
  }
}

.date_block {
  margin-top: 24px;
  @include display-flex(stretch, flex-end);
  gap: 24px;

  & div {
    margin: 0;
    flex: 0 1 50%;

    & select,
    input {
      width: 100%;
    }
  }

  @media screen and (max-width: 1050px) {
    margin-top: 12px;
  }
}

.medias__group {
  margin-top: 24px;
  @include display-flex(stretch, flex-end);
  gap: 24px;
  & div {
    margin: 0;
    flex: 0 1 50%;
    & div {
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
    margin-top: 12px;
    @include display-flex;
    flex-direction: column;
    gap: 12px;

    & div {
      margin: 0;
      flex: 0 1 100%;
      width: 100%;

      & div {
        width: 100%;
      }
    }
  }
}
.wrapper_medias {
  @media screen and (max-width: 1050px) {
    margin-top: 6px;
  }
}

.subtitle {
  margin-top: 16px;
  @include fonts(400, 14px, 24px, $carbon);

  @media screen and (max-width: 1050px) {
    margin-top: 5px;
    font-size: 12px;
  }
}
