@import "src/styles/colors";
@import "src/styles/mixins";

.sidebar {
  padding: 16px;
  width: 90px;
  height: calc(100vh - 68px);
  position: fixed;
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e5e5e5;
  z-index: 15;

  & .sidebar_link{
    @include display-flex(flex-start, stretch);
    flex-direction: column;
    gap: 16px;

    & .group {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px solid #D9D9D9;
      gap: 4px;



      & .menu_item {
        width: 56px;
        height: 50px;
        border-radius: 8px;
        @include display-flex;

        & svg {
          width: 24px;
          color: $carbon;
          background-color: #f8f8fa;
          border-radius: 4px;
        }

        &:hover{
          background-color: $accent;

          & svg {
            color: white;
            background-color: $accent;
          }
        }


      }
    }
  }
}

.active{
  background-color: $accent;

  & svg {
    color: white!important;
    background-color: $accent!important;
  }
}



.upgrade {
 @include display-flex(flex-start);
  width: 35px;
  height: 20px;
  margin: 0 auto;

  background: $midday_sun;
  border-radius: 20px;
  padding-left: 2px;


  & div{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    @include display-flex;
    cursor: pointer;

    & svg {
      width: 9px;
      color: $accent;
    }
  }
}

.logout {
  all: unset;
  margin: 37px 0 64px;
  width: 100%;
  @include display-flex;
  cursor: pointer;

  & svg {
    height: 24px;
    width: 24px;
    color: #7C7C7A;

    &:hover {
      color: $accent;
    }
  }

}


//.account{
//  display: none;
//  @media screen and (max-width: 500px) {
//    position: relative;
//    display: block;
//    padding: 21px 19px 21px;
//    height: 132px;
//  }
//}
//
//.header{
//  display: none;
//  @media screen and (max-width: 500px) {
//    width: calc(100% + 38px);
//    @include display-flex(space-between);
//    background: white;
//    margin: 0 -19px;
//    padding: 22px 19px 23px;
//
//    & .arrow_title{
//      cursor: pointer;
//      @include display-flex(flex-start);
//
//      & svg{
//        height: 44px;
//        margin-right: 20px;
//        color: $accent;
//      }
//
//      & span{
//        @include fonts(600, 18px, 24px, #313131);
//      }
//    }
//
//    & button{
//      width: 92px;
//      height: 36px;
//      background: #FAFAFA;
//      mix-blend-mode: normal;
//      border-radius: 23px;
//      border: none;
//      cursor: pointer;
//      @include display-flex;
//      @include fonts(500, 14px, 24px, $accent);
//    }
//  }
//}
//
//.sidebar_link{
//  @media screen and (max-width: 500px){
//    padding: 12px 11px 6px;
//    background: white;
//    border-radius: 4px;
//    @include display-flex(flex-start, stretch);
//    //align-items: stretch;
//    flex-direction: column;
//    gap: 16px;
//  }
//}
