@import "src/styles/colors";
@import "src/styles/mixins";

.sidebar {
  //height: 100%;
  width: 320px;
  padding: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: fixed;
  margin-left: 32px;

  @media screen and (max-width: 1050px) {
    display: none;
  }
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.icon {
  @include display-flex;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #33333314;
  margin-right: 16px;

  & svg {
    width: 24px;
    height: 24px;
    color: $carbon;
  }
}

.name {
  white-space: nowrap;
  @include fonts(400, 14px, 20px, $carbon);
}

.item:hover,
.active {
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  & .name {
    color: $jazzy_jade;
  }

  & .icon {
    background-color: $jazzy_jade;

    & svg {
      color: white;
    }
  }
}
