@import "../../../styles/colors";
@import "../../../styles/mixins";

.card {
  width: 360px;
  height: 488px;
  background: #ffffff;
  border: 1px solid #eaecee;
  border-radius: 12px;
  padding: 18px 24px 21px;

  & .header {
    @include display-flex(space-between);

    & .state {
      @include display-flex(flex-start);

      & div:last-child {
        @include fonts(500, 13px, 15px, $jazzy_jade);
      }

      & div:first-child {
        width: 8px;
        height: 8px;
        border: 1px solid #eaecee;
        background: inherit;
        border-radius: 4px;
        margin-right: 8px;
      }
    }

    & .point {
      width: 4px;
      height: 4px;
      background: #8b8b8b;
      border-radius: 2px;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        width: 4px;
        height: 4px;
        background: #8b8b8b;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: -6px;
      }

      &:after {
        content: "";
        width: 4px;
        height: 4px;
        background: #8b8b8b;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 6px;
      }
    }
  }

  & img {
    margin-top: 23px;
    width: 312px;
    height: 157px;
    border-radius: 12px;
    object-fit: cover;
  }

  & .country {
    margin-top: 12px;
    @include display-flex(flex-start);

    & svg {
      width: 26px;
      margin-right: 8px;
    }

    & div {
      @include fonts(400, 13px, 20px, #595959);
      margin-right: 16px;
    }
  }

  & h2 {
    margin-top: 12px;
    @include fonts(500, 18px, 27px, $accent);
  }

  & .position {
    margin-top: 16px;

    & h4 {
      @include fonts(400, 13px, 20px, #595959);
    }

    & .range {
      margin-top: 7px;
      @include display-flex(flex-start);
      gap: 11px;

      & span {
        @include fonts(600, 12px, 16px, #595959);
      }

      & .line {
        height: 10px;
        width: 225px;
        border-radius: 8px;
        background: #ededed;
        position: relative;

        & div {
          position: absolute;
          height: 100%;
          background: #51c9c2;
          left: 0;
          border-radius: 8px;
        }
      }
    }
  }

  & .footer {
    margin-top: 28px;
    border-top: 1px dashed #999999;
    padding: 11px 2px 0;

    & .total {
      @include display-flex(space-between);

      & span:first-child {
        @include fonts(400, 13px, 20px, #595959);
      }

      & span:last-child {
        @include fonts(400, 15px, 22px, $accent);
      }
    }

    & button {
      margin: 18px auto 0;
      width: 304px;
      height: 52px;
      background: #ffffff;
      border: 1px solid #eaecee;
      border-radius: 8px;
      @include display-flex;
      @include fonts(500, 14px, 14px, $accent);
      cursor: pointer;
    }
  }
}
