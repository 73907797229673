@import "src/styles/colors";
@import "src/styles/mixins";

.position {
  width: 492px;
  height: 236px;
  border-radius: 12px;
  margin-right: 25px;
  background: #ffffff;
  border: 1px solid #eaecee;
  padding: 10px;
  @include display-flex;

  &:last-child {
    margin-right: 0;
  }

  & .images {
    width: 167px;
    height: 100%;
    border: 1px solid #eaecee;
    border-radius: 8px;
    @include display-flex;
    padding: 30px 15px;

    & img {
      width: 100%;
    }
  }

  & .text {
    width: 305px;
    height: 100%;
    padding: 13px 17px 22px;

    & .header {
      height: 103px;
      border-bottom: 1px dashed #999999;

      & h3 {
        @include fonts(500, 16px, 24px, $accent);
      }

      & p {
        margin-top: 7px;
        @include fonts(400, 12px, 18px, #4f5b67);
      }
    }

    & .number_pos {
      margin-top: 8px;
      @include display-flex(flex-start);

      & span {
        @include fonts(400, 12px, 18px, #4f5b67);
        margin-left: 8px;
      }
    }

    & .footer {
      margin-top: 25px;
      @include display-flex(space-between);

      & .coast {
        @include display-flex(flex-start);

        & span:first-child {
          @include fonts(500, 20px, 26px, $accent);
          margin-right: 6px;
        }

        & span:last-child {
          @include fonts(400, 14px, 26px, #656668);
          margin-top: 4px;
        }
      }

      & button {
        width: 93px;
        height: 40px;
        background: #333333;
        border: 1px solid #333333;
        border-radius: 8px;
        @include display-flex;

        @include fonts(400, 14px, 14px, white);
        cursor: pointer;

        &:hover {
          @include fonts(400, 14px, 14px, $jazzy_jade);
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 343px;
    height: 165px;
    border-radius: 12px;
    margin-right: 17px;

    &:last-child {
      margin-right: 0;
    }

    & .images {
      width: 114px;
      height: 100%;
      border: 1px solid #eaecee;
      border-radius: 8px;
      @include display-flex;
      padding: 23px 4px;

      & img {
        width: 100%;
      }
    }

    & .text {
      width: 209px;
      height: 100%;
      padding: 1px 1px 2px 11px;

      & .header {
        height: 74px;
        border-bottom: 1px dashed #999999;
        padding-bottom: 14px;

        & h3 {
          overflow: hidden;
          @include fonts(500, 14px, 21px, $accent);
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 197px;
          padding-right: 5px;
        }

        & p {
          margin-top: 3px;
          @include fonts(400, 12px, 18px, #4f5b67);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      & .number_pos {
        margin-top: 10px;
        @include display-flex(flex-start);

        & svg {
          width: 18px;
          height: 18px;
        }

        & span {
          @include fonts(400, 12px, 18px, #4f5b67);
          margin-left: 8px;
        }
      }

      & .footer {
        margin-top: 7px;
        @include display-flex(space-between);

        & .coast {
          @include display-flex(flex-start);

          & span:first-child {
            @include fonts(500, 16px, 26px, $accent);
            margin-right: 2px;
          }

          & span:last-child {
            @include fonts(400, 12px, 26px, #656668);
            margin-top: 4px;
          }
        }

        & button {
          width: 77px;
          height: 33px;
          border-radius: 5px;

          @include fonts(400, 12px, 12px, white);
          cursor: pointer;

          &:hover {
            @include fonts(400, 12px, 12px, $jazzy_jade);
          }
        }
      }
    }
  }
}
