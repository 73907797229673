@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  max-width: 1126px;
  margin: 0 auto 0;

  & .workcation {
    margin-top: 46px;
    @include display-flex(flex-start);
    gap: 26px;

    & .circle {
      width: 100px;
      height: 100px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
      @include display-flex;
    }

    & .right_side {
      @include display-flex(center, flex-start);
      flex-direction: column;
      gap: 5px;

      & h2 {
        @include fonts(700, 18px, 24px, $accent);
      }

      & .text {
        margin-top: 5px;
        @include display-flex(flex-start);

        & h3 {
          @include fonts(500, 16px, 19px, $accent);
          letter-spacing: -0.005em;
          text-transform: capitalize;
        }

        & div {
          @include fonts(400, 16px, 20px, #6b7280);
          margin-right: 6px;
        }
      }

      & .view {
        margin-top: 27px;
        @include display-flex(flex-start);
        gap: 29px;

        & .item {
          @include display-flex;
          gap: 12px;
          text-transform: capitalize ;

          & div {
            width: 40px;
            height: 40px;
            background: rgba(51, 51, 51, 0.08);
            border-radius: 4px;
            @include display-flex;
            cursor: pointer;

            & img {
              width: 24px;
              height: 24px;
            }
          }

          & span {
            @include fonts(400, 16px, 24px, $accent);
          }
        }
      }
    }
  }

  & .view_mobil {
    display: none;
  }

  & .photos {
    margin-top: 30px;

    & h2 {
      @include fonts(700, 16px, 24px, $accent);
    }

    & .wrapper {
      margin-top: 22px;
      @include display-flex;
      gap: 19px;

      & img {
        border-radius: 4px;
        object-fit: cover;
      }

      & .basic {
        width: 562px;
        height: 380px;
      }

      & .second,
      .third {
        width: 263px;
        @include display-flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 20px;

        & img {
          width: 263px;
          height: 180px;
        }
      }
    }
  }

  & .show_all {
    display: none;
  }

  @media screen and (max-width: 500px) {

    overflow: hidden;
    & .workcation {
      margin-top: 35px;
      @include display-flex(flex-start);
      gap: 13px;

      & .circle {
        width: 71px;
        height: 71px;

        & svg {
          width: 40px;
        }
      }

      & .right_side {
        @include display-flex(center, flex-start);
        flex-direction: column;
        gap: 1px;

        & h2 {
          font-size: 16px;
          margin-bottom: 2px;
        }

        & .text {
          margin-top: 3px;
          @include display-flex(flex-start, flex-start);
          flex-direction: column;

          & h3 {
            @include fonts(500, 14px, 16px, $accent);
          }

          & div {
            font-size: 14px;
            margin-right: 0;
          }
        }

        & .view {
          display: none;
        }
      }
    }

    & .view_mobil {
      margin-top: 20px;
      @include display-flex(flex-start);
      gap: 30px;

      & .item {
        @include display-flex;
        gap: 5px;

        & div {
          width: 30px;
          height: 30px;
          background: rgba(51, 51, 51, 0.08);
          border-radius: 4px;
          @include display-flex;
          cursor: pointer;

          & img {
            width: 18px;
            height: 18px;
          }
        }

        & span {
          @include fonts(400, 14px, 24px, $accent);
        }
      }
    }

    & .photos {
      margin-top: 24px;
      padding-bottom: 262px;
      position: relative;

      & h2 {
        @include fonts(700, 14px, 24px, $accent);
      }

      & .wrapper {
        position: absolute;
        width: 465px;
        height: 248px;
        left: 0;
        top: 38px;
        margin-top: 0;
        gap: 7px;

        & img {
          border-radius: 12px;
        }

        & .basic {
          width: 181px;
          height: 248px;
        }

        & .second {
          width: 89px;
          row-gap: 8px;

          & img {
            width: 89px;
            height: 120px;
          }
        }

        & .third {
          width: 181px;
          @include display-flex;
          flex-direction: column;
          flex-wrap: wrap;
          row-gap: 20px;

          & img {
            width: 181px;
            height: 248px;
          }

          & img:last-child {
            display: none;
          }
        }
      }
    }

    & .show_all {
      margin: 18px auto 0;
      width: 133px;
      height: 36px;
      border: 1px solid $accent;
      border-radius: 8px;
      background: #ffffff;
      @include display-flex;
      @include fonts(500, 12px, 14px, $accent);
      cursor: pointer;
    }
  }
}
