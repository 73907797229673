@import "../../styles/colors";
@import "../../styles/mixins";

.content {
  width: 730px;
  height: 190px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;

  box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
    0 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 8px;

  padding: 8px;

  @include display-flex(space-between);

  .text {
    padding-left: 32px;
    .title {
      @include fonts(600, 20px, 27px, $accent);
      margin-bottom: 8px;
    }

    .description {
      @include fonts(400, 16px, 26px, $accent);
      font-feature-settings: "salt";
    }
  }

  .button_box {
    width: 235px;
    height: 174px;
    background: rgba(81, 201, 194, 0.26);
    border-radius: 8px;
    padding: 30px 42px;

    & button {
      all: unset;
      width: 151px;
      height: 46px;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
    }

    & button:first-child {
      background: $jazzy_jade;
      margin-bottom: 22px;
      @include fonts(600, 14px, 14px, white);
    }

    & button:last-child {
      background: white;
      @include fonts(600, 14px, 14px, $accent);
    }
  }
}
