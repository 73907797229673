@import "src/styles/colors";
@import "src/styles/mixins";

.layout {
  height: 1725px;
  position: absolute;
  z-index: 1001;
  top: 62px;
  left: 0;
  right: 0;
  background: rgba(7, 2, 51, 0.39);
  backdrop-filter: blur(2px);
  @include tablet {
    top: 79px;
    height: 1101px;

  }
  @include desktop {
    height: 952px;

  }

  .modal {
    position: relative;
    top: 18px;
    left: 7px;
    padding: 43px 40px 36px;
    background-color: #fff;
    border: 1px solid #E4E5E7;
    width: 360px;
    box-shadow: 0 60px 160px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    @include tablet {
      top: 20px;
    }
    @include desktop {
      left: 39px;
    }


    .inner {
      position: absolute;
      top: -10px;
      left: 16.5px;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border-top: 1px solid #E4E5E7;
      border-left: 1px solid #E4E5E7;
      transform: rotate(45deg);
      @include desktop {
        left: 20px;
      }
    }

    .close {
      display: none;
      position: absolute;
      border: 0;
      background-color: transparent;
      width: 26px;
      height: 26px;
      top: 10px;
      right: 10px;
      @include display-flex;
      z-index: 16;
      border-radius: 50%;
      @include tablet {
        display: none;
      }

      &:hover{
        border: 1px solid #3D3D3F;
      }

      & svg{
        width: 20px;
      }

    }

    .nav {
      display: flex;
      flex-direction: column;
      margin-bottom: 64px;
      padding-left: 11px;

      .link {
        border: 0;
        background-color: transparent;
        color: #1d1d1d !important;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        cursor: pointer;
        margin-right: auto;

        &.active {
          color: #2196F3 !important;
          text-decoration-line: underline;
          font-weight: 400;
          font-size: 21px;
          line-height: 25px;
        }

        &:not(:last-child) {
          margin-bottom: 41px;
        }
      }

    }

    .social_list {
      display: flex;
      padding-left: 13px;
      margin-bottom: 39px;

      .social_item {

        &:not(:last-child) {
          margin-right: 21px;
        }

        .social_link {
          @include display-flex();
          width: 40px;
          height: 40px;
          background: #2196F3;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #EDEDED;
      margin-bottom: 22px;
    }

    .info_list {
      padding-left: 13px;

      .info_item {
        &:not(:last-child) {
          margin-bottom: 17px;
        }

        .info_link {
          @include fonts(400, 18px, 21px, #1d1d1d);
          opacity: .4;
        }
      }
    }
  }
}


