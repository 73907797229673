@import "src/styles/colors";
@import "src/styles/mixins";

.sliderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.slider {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttons {
  margin-top: 50px;
  display: flex;

  & button {
    all: unset;
    height: 52px;
    width: 52px;
    @include display-flex;
    margin: 0 5px;
    transition: all 0.3s linear;
    border-radius: 50%;

    & svg {
      color: $carbon;
    }

    &:hover {
      background-color: $carbon;

      & svg {
        color: white;
      }
    }
  }
}
