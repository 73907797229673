@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  position: absolute;
  top: 110px;
  right: 55px;
  width: 350px;
  //height: 325px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  background-color: #ffffff;

  z-index: 1002;

  &:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background: white;
    top: -8px;
    right: 30px;
    border-top: 1px solid #e6e7e9;
    border-left: 1px solid #e6e7e9;
    border-radius: 2px;
  }

  .title {
    height: 67px;
    @include display-flex(flex-start);
    @include fonts(500, 16px, 19px, $accent);
  }

  .block {
    & .item {
      height: 48px;
      padding-left: 20px;
      @include display-flex(flex-start);
      transition: all 0.5s ease;
      cursor: pointer;

      & div {
        @include fonts(400, 14px, 20px, rgba(24, 24, 25, 0.9));
        width: 293px;
        @include display-flex(flex-start);
        margin-left: 12px;
        transition: all 0.5s ease;

      }

      &:hover {
        background: #070233;
      }
      &:hover div {
        color: #fff;

      }


    }
    .line__short {
      margin-left: auto;
      width: 294px;
      height: 1px;
      background: #E6E7E9;
    }
  }

  & button {
    height: 64px;
    width: 100%;
    border: none;
    border-top: 1px solid #e6e7e9;
    background: inherit;
    @include display-flex(flex-end);
    border-radius: 0 0 8px 8px;
    padding-right: 20px;
    cursor: pointer;

    & svg {
      height: 9px;
      color: $jazzy_jade;
    }

    & span {
      @include fonts(500, 14px, 20px, $jazzy_jade);
      letter-spacing: -0.3px;
    }
  }
}
