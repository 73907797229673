@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  width: 100%;
  height: 68px;
  position: fixed;
  box-shadow: 0px -2px 7px 0px #0000000d;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  margin: 0 auto;
  z-index: 13;

  @media screen and (max-width: 1050px) {
    padding: 30px 19px 40px 19px;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-bottom: none;
    z-index: 100;
    background: white;
  }
}

.burger {
  display: none;

  @media screen and (max-width: 1050px) {
    display: block;
    width: 24px;
  }
}

.logo {
 min-width: 90px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e5e5e5;

  position: relative;



  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 100%;
    border-right: none;
  }
}

.title {
  flex: 0 1 249px;
  width: 249px;
  margin: 0;
  padding: 0;
  @include fonts(600, 24px, 28.13px, $carbon);
  text-align: center;
  cursor: pointer;


  @media screen and (max-width: 1050px) {
    display: inline;
    @include fonts(600, 20px, 23px, $carbon);
  }
}

.arrows {
  all: unset;
  cursor: pointer;

  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 1050px) {
    display: none;
  }
}

.open {
  right: unset;
  left: 35px;
  transform: rotate(180deg) translateY(50%);
}

.right_header {
  flex: 10 1 auto;
  position: relative;
  //width: calc(100% - 250px);
  height: 100%;

  & .wrapper {
    //max-width: 1169px;
    width: 100%;
    padding-left: 17px;
    margin: 0 auto;
    height: 100%;
    @include display-flex;
  }

  @media screen and (max-width: 1050px) {
    width: auto;

    & .wrapper {
      width: auto;
      padding: 0;
      margin: 0;
    }
  }
}

.content {
  position: relative;
  padding: 10px 20px 10px 16px;
  width: calc(100% - 241px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: "";
    display: inline-block;
    height: 48px;
    width: 2px;
    background-color: #e5e5e5;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 1050px) {
    display: inline;
    padding: 0;
    width: auto;

    &::after {
      content: none;
    }
  }
}

.links {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: none;
  }
}

.link {
  transition: all 0.3s linear;
  @include fonts(400, 14px, 20px, $carbon);
  margin-right: 32px;

  &:hover {
    color: $carbon;
    font-weight: 500;
  }
}

.notification {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & svg {
    color: #4d556fc2;
    height: 24px;
  }
}

//.red_circle {
//  position: relative;
//
//  &::after {
//    content: '';
//    display: inline-block;
//    position: absolute;
//    @include circle(5px, 0, #DD425A, true);
//    top: 0;
//    right: 0;
//  }
//}

.account {
  width: 241px;
  height: 100%;
  @media screen and (max-width: 1050px) {
    display: none;
  }
}
