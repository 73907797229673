@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  //padding: 8px 0 16px;
  @include display-flex(flex-start);
  //padding: 0 19px;
  gap: 31px;

  & .icon {
    position: relative;

    & .logo {
      @include circle(80px, 0, transparent);
      object-fit: cover;
    }

    & .change_icon {
      position: absolute;
      bottom: 0;
      right: 0;

      & input {
        display: none;
      }

      & .button {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        box-shadow: 0 4px 16px 0 #b3c0e752;
        background-color: #fff;
        border-radius: 50%;

        & svg {
          width: 16px;
          color: #4d556fc2;
        }
      }
    }
  }

  & .text{
    @include display-flex(flex-start, flex-start);
    flex-direction: column;

    & .name {
      @include fonts(700, 18px, 24px, #2d3748);
    }

    & .mail {
      @include fonts(400, 14px, 20px, #313131);
    }

    & .registration {
      margin-top: 7px;
      @include fonts(400, 12px, 20px, #313131);
      mix-blend-mode: normal;
      opacity: 0.64;
    }

    & .statistics {
      display: flex;
      align-items: center;

      & .progress {
        margin-left: 9px;
        @include fonts(500, 12px, 14.22px, $carbon);
      }
    }

  }

}
