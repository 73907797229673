@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  padding-top: 22px;

  .container {
    margin: 0 auto;
    padding: 0 20px;
    width: 1392px;

    .link {
      @include fonts(500, 24px, 48px, $main_text);

      .icon{
        margin-right: 18px;
      }
    }

    .path {
      @include fonts(400, 12px, 20px, $jazzy_jade);
      padding-left: 42px;

      .active {
        @include fonts(400, 12px, 20px, $main_text);

      }
    }
  }
}

