@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  padding: 101px 0 88px;

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 500px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1440px;
      padding: 0 52px;
    }

    .pretitle {
      text-transform: uppercase;
      text-align: center;
      @include fonts(500, 18px, 32px, #1598FF);
      margin-bottom: 10px;

      @include tablet {
        text-align: unset;

      }

      @include desktop {
        @include fonts(500, 20px, 32px, #1598FF);

      }
    }

    .meta {
      margin-bottom: 50px;
      @include desktop {
        @include display-flex(space-between);
        margin-bottom: 92px;

      }

      .title {
        @include fonts(600, 24px, 56px, 1d1d1d);
        margin-bottom: 20px;
        text-align: center;

        @include tablet {
          @include fonts(600, 36px, 66px, 1d1d1d);
          text-align: unset;

        }
        @include desktop {
          margin-bottom: unset;
          width: 708px;
          @include fonts(600, 62px, 77px, 1d1d1d);

        }
      }

      .btn_block {
        @include display-flex();
        flex-direction: column;

        @include tablet {
          flex-direction: row;
          @include display-flex(flex-start);

        }

        @include desktop {
          @include display-flex();
        }

        .btn {
          border-radius: 10px;
          cursor: pointer;
          border: 0;
          background-color: transparent;
          width: 208px;
          height: 62px;
          @include fonts(700, 20px, 59px, $carbon);
          transition: border .25s cubic-bezier(0.4, 0, 0.2, 1);
          &:first-child {
            margin-bottom: 15px;
            @include tablet {
              margin-bottom: unset;
              margin-right: 32px;

            }
          }

          &:hover, &:focus {
            border: 1px solid #FF1700;
          }
        }

        .btn.active {
          background: #FF1700;
          border: 1px solid #FF1700;
          color: #fff;
        }

      }
    }
  }

  .wrapper {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 500px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1052px;
    }

    .list {
      list-style: none;

      .item {
        width: 100%;
        padding: 28px 15px 27px;
        background: rgba(244, 246, 252, 0.5);
        border-radius: 20px;
        cursor: pointer;
        @include tablet {
          padding: 28px 40px 27px;

        }

        &:not(:last-child) {
          margin-bottom: 29px;
        }

        .data {
          display: flex;
          align-items: center;

          .number {
            @include fonts(500, 18px, 66px, #1598FF);
            margin-right: 10px;
            @include tablet {
              @include fonts(500, 25px, 77px, #1598FF);
              margin-right: 46px;

            }
          }
          .subtitle {
            @include fonts(500, 16px, 36px, #1D1D1D);
            width: 90%;

            @include tablet {
              @include fonts(500, 22px, 36px, #1D1D1D);

            }
          }
          .subtitle.active {
            @include fonts(600, 16px, 36px, #fff);

            @include tablet {
              @include fonts(600, 22px, 36px, #fff);

            }
          }
          .icon {
            cursor: pointer;
            margin-left: auto;
            fill: #1598FF;
          }
        }
        .description {
          margin-top: 15px;
          @include fonts(400, 14px, 32px, #fff);

          @include tablet {
            @include fonts(400, 22px, 37px, #fff);

          }
        }
      }

      .active {
        background: #070233;
      }
    }
  }
}
