@import "src/styles/colors";
@import "src/styles/mixins";

.modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(#000000, 0.2);
  mix-blend-mode: normal;
  padding: 20px;
  z-index: 10000;
  @include display-flex;

  & .content{
    width: 800px;
    height: 600px;
    position: relative;
    background: white;
    border-radius: 4px;

    & img{
      width: 800px;
      height: 600px;
      object-fit: cover;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
      border-radius: 4px;
    }

    & .close{
      position: absolute;
      width: 62px;
      height: 62px;
      background: rgba(51, 51, 51, 0.6);
      border-radius: 8px;
      @include display-flex;
      top: -71px;
      right: -72px;

      & svg{
        width: 32px;
        height: 32px;
        color: white;
      }
    }

    & .left, .right{
      position: absolute;
      width: 17px;
      height: 17px;
      border-top: 3px solid $accent;
      border-left: 3px solid $accent;
      top: 287px;
      border-radius: 2px;
      transition: all .5s ease-in-out;

      &:hover{
        border-color: white;
      }
    }

    & .left{
      transform: rotate(-45deg);
      left: -36px;
    }

    & .right{
      transform: rotate(135deg);
      right: -36px;
    }
  }
}
