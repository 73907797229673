@import "../../../../../../styles/colors";
@import "../../../../../../styles/mixins";

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 19px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.flexContainer {
  & div {
    display: flex;

    & div:first-child {
      margin-left: 3px;
      margin-right: 17px;
      margin-bottom: 17px;
    }

    & div:last-child {
      margin-right: 0;
      margin-bottom: 17px;
    }
  }

  @media screen and (max-width: 500px) {
    & div {
      display: block;

      & div:first-child {
        margin: 0 0 12px 0;
      }

      & div:last-child {
        display: flex;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.addSocial {
  all: unset;
  cursor: pointer;
  border: 1px dashed #e5e5e5;
  border-radius: 8px;

  @include fonts(400, 14px, 19.6px, $carbon);

  display: flex;
  align-items: center;
  padding-right: 36px;

  & svg {
    width: 22px;
    color: #1818196b;
    margin: 17px;
  }
}

.socials {
  display: flex;
  flex-wrap: wrap;
}

.error {
  @include fonts(700, 14px, 16px, $form_red);
}
