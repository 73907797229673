@import "src/styles/colors";
@import "src/styles/mixins";

.mobile_title {
  display: none;

  @media screen and (max-width: 1050px) {
    display: block;

    & h3 {
      @include fonts(700, 16px, 24px, #313131);
    }
    //& .progress_block {
    //  margin-top: 15px;
    //  @include display-flex(space-between);
    //  padding-left: 18px;
    //
    //  & div {
    //    @include fonts(500, 14px, 24px, $accent);
    //  }
    //
    //  & .title_progress {
    //    position: relative;
    //
    //    &:before {
    //      content: "";
    //      position: absolute;
    //      width: 9px;
    //      height: 9px;
    //      border-radius: 50%;
    //      background: $jazzy_jade;
    //      left: -18px;
    //      top: 8px;
    //    }
    //  }
    //}
    //
    //& .progress_line {
    //  margin-top: 13px;
    //  position: relative;
    //  width: 100%;
    //  height: 5px;
    //  border-radius: 3px;
    //  background: rgba(228, 228, 228, 0.7);
    //
    //  & div {
    //    position: absolute;
    //    height: 100%;
    //    background: $jazzy_jade;
    //    border-radius: inherit;
    //  }
    //}
  }
}

.title {
  @include display-flex(space-between);
  max-width: 1126px;
  width: 100%;
  position: fixed;
  z-index: 10;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -22px;
    z-index: 9;
    height: 86px;
    width: 100%;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.26) 90%,
        rgba(255, 255, 255, 0.1) 95%,
        rgba(255, 255, 255, 0.01) 100%
    );
  }

  span {
    margin: 10px 12px;
    @include fonts(700, 20px, 24px, #313131);
    z-index: 10;
  }

  button {
    width: 218px;
    border-radius: 8px;
    border: none;
    background-color: $midday_sun;
    padding: 12px 0;
    cursor: pointer;
    z-index: 10;

    @include fonts(500, 14px, 20px, $accent);
  }

  @media screen and (max-width: 1050px) {
    background: white;
    bottom: 0;
    left: 0;
    padding: 11px 0;

    &::before {
      content: none;
    }

    span {
      display: none;
    }

    button {
      width: calc(100% - 38px);
      padding: 12px 0;
      margin: 0 auto;

      @include fonts(500, 16px, 20px, $accent);
    }
  }
}

.progress {
  @include display-flex(space-between);
  padding: 20px 24px;
  background: white;
  border-radius: 8px;
  position: relative;
  max-width: 1114px;
  margin: 0 auto 0;

  span {
    @include fonts(500, 16px, 24px, $accent);
    z-index: 1;

    span.price {
      @include fonts(700, 20px, 24px, $accent);
      margin: 0 19px 0 14px;
    }
  }

  div {
    position: absolute;
    height: 100%;
    background: #ffdc8e;
    border-radius: 8px;
    left: 0;
  }
}

.step {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  @include display-flex;
  width: 100%;
  height: auto;
  border: none;
  margin-top: 33px;


  & .item_mobile1,
  .item_mobile2,
  .item_mobile3 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  & .item_mobile1 {
    border: 1px solid $jazzy_jade;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $midday_sun;
      top: 12px;
      left: 12px;
    }
  }

  & .item_mobile2 {
    border: 1px solid $jazzy_jade;
    position: relative;

    &:before {
      content: "02";
      position: absolute;
      @include fonts(500, 14px, 16px, $jazzy_jade);
      top: 12px;
      left: 12px;
    }
  }

  & .item_mobile3 {
    border: 1px solid $jazzy_jade;
    position: relative;

    &:before {
      content: "03";
      position: absolute;
      @include fonts(500, 14px, 16px, $jazzy_jade);
      top: 12px;
      left: 12px;
    }
  }

  & .line_mobile {
    width: calc(50% - 60px);
    height: 1px;
    border-top: 1px dashed $jazzy_jade;
  }
}
