@import "src/styles/colors";
@import "src/styles/mixins";

.data {
  background: #fafafa;
  border-top: 1px solid #ebebeb;
  padding: 17px 19px 19px 19px;

  & h3 {
    @include fonts(600, 16px, 19px, $accent);
  }

  & .title {
    margin-top: 9px;
    @include fonts(400, 14px, 19px, $accent);
  }

  & .description {
    display: block;
    margin: 13px 0 0 0;

    textarea {
      width: 100%;
      height: 100px;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 6px;
      @include fonts(400, 12px, 14px, #202124);
      background: #f6f6f6;
    }

    span {
      display: block;
      width: 100%;
    }
  }

  & .input_select {
    display: block;
    margin: 0;

    & label {
      @include fonts(400, 14px, 20px, $accent);
    }

    & select {
      width: 100%;
    }
  }

  & .specify {
    margin-top: 22px;

    & span {
      @include fonts(400, 14px, 20px, $accent);
    }

    & .as_input {
      width: 100%;
      height: 40px;
      border: 1px solid #e9ecef;
      border-radius: 4px;
      @include display-flex(space-between);
      background: transparent;

      & div:first-child {
        @include fonts(600, 16px, 25px, $accent);
        @include display-flex(flex-start);
        padding-left: 10px;
      }

      & div:last-child {
        width: 50px;
        border-left: 1px solid #e9ecef;
        height: 100%;
        @include fonts(500, 16px, 19px, #676b79);
        @include display-flex;
      }
    }
  }

  & .save {
    margin-top: 23px;
    @include display-flex(space-between);

    & div {
      width: 209px;
      height: 36px;
      background: #f1f1f3;
      padding-left: 10px;
      border-radius: 4px;
      @include display-flex(flex-start);
      @include fonts(600, 16px, 25px, $accent);
    }

    & button {
      width: 117px;
      height: 36px;
      background: $jazzy_jade;
      border: none;
      border-radius: 4px;
      @include display-flex;
      @include fonts(600, 12px, 12px, #e4ecf7);
    }
  }
}
