@import "src/styles/colors";
@import "src/styles/mixins";

.profile_portfolio {
  width: 410px;
  padding: 24px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: relative;

  @include desktop {
    width: 784px;
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;
  @media screen and (max-width: 1050px) {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.subtitle {
  @include fonts(400, 14px, 24px, $carbon);
  margin-bottom: 22px;
  @media screen and (max-width: 1050px) {
    @include fonts(400, 12px, 24px, #000000);
    margin-bottom: 16px;
  }
}
