@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  padding: 0 19px;
  margin: 8px 0;

  & .profile_tab {
    padding: 8px;
    width: 100%;
    height: 56px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    @include display-flex(space-between, center);

    & .left_block {
      display: inline-flex;

      & .icon {
        @include display-flex;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: #33333314;
        margin-right: 16px;

        & svg {
          width: 24px;
          height: 24px;
          color: $carbon;
        }

        &:hover {
          background-color: $jazzy_jade;

          & svg {
            color: white;
          }
        }
      }

      & .active_icon {
        background-color: $jazzy_jade !important;

        & svg {
          color: white !important;
        }
      }

      & .title {
        @include fonts(500, 14px, 20px, $accent);
        @include display-flex;
      }

      & .active_title {
        @include fonts(500, 14px, 20px, $jazzy_jade);
      }
    }

    & .down_icon {
      width: 20px;
      color: #999999;
      transform: rotate(-90deg);
    }

    & .active_down_icon {
      transform: none;
    }
  }
}
