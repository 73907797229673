@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  max-width: 1114px;
  margin: 31px auto 0;
  @include display-flex;
  gap: 29px;

  & .data_event {
    width: 727px;

    & .description {
      //margin-top: 5px;

      & h2 {
        @include fonts(700, 16px, 20px, $accent);
        margin-bottom: 20px;
      }

      & p {
        width: 715px;
        @include fonts(400, 16px, 28px, #6b7280);
        text-align: left;
      }
    }

    & .additional {
      margin-top: 30px;

      & h2 {
        @include fonts(700, 16px, 24px, $accent);
        margin-bottom: 20px;
      }

      & p {
        width: 715px;
        @include fonts(400, 16px, 28px, #999999);
        text-align: left;
      }
    }

    & .location {
      margin-top: 30px;

      & h2 {
        @include fonts(700, 16px, 20px, $accent);
      }

      & .input_location {
        margin-top: 13px;
        width: 100%;
        height: 57px;
        background: #f5f5f5;
        border-radius: 4px;
        padding-left: 10px;
        @include display-flex(flex-start);

        & .icon {
          width: 40px;
          height: 40px;
          background: rgba(51, 51, 51, 0.08);
          border-radius: 8px;
          @include display-flex;
          margin-right: 11px;
        }

        & span {
          @include fonts(500, 14px, 23px, $accent);
          font-feature-settings: "salt" on;
        }
      }

      & .map {
        margin-top: 12px;
        width: 100%;
        height: 345px;
        position: relative;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        & .absolute {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.4)
          );
          z-index: 2;
        }
      }
    }
  }

  & .details {
    width: 360px;

    & h2 {
      @include fonts(700, 16px, 24px, $accent);
      margin-bottom: 24px;
    }

    & .item_block {
      @include display-flex;
      flex-direction: column;
      gap: 20px;

      & .item {
        width: 360px;
        height: 86px;
        border-radius: 12px;
        background: #f5f5f5;
        border: 1px solid #f5f5f5;
        padding: 20px 33px;
        @include display-flex(flex-start);
        gap: 17px;

        & img {
          width: 24px;
          height: 24px;
        }

        & .text {
          @include display-flex(center, flex-start);
          flex-direction: column;

          & h3 {
            @include fonts(400, 14px, 14px, $accent);
          }

          & div {
            margin-top: 8px;
            @include fonts(400, 16px, 24px, #999999);
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin: 24px auto 0;
    @include display-flex;
    flex-direction: column;
    gap: 24px;

    & .data_event {
      width: 100%;

      & .description {
        & h2 {
          font-size: 14px;
          margin-bottom: 10px;
        }

        & p {
          width: 100%;
          font-size: 14px;
        }
      }

      & .additional {
        margin-top: 24px;

        & h2 {
          font-size: 14px;
          margin-bottom: 6px;
        }

        & p {
          width: 100%;
          font-size: 14px;
        }
      }

      & .location {
        margin-top: 24px;

        & h2 {
          font-size: 14px;
        }

        & .input_location {
          margin-top: 10px;
          height: 50px;

          & .icon {
            width: 30px;
            height: 30px;
            border-radius: 4px;
          }

          & span {
            font-size: 12px;
          }
        }

        & .map {
          margin-top: 10px;
          height: 158px;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
          }

          & .absolute {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.4),
              rgba(255, 255, 255, 0.4)
            );
            z-index: 2;
          }
        }
      }
    }

    & .details {
      width: 100%;

      & h2 {
        @include fonts(700, 14px, 20px, $accent);
        margin-bottom: 10px;
      }

      & .item_block {
        gap: 12px;

        & .item {
          width: 333px;
          height: 76px;
          padding: 17px 19px;
          gap: 15px;

          & img {
            width: 22px;
            height: 22px;
          }

          & .text {
            & div {
              margin-top: 7px;
              @include fonts(400, 14px, 21px, #999999);
            }
          }
        }
      }
    }
  }
}
