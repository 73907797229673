@import "../../styles/colors";
@import "../../styles/mixins";

.section {
  background-color: #070233;
  background-image: url("../../assets/images/zaglushka/bg-main.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  height: 100%;
  width: 100%;
  padding: 190px 0 254px;

  .title1 {
    display: block;
    margin: 0 auto 160px;
  }

  .title2 {
    display: block;
    margin: 0 auto 25px;
  }

  .title3 {
    display: block;
    margin: 0 auto 25px;
  }
  .title4 {
    display: block;
    margin: 0 auto 54px;
  }

  .paragraph {
    margin: 0 auto 26px;
    @include display-flex();
  }
  .social_list {
    @include display-flex();

    .social_item {
      &:not(:last-child) {
        margin-right: 17px;
      }

      .social_link {
        @include display-flex();
        width: 40px;
        height: 40px;
        background: #2196F3;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
