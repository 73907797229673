@import "src/styles/colors";
@import "src/styles/mixins";

.slider {
  position: relative;
  overflow: hidden;
  height: 316px;
  margin-top: 56px;

  & .title {
    @include display-flex(flex-start);
    position: relative;

    h3 {
      @include fonts(600, 34px, 40px, $accent);
    }
  }
}

.slider_container {
  position: absolute;
  @include display-flex(flex-start);
  flex-wrap: nowrap;
  bottom: 0;
}

.button {
  position: absolute;
  padding-right: 0;
  right: 50px;
  @include display-flex;

  & .svg_cicl {
    & circle,
    path {
      transition: all 1s ease-in-out;
    }
  }

  & .arrow {
    width: 32px;
    height: 32px;
    background: transparent;
    cursor: pointer;
    position: relative;
    border: none;
    z-index: 3;

    &:first-child {
      margin-right: 13px;
    }
  }
}

@media screen and (max-width: 500px) {
  .slider {
    height: 257px;

    .title {
      h3 {
        @include fonts(600, 16px, 24px, $accent);
      }
    }
  }

  .slider_container {
    bottom: 45px;
  }

  .button {
    top: 225px;

    & .svg_cicl {
      position: absolute;
      top: -4px;
      left: -4px;
      transform: scale(0.75);
    }

    & .arrow {
      width: 23px;
      height: 23px;

      &:first-child {
        margin-right: 13px;
      }
    }
  }
}
