@import "src/styles/colors";
@import "src/styles/mixins";

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0.5);
  mix-blend-mode: normal;
  z-index: 1001;
}
