@import "src/styles/colors";
@import "src/styles/mixins";

.prev_footer {
  margin-top: 39px;
  @include display-flex(flex-start, center);
  flex-direction: column;

  & button {
    width: 176px;
    height: 55px;
    background: #333333;
    box-shadow: -2px -2px 5px rgba(255, 255, 255, 0.1),
      2px 2px 5px rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    border: none;
    @include fonts(500, 20px, 10px, #ffffff);
    cursor: pointer;

    &:hover {
      color: $jazzy_jade;
    }
  }

  & .subscribe {
    margin: 123px 0 119px;

    width: 1200px;
    height: 454px;
    background: rgba(248, 245, 230, 0.85);
    border-radius: 15px;
    position: relative;

    @include display-flex;
    flex-direction: column;

    .filter_img {
      position: absolute;
      height: 100%;
      width: 100%;
      background: url("../../../assets/images/loginBackgr.png");
      background-size: cover;
      opacity: 0.3;
      mix-blend-mode: luminosity;
      border-radius: 15px;
      z-index: -1;
    }

    & svg {
      position: absolute;
      top: -255px;
      left: -480px;
      z-index: 1;
    }

    & h2 {
      width: 805px;
      height: 130px;
      @include fonts(700, 54px, 65px, $accent);
      text-align: center;
    }

    & .description {
      margin-top: 24px;
      @include fonts(400, 16px, 26px, $accent);
    }

    & button {
      margin-top: 48px;
      width: 148px;
      height: 50px;
      background: $jazzy_jade;
      border-radius: 15px;
      border: none;

      @include fonts(600, 18px, 18px, #ffffff);
      text-align: center;
      letter-spacing: 0.02em;
      cursor: pointer;

      &:hover {
        @include fonts(500, 18px, 18px, $jazzy_jade);
        background: white;
        border: 1px solid $jazzy_jade;
      }
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 51px;

    & button {
      width: 119px;
      height: 43px;
      border-radius: 12px;
      @include fonts(500, 14px, 10px, #ffffff);
    }

    & .subscribe {
      margin: 210px 0 0;

      width: 100%;
      height: 466px;
      border-radius: 0;

      & svg {
        top: -280px;
        left: -395px;
        transform: scale(0.5);
      }

      & h2 {
        width: 330px;
        height: 82px;
        @include fonts(700, 28px, 41px, $accent);
      }

      & .description {
        margin-top: 17px;
        @include fonts(400, 16px, 24px, $accent);
        width: 302px;
        text-align: center;
      }

      & button {
        margin-top: 40px;
        width: 119px;
        height: 43px;
        border-radius: 12px;

        @include fonts(500, 14px, 10px, #ffffff);
        text-align: center;
        letter-spacing: 0;

        &:hover {
          @include fonts(500, 18px, 18px, $jazzy_jade);
          background: white;
          border: 1px solid $jazzy_jade;
        }
      }
    }
  }
}
