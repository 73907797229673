@import "src/styles/colors";
@import "src/styles/mixins";

.leftside {
  grid-area: leftside;
  background: #f8f8fa;
  border-right: 1px solid #e5e5e5;
  padding-left: 5px;
  //height: 814px;
  height: 100%;
  overflow-y: scroll;
  filter: blur(0);

  & .left_content {
    padding: 25px 8px 0 3px;

    margin-top: 67px;

    & div:last-child{
      & .streaming{
        padding-bottom: 20px;
      }

    }

    & h3 {
      width: 100%;
    }

    & h3:first-child {
      width: 100%;
      height: 63px;
      background-color: $accent;
      padding-left: 19px;
      border-radius: 8px 8px 0 0;

      @include fonts(600, 14px, 24px, #ffffff);
      @include display-flex(flex-start);
    }

    & h3.title {
      width: 100%;
      height: 55px;
      background-color: #ffffff;
      padding-right: 18px;

      @include fonts(500, 14px, 24px, $accent);
      @include display-flex(flex-end);
      border-bottom: 1px solid #e5e5e5;
    }

    & .streaming {
      width: 100%;
      background-color: #ffffff;
      padding: 21px 19px 0;
    }
  }
}

.marginTop {
  margin-bottom: 13px;
}
