@import "src/styles/colors";
@import "src/styles/mixins";

.block {
  margin-top: 34px;
  border-top: 1px solid #e2e2e2;
  padding: 23px 0 18px;

  & h3 {
    @include fonts(500, 18px, 21px, rgba(51, 51, 51, 0.91));
  }

  @media screen and (max-width: 500px){
    margin-top: 30px;
    padding: 30px 0 40px;

    & h3 {
      @include fonts(500, 16px, 19px, rgba(51, 51, 51, 0.91));
    }
  }

}
