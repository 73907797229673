@import "../styles/colors";
@import "../styles/mixins";

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.media__container {
  @include fonts(400, 14px, 20px, $carbon);
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
  @include desktop {
    margin: 8px 12px;
  }

  .wrapper {
    position: relative;
    margin-top: 5px;

    & input {
      border-radius: 4px;
      border: 1px solid #f5f5f5;
      padding: 8px 8px 8px 60px;
      cursor: pointer;
      @include fonts(400, 16px, 24px, $accent);
      width: 100%;
      height: 56px;
      background-color: transparent;
      position: relative;

      @media screen and (min-width: 1051px){
        min-width: 355px;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      z-index: 0;
      width: 40px;
      height: 40px;
      background-color: #33333314;
      border-radius: 5px;
      @include display-flex();
    }
  }
}

.container {
  @include fonts(400, 14px, 20px, $carbon);
  display: flex;
  flex-direction: column;
  margin: 0 24px 15px 0;

  & input,
  & select {
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    padding: 8px 12px;
    width: 355px;
    margin-top: 5px;
    appearance: none;
    //background: transparent;
  }

  & select option {
    @include fonts(400, 14px, 20px, $carbon);
    padding: 5px 12px;
  }

  & textarea {
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    padding: 8px 12px;
    width: 734px;
    height: 67px;
  }
}

.phone {
  @include fonts(400, 14px, 20px, $carbon);
  display: flex;
  align-items: center;

  & input {
    width: 232px;
  }

  & select {
    width: 108px;
    margin-right: 15px;
  }
  @media screen and (max-width: 1050px) {
    & input {
      //width: 224px !important;
      width: calc(100% - 122px)!important;
    }

    & select {
      width: 108px !important;
      margin-right: 14px;
    }
  }
}

.text {
  @include fonts(400, 14px, 22px, #00000040);
  margin-top: 5px;
  text-align: right;
  padding-right: 14px;
  margin-left: auto;
}

.file {
  display: none;
}

.inputError {
  & input,
  & select,
  & textarea {
    border: 1px solid $form_red;
  }

  & label {
    color: $form_red;
  }
}
.textAreaFooter {
  display: flex;
  width: 734px;
  justify-content: space-between;
}

.errorText {
  font-size: 12px;
  color: $form_red;
  margin-top: 3px;
}

.input_file {
  display: flex;
  flex-direction: column;
  //margin: 0 12px!important;

  & .as_file {
    border: 1px dashed $background-color;
    border-radius: 8px;
    @include fonts(400, 16px, 22px, $accent);
    width: 272px;
    padding: 13px 17px;

    cursor: pointer;
    margin: 0 !important;

    @include display-flex(flex-start);

    .add_icon {
      width: 14px;
      color: $accent;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1050px) {
    & .as_file {
      @include fonts(400, 14px, 20px, $accent);
      letter-spacing: -0.5px;
      width: 272px;
      padding: 12px;

      .add_icon {
        width: 13px;
        color: rgba(24, 24, 25, 0.42);
        margin-right: 17px;
      }
    }
  }
}
.dta {
  width: 120px;
}
