@import "src/styles/colors";
@import "src/styles/mixins";

.mobile_title {
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
    margin-top: 33px;
    @include fonts(700, 16px, 24px, #313131);
  }
}

.proceed {
  display: none;
  @media screen and (max-width: 500px) {
    width: 100%;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 11px 19px;
    @include display-flex;

    & button {
      width: 100%;
      padding: 12px 0;
      border-radius: 8px;
      border: none;
      background-color: $ambrosia_cake;
      cursor: pointer;

      @include fonts(500, 16px, 20px, $accent);
    }
  }
}

.content {
  max-width: 1114px;
  margin: 0 auto 0;
  position: relative;

  & .title {
    position: fixed;
    width: 100%;
    max-width: 1114px;
    z-index: 10;
    top: 89px;
    @include display-flex(space-between);

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -22px;
      z-index: 9;
      height: 86px;
      width: 100%;
      background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.26) 90%,
          rgba(255, 255, 255, 0.1) 95%,
          rgba(255, 255, 255, 0.01) 100%
      );
    }

    & span {
      margin: 10px 12px;
      @include fonts(700, 20px, 24px, #313131);
      z-index: 10;
    }

    & button {
      width: 218px;
      border-radius: 8px;
      border: none;
      background-color: $midday_sun;
      padding: 12px 0;
      cursor: pointer;
      z-index: 10;
      @include fonts(500, 14px, 20px, $accent);
    }
  }

  & .step {
    position: relative;
    width: 1114px;
    height: 81px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    margin-top: 101px;
    @include display-flex;

    & .item {
      width: 371px;
      height: 100%;
      border-right: 1px solid #f5f5f5;
      position: relative;
      @include display-flex;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        border-top: 1px solid #f5f5f5;
        border-right: 1px solid #f5f5f5;
        transform: rotate(45deg);
        background: white;
        right: -8px;
      }

      &:last-child {
        border-right: none;
      }

      & .circle1,
      .circle2 {
        width: 41px;
        height: 41px;
        border-radius: 20px;
        margin-right: 15px;
        @include display-flex;
      }

      & .circle1 {
        background: $jazzy_jade;
      }

      & .circle2 {
        border: 2px solid $jazzy_jade;

        & div {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #ffdc8e;
        }

        & span {
          @include fonts(500, 14px, 16px, $jazzy_jade);
        }
      }

      & .text {
        & h4 {
          @include fonts(500, 16px, 16px, $jazzy_jade);
        }

        & p {
          margin-top: 1px;
          @include fonts(400, 14px, 20px, #787e91);
        }
      }

      & .item_mobile1,
      .item_mobile2,
      .item_mobile3 {
        display: none;
      }

      & .line_mobile {
        display: none;
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;

    & .title {
      display: none;
    }

    & .step {
      width: 100%;
      height: auto;
      border: none;
      margin-top: 33px;
      @include display-flex;

      & .item {
        display: none;
      }

      & .item_mobile1,
      .item_mobile2,
      .item_mobile3 {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      & .item_mobile1 {
        background: $jazzy_jade;
        @include display-flex;
      }

      & .item_mobile2 {
        border: 1px solid $jazzy_jade;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: $midday_sun;
          top: 12px;
          left: 12px;
        }
      }

      & .item_mobile3 {
        border: 1px solid $jazzy_jade;
        position: relative;

        &:before {
          content: "03";
          position: absolute;
          @include fonts(500, 14px, 16px, $jazzy_jade);
          top: 12px;
          left: 12px;
        }
      }

      & .line_mobile {
        width: calc(50% - 60px);
        height: 1px;
        border-top: 1px dashed $jazzy_jade;
      }
    }
  }
}

.mobile_description {
  display: none;

  @media screen and (max-width: 500px) {
    margin-top: 14px;
    @include display-flex(space-between);

    & span {
      @include fonts(500, 14px, 16px, $jazzy_jade);
    }
  }
}
