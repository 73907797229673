@import "src/styles/colors";
@import "src/styles/mixins";

.block {
  position: relative;
  margin: 47px 0 39px;

  .track_bg {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #e5e5e5;
    top: 0;
    z-index: 1;
  }

  & .track {
    position: absolute;
    width: 0;
    height: 4px;
    background: $accent;
    top: 0;
    z-index: 2;
  }

  & .point0,
  .point1,
  .point2,
  .point3,
  .point4,
  .point5 {
    position: absolute;
    width: 7px;
    height: 5px;
    background: white;
    top: 0;
    z-index: 3;

    & span {
      position: absolute;
      @include fonts(400, 12px, 14px, #717171);
      white-space: nowrap;
      top: 21px;
    }
  }

  & .point0 {
    left: 0;

    & span {
      left: 5px;
      top: -31px
    }
  }
  & .point1 {
    left: 20.7%;
  }
  & .point2 {
    left: 40%;
  }
  & .point3 {
    left: 59%;
  }
  & .point4 {
    left: 78.2%;
  }
  & .point5 {
    left: 99.5%;

    & span {
      left: -85px;
      top: -31px
    }
  }

  & .custom_range {
    position: absolute;
    width: 100%;
    height: 4px;
    z-index: 5;
    top: 1px;

    background: none;
    border: none;
    outline: none;

    color: transparent;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: 4px;
      border: none;
      background-color: transparent;
    }

    &::-moz-range-track {
      visibility: hidden;
    }

    &::-moz-range-thumb {
      position: relative;
      background: no-repeat center
        url("../../../../../assets/images/marketplace/thumb.png") white;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
      cursor: pointer;
      width: 25px;
      height: 25px;
      z-index: 10;
    }

    &::-webkit-slider-thumb {
      position: relative;
      background: no-repeat center
        url("../../../../../assets/images/marketplace/thumb.png") white;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
      cursor: pointer;
      width: 25px;
      height: 25px;
      -webkit-appearance: none;
      margin-top: -11px;
      z-index: 10;
    }
  }
}
