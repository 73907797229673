@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  margin-top: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  margin-right: 45px;
  @include fonts(700, 24px, 57px, $carbon);
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: inline-block;
    @include circle(22px, 0, $jazzy_jade, true);
    margin-right: 10px;
  }
}

.text {
  @include fonts(400, 24px, 28.13px, #707070);
}
