@import "src/styles/colors";
@import "src/styles/mixins";

.error_field {
  position: absolute;
  z-index: 1001;
  width: calc(100% - 250px);
  height: 68px;
  background: #fff4ea;
  padding: 0 56px 0 53px;
  @include display-flex(space-between);

  & .title {
    position: relative;
    @include fonts(500, 14px, 20px, $carbon);

    &:before {
      content: "!";
      position: absolute;
      top: 0;
      left: -28px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: #ffdc8e;
      color: white;
      @include display-flex;
    }
  }

  & .subscribe {
    cursor: pointer;
  }

  @media screen and (max-width: 500px){

    width: 100%;
    height: 90px;
    top: 0;
    padding: 0 20px 0 48px;
    @include display-flex(space-between);

    &:before {
      content: "!";
      position: absolute;
      top: 34px;
      left: 20px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ffdc8e;
      color: white;
      @include display-flex;
    }

    & .title {
      position: relative;
      @include fonts(500, 14px, 20px, $carbon);

      &:before {
        content: none;
      }
    }
  }
}
