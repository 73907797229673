@import "src/styles/colors";
@import "src/styles/mixins";

.upload {
  background: rgba(245, 245, 245, 0.24);
  border: 1px dashed #e5e5e5;
  box-sizing: border-box;
  border-radius: 12px;
  height: 200px;
  position: relative;
  cursor: pointer;
  margin: 0 24px 24px 0;

  @include display-flex();
  flex-direction: column;

  & button {
    all: unset;
    width: 323px;
    z-index: 100;

    @include display-flex();
    flex-direction: column;

    & svg {
      width: 44px;
      color: rgba(120, 126, 145, 0.7);
      display: block;
      margin-bottom: 20px;
    }

    & div {
      @include fonts(400, 18px, 21px, #676b79);
      text-align: center;

      & span {
        font: inherit;
        font-weight: 500;
      }
    }
  }

  .logo {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 50px;

    left: 24px;
    bottom: 24px;

    @include display-flex();

    & div {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0;
      bottom: 0;
      background: #51c9c2;
      border-radius: 16px;

      @include display-flex();

      & button {
        all: unset;
        @include display-flex();

        & svg {
          margin: 0;
          height: 16px;
          color: rgba(255, 255, 255, 0.76);
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    height: 160px;
    margin: 0 0 16px 0;

    & button {
      width: 253px;

      & svg {
        width: 33px;
        margin-bottom: 14px;
      }

      & div {
        @include fonts(400, 14px, 19px, #676b79);
        text-align: center;

        & span {
          font: inherit;
          font-weight: 500;
        }
      }
    }
  }

   & .actions {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 20px;
    bottom: 20px;
    right: 25px;
  }

  & .actionBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s linear;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: rgba(51, 51, 51, 0.8);

    & > svg {
      width: 20px;
      height: 20px;
      color: #7e92a2;
      margin-bottom: 0;
    }

    &:hover {
      background-color: #fff;
    }
  }
}

.file {
  display: none;
}

.pic_img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo_img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  opacity: 0.8;
}
