@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  position: relative;
  padding: 20px 0 92px;
  background-color: #F8F8FA;
  @include tablet {
    padding: 32px 0 175px;

  }
  .container {

    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    @include mobile {
      width: 480px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1165px;
      padding: 0 44px;
    }

    .title{
      @include fonts(700, 20px, 24px, #313131);
      @include tablet {
        margin-bottom: 32px;
      }
    }

    .content {
      margin: 91px 0 0 45px;
      overflow: hidden;
      @include display-flex(flex-start, stretch);
      flex-direction: column;
      gap: 67px;
    }
  }

  .btn {
    width: 195px;
    height: 48px;
    padding: 12px;
    border: 1px dashed #979797;
    border-radius: 8px;
    @include display-flex(flex-start);
    @include fonts(500, 14px, 20px, $accent);

    position: absolute;
    bottom: 26px;
    left: 20px;
    @include tablet {
      top: 24px;
      left: unset;
      right: 32px;
      width: 258px;
    }
    .icon {
      position: relative;
      top: -1px;
      margin-right: 12px;

    }
  }


}

