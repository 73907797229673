@import "src/styles/colors";
@import "src/styles/mixins";

.account {
  padding: 8px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  margin-bottom: 16px;
  position: relative;
}

.logo {
  @include circle(96px, 0, transparent);
  object-fit: cover;
}

.name {
  @include fonts(700, 18px, 24px, #2d3748);
}

.mail {
  @include fonts(400, 14px, 20px, #313131);
}

.registration {
  @include fonts(400, 12px, 20px, #313131);
}

.change_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  & input {
    display: none;
  }
}

.button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  box-shadow: 0 4px 16px 0 #b3c0e752;
  background-color: #fff;
  border-radius: 50%;

  & svg {
    width: 16px;
    color: #4d556fc2;
  }
}
