@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  width: 100%;
  margin: 100px auto 0 auto;

  & .header {
    border-top: 1px solid #eaecee;
    padding-top: 76px;
    max-width: 1350px;
    margin: 0 auto;
    @include display-flex(space-between);

    & h2 {
      @include fonts(600, 34px, 49px, $accent);
      font-feature-settings: "liga" off;
      width: 584px;
    }

    & p {
      @include fonts(400, 20px, 32px, #18191f);
      font-feature-settings: "liga" off;
      width: 524px;
    }
  }

  & .content {
    margin: 91px 0 0 45px;
    overflow: hidden;
    @include display-flex(flex-start, stretch);
    flex-direction: column;
    gap: 67px;
  }

  @media screen and (max-width: 500px) {
    margin: 96px auto 0 auto;
    padding: 0 17px;

    & .header {
      border-top: 1px solid #eaecee;
      padding-top: 25px;
      margin: 0 auto;
      @include display-flex(space-between);
      flex-direction: column;

      & h2 {
        @include fonts(600, 18px, 28px, $accent);
        font-feature-settings: "liga" off;
        width: 100%;
      }

      & p {
        margin-top: 5px;
        @include fonts(400, 14px, 25px, #18191f);
        font-feature-settings: "liga" off;
        width: 100%;
      }
    }

    & .content {
      margin: 8px 0 0 0;
      width: 100%;
      overflow: hidden;
      @include display-flex(flex-start, stretch);
      flex-direction: column;
      gap: 0;
    }
  }
}
