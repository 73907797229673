@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  padding-top: 10px;
  width: 25%;
  cursor: pointer;

  margin-right: 24px;
  margin-top: 24px;

  h5 {
    @include fonts(600, 16px, 123%, $accent);
    display: block;
    padding: 10px 8px 9px;
    margin-bottom: 0;

    border-bottom: 1px solid #f5f5f5;
  }

  figure {
    width: 100%;
    padding: 12px 8px;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 231px;
      object-fit: cover;
      border-radius: 8px;
    }

    figcaption {
      padding-top: 7px;
      @include fonts(400, 14px, 122%, #999999);
    }
  }

  hr {
    color: #999999;
    outline: 3px dashed white;
    outline-offset: -3px;
    overflow: hidden;
  }

  .footer {
    @include display-flex(space-between);
    padding: 9px 8px 17px;

    div,
    div > span {
      @include fonts(400, 14px, 122%, #595959);

      span:last-child {
        @include fonts(500, 15px, 97%, #595959);
        letter-spacing: 0.2px;
      }
    }
  }
}

.card:last-child {
  margin-right: 0;
}
