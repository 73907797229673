@import "src/styles/colors";
@import "src/styles/mixins";

.block {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 100%;
  @include display-flex(flex-start);

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  & .first {
    width: 619px;
    height: 100%;
    position: relative;
    z-index: 1;
    margin-right: 13px;

    & .overlaid {
      position: absolute;
      width: 240px;
      height: 374px;
      top: 23px;
      left: 23px;
      z-index: 2;
      @include display-flex(space-between, flex-start);
      flex-direction: column;

      & .play {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.75);
        @include display-flex;
        cursor: pointer;

        & .triangle {
          width: 0;
          height: 0;
          border-top: 9px solid transparent;
          border-left: 14px solid $jazzy_jade;
          border-bottom: 9px solid transparent;
          margin-left: 6px;
        }
      }

      & .title {
        @include display-flex(center, flex-start);
        flex-direction: column;

        & h3 {
          @include fonts(700, 24px, 28px, #ffffff);
          letter-spacing: -0.01em;
        }

        & .country {
          & svg {
            width: 19px;
            margin-right: 12px;
          }

          & span {
            @include fonts(500, 18px, 21px, #ffffff);
            letter-spacing: -0.04em;
          }
        }

        & .footer {
          @include fonts(500, 18px, 21px, #ffffff);
          letter-spacing: -0.04em;
        }
      }
    }
  }

  & .second {
    width: 303px;
    height: 100%;
    margin-right: 13px;
  }

  & .third {
    width: 147px;
    height: 100%;
    margin-right: 13px;

    & div {
      height: 201px;
    }

    & div:first-child {
      margin-bottom: 13px;
    }
  }

  & .fourth {
    width: 303px;
    height: 100%;
    position: relative;

    & .next {
      position: absolute;
      width: 146px;
      height: 39px;
      left: 102px;
      bottom: 18px;
      background: $accent;
      border: 1px solid $accent;
      border-radius: 8px;
      cursor: pointer;

      @include display-flex;

      & .point {
        @include display-flex;
        flex-wrap: wrap;
        width: 20px;
        margin-right: 8px;

        & div {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          margin: 3.5px;
          background: white;
        }
      }

      & span {
        @include fonts(500, 13px, 15px, white);
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 465px;
    height: 248px;
    @include display-flex(flex-start);

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }

    & .first {
      flex-shrink: 0;
      width: 181px;
      margin-right: 7px;

      & .overlaid {
        width: 165px;
        height: 214px;
        top: 17px;
        left: 8px;

        & .play {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.75);
          @include display-flex;
          cursor: pointer;

          & .triangle {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-left: 11px solid $jazzy_jade;
            border-bottom: 7px solid transparent;
            margin-left: 4px;
          }
        }

        & .title {
          & h3 {
            @include fonts(700, 14px, 16px, #ffffff);
          }

          & .country {
            & svg {
              width: 15px;
              margin-right: 8px;
            }

            & span {
              @include fonts(500, 12px, 18px, #ffffff);
            }
          }

          & .footer {
            @include fonts(500, 14px, 16px, #ffffff);
          }
        }
      }
    }

    & .second {
      display: none;
    }

    & .third {
      width: 89px;
      height: 100%;
      margin-right: 7px;

      & div {
        height: 120px;
      }

      & div:first-child {
        margin-bottom: 7px;
      }
    }

    & .fourth {
      width: 181px;
      height: 100%;
      position: relative;

      & .next {
        display: none;
      }
    }
  }
}
