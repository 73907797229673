@import "src/styles/colors";
@import "src/styles/mixins";

.header {
  height: 1034px;
  position: relative;
  background: linear-gradient(
    180deg,
    #f8f5e6 32.69%,
    rgba(248, 245, 230, 0.17) 100%
  );

  & .wrapper {
    margin: 0 auto;
    max-width: 1440px;
    height: 100%;
  }

  & .content {
    height: 528px;
    position: relative;

    & .left_arrow {
      position: absolute;
      top: 48px;
      left: 498px;
    }

    & .vector {
      position: absolute;
      top: -70px;
      left: 310px;
    }

    & .left_side {
      position: absolute;
      width: 462px;
      height: 283px;
      top: 137px;
      left: 48px;

      & .rate {
        @include display-flex(flex-start);

        & .star {
          width: 32px;
          height: 32px;
          margin-bottom: 3px;
        }

        & span {
          @include fonts(400, 22px, 26px, $accent);
          margin: 0 5px 0 12px;
          letter-spacing: 0.2px;
        }

        & .point {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $accent;
          margin: 0 14px 0 0;
        }

        & .flag {
          width: 28px;
          height: 28px;
        }
      }

      & h1 {
        margin-top: 6px;
        width: 462px;
        @include fonts(600, 48px, 65px, $accent);
      }

      & .view {
        margin-top: 17px;
        @include display-flex(flex-start);
        gap: 29px;

        & .item {
          @include display-flex;
          gap: 12px;

          & div {
            width: 40px;
            height: 40px;
            background: rgba(51, 51, 51, 0.08);
            border-radius: 4px;
            @include display-flex;
            cursor: pointer;

            & img {
              width: 24px;
              height: 24px;
            }
          }

          & span {
            @include fonts(400, 16px, 24px, $accent);
          }
        }
      }

      & .link {
        padding-top: 37px;

        & a {
          @include fonts(400, 16px, 22px, #4d4d4d);
          text-decoration-line: underline;
        }
      }
    }

    & .right_side {
      position: absolute;
      width: 193px;
      height: 131px;
      top: 227px;
      left: 858px;
      background: $jazzy_jade;
      border-radius: 10px;
      @include display-flex();
      flex-direction: column;

      & .day {
        @include fonts(400, 60px, 60px, white);
        letter-spacing: -1.87579px;
      }

      & .month {
        @include fonts(400, 21px, 21px, rgba(255, 255, 255, 0.7));
        margin: 6px 0 12px 0;
      }

      & .label {
        position: absolute;
        width: 282px;
        //height: 38px;
        padding: 8px 5px;
        background: white;
        border-radius: 5px;
        top: 115px;
        left: 25px;
        @include display-flex();

        & span {
          @include fonts(500, 16px, 19px, $accent);
          letter-spacing: -0.500211px;
          margin-left: 10px;
        }
      }
    }
  }

  & .media {
    border-radius: 12px 0 0 12px;
    height: 415px;
    width: 100%;
    position: relative;
    margin-left: 50px;

    & .showPhoto {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    height: 800px;

    & .wrapper {
      width: 100%;
    }

    & .content {
      height: 498px;

      & .left_arrow {
        position: absolute;
        top: 65px;
        left: 185px;
        transform: rotate(90deg) scale(0.5);
      }

      & .vector {
        position: absolute;
        top: -120px;
        left: -220px;
        transform: scale(0.7) rotate(-10deg);
      }

      & .left_side {
        position: absolute;
        width: 100%;
        height: 265px;
        top: 53px;
        left: 19px;

        & .rate {
          @include display-flex(flex-start);

          & .star {
            width: 19px;
            height: 19px;
            margin-bottom: 0;
          }

          & span {
            @include fonts(400, 16px, 16px, $accent);
            margin: 0 6px;
            letter-spacing: 0.2px;
          }

          & .point {
            width: 3px;
            height: 3px;
            margin: 0 9px 0 0;
          }

          & .flag {
            width: 21px;
            height: 21px;
          }
        }

        & h1 {
          margin-top: 2px;
          width: 313px;
          @include fonts(600, 32px, 43px, $accent);
        }

        & .view {
          margin-top: 12px;
          @include display-flex(flex-start);
          gap: 30px;

          & .item {
            @include display-flex;
            gap: 5px;

            & div {
              width: 30px;
              height: 30px;
              background: rgba(51, 51, 51, 0.08);
              border-radius: 4px;
              @include display-flex;
              cursor: pointer;

              & img {
                width: 15px;
                height: 15px;
              }
            }

            & span {
              font-size: 14px;
            }
          }
        }

        & .link {
          padding-top: 40px;

          & a {
            @include fonts(400, 14px, 19px, #4d4d4d);
          }
        }
      }

      & .right_side {
        position: absolute;
        width: 144px;
        height: 103px;
        top: 309px;
        left: 49px;

        & .day {
          @include fonts(500, 30px, 35px, white);
        }

        & .month {
          @include fonts(400, 16px, 19px, rgba(255, 255, 255, 0.7));
          margin: 9px 0 0 0;
        }

        & .label {
          width: 256px;
          height: 34px;
          top: 88px;
          left: 20px;
          @include display-flex();

          & svg {
            width: 13px;
          }

          & span {
            @include fonts(500, 14px, 16px, $accent);
            margin-left: 9px;
          }
        }
      }
    }

    & .media {
      border-radius: 12px 0 0 12px;
      height: 302px;
      width: 100%;
      position: relative;
      margin-left: 20px;

      & .showPhoto {
        position: absolute;
        width: 100%;
        height: 36px;
        top: 266px;
        left: -20px;
        display: block;

        & button {
          @include display-flex();
          width: 133px;
          height: 36px;
          border: 1px solid #333333;
          border-radius: 8px;
          margin: 0 auto;
          @include fonts(500, 12px, 14px, $accent);
          background: white;
        }
      }
    }
  }
}
