@import "src/styles/colors";
@import "src/styles/mixins";

.button {
  @include fonts(400, 14px, 20px, $carbon);
  padding: 14px 22px;
  border: 1px dashed #e5e5e5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 8px;
  cursor: pointer;

  & svg {
    width: 24px;
    color: #1818196b;
  }

  @media screen and (max-width: 500px) {
    margin-left: 0;
    width: 201px;
    & svg {
      color: rgba(24, 24, 25, 0.42);
    }
  }
}
