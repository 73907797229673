@import "src/styles/colors";
@import "src/styles/mixins";

.proceed {
  position: fixed;
  top: 657px;
  left: 0;
  width: 100%;
  padding: 0 19px 10px 19px;

  & .bar {
    @include display-flex(space-between);
    width: 100%;
    height: 40px;
    padding: 0 9px 0 14px;

    & .items {
      & span:first-child {
        @include fonts(500, 15px, 24px, #676b79);
      }
      & span:last-child {
        @include fonts(500, 15px, 24px, $accent);
      }
    }
  }

  & .button {
    width: 100%;

    & button {
      width: 100%;
      height: 44px;
      margin: 0 auto;
      background: #eee9cf;
      border-radius: 8px;
      @include fonts(500, 16px, 20px, $accent);
      @include display-flex;
      border: none;
    }
  }
}
