@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  width: 410px;
  padding: 24px 0 9px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  @include desktop {
    width: 784px;

  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;
    margin-bottom: 25px;

    & input,
    select,
    textarea {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;

  @media screen and (max-width: 1050px) {
    margin: 12px 0 16px;
    font-size: 14px;
  }
}


.group {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & .description{

    & textarea{
      height: 54px;
    }

  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1050px) {
    display: block;

    & div {
      margin-right: 0;
    }
  }
}
