@import "src/styles/colors";
@import "src/styles/mixins";

.organizer {
  margin: 41px auto 0 auto;
  width: 100%;
  padding: 0 48px;
  max-width: 1440px;
  height: 666px;

  & .wrapper {
    max-width: 1344px;
    padding-right: 80px;
    margin: 0 auto;
    @include display-flex(space-between, flex-start);
  }

  & .left {
    height: 630px;
    @include display-flex(flex-start, flex-start);
    flex-direction: column;

    & h4 {
      margin-top: 20px;
      @include fonts(500, 20px, 23px, #4d4d4d);
    }

    & .workcation {
      margin-top: 30px;
      @include display-flex(flex-start);

      & .ellipse {
        width: 112px;
        height: 112px;
        border-radius: 50%;
        border: 1px solid #eaecee;
        @include display-flex;
        margin-right: 27px;
      }
    }

    & p {
      margin-top: 15px;
      width: 496px;
      @include fonts(500, 20px, 28px, $accent);
    }

    & a {
      margin-top: 12px;
      cursor: pointer;
      @include display-flex(flex-start);

      & span {
        @include fonts(500, 16px, 24px, $jazzy_jade);
        margin-right: 8px;
      }
    }

    & .arrow {
      width: 20px;
      transform: rotate(180deg);
      color: $jazzy_jade;
    }

    & .social {
      margin-top: 23px;
      @include display-flex(flex-start);

      & svg {
        margin-right: 20px;
        cursor: pointer;
      }

      & svg:last-child {
        margin-right: 0;
      }

      & .twitter {
        width: 20px;
        color: #4d4d4d;
      }
    }
  }

  & .right {
    @include display-flex(flex-start, flex-start);
    flex-direction: column;

    & h2 {
      @include fonts(600, 34px, 40px, $accent);
    }

    & .text {
      margin-top: 24px;

      & .truncate_text {
        width: 560px;
        @include fonts(400, 18px, 28px, #6b7280);
        text-align: justify;
      }
    }

    & .statistics {
      //margin-top: 30px;
      //@include display-flex;
      //flex-wrap: wrap;
      //width: 560px;
      //row-gap: 32px;
      //column-gap: 16px;
      display: none;

      & div {
        width: 264px;
        height: 116px;
        border-top: 2px solid #f3f4f6;
        @include display-flex(center, flex-start);
        flex-direction: column;

        & .name {
          @include fonts(500, 16px, 24px, #4d4d4d);
        }

        & .value {
          @include fonts(600, 30px, 36px, $accent);
          letter-spacing: -0.025em;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin: 39px 0 0;
    padding: 0 16px;
    height: auto;

    & .wrapper {
      width: 100%;
      padding: 0;
      margin: 0;
      display: block;
    }

    & .left {
      height: auto;
      display: block;

      & h4 {
        margin: 0;
        @include fonts(500, 16px, 19px, #4d4d4d);
      }

      & .workcation {
        margin-top: 17px;
        @include display-flex(flex-start);

        & .ellipse {
          width: 30px;
          height: 30px;
          border-radius: 0;
          border: none;
          @include display-flex;
          margin-right: -10px;

          & svg {
            height: 20px;
          }
        }

        & .svg_label {
          height: 13px;
          margin-top: 6px;
        }
      }

      & p {
        margin-top: 18px;
        width: 100%;
        @include fonts(500, 14px, 28px, $accent);
      }

      & a {
        margin-top: 16px;
        cursor: pointer;
        @include display-flex(flex-start);

        & span {
          @include fonts(500, 12px, 24px, $jazzy_jade);
          margin-right: 8px;
        }
      }

      & .arrow {
        width: 15px;
        transform: rotate(180deg);
        color: $jazzy_jade;
      }

      & .social {
        margin-top: 23px;
        @include display-flex(flex-start);

        & svg {
          margin-right: 18px;
          cursor: pointer;
        }

        & svg:last-child {
          margin-right: 0;
        }

        & .twitter {
          width: 18px;
          color: #4d4d4d;
        }
      }
    }

    & .right {
      margin-top: 66px;

      & h2 {
        @include fonts(600, 16px, 40px, $accent);
        letter-spacing: -0.025em;
      }

      & .text {
        position: relative;

        &::after {
          content: "  read more";
          //position: absolute;
          //bottom: -3px;
          //right: 0;
          @include fonts(400, 14px, 28px, $jazzy_jade);
        }

        & .truncate_text {
          width: 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          @include fonts(400, 14px, 28px, #6b7280);
          height: 140px;
        }
      }

      & .statistics {
        margin-top: 39px;
        width: 100%;
        row-gap: 32px;
        column-gap: 12%;
        @include display-flex;
        flex-wrap: wrap;

        & div {
          //width: 44%;
          width: 100%;
          height: 86px;
          margin-right: 0;
          border-top: 2px solid #f3f4f6;
          @include display-flex(center, flex-start);
          flex-direction: column;

          & .name {
            @include fonts(500, 14px, 24px, #4d4d4d);
          }

          & .value {
            @include fonts(600, 20px, 36px, $accent);
          }
        }
      }
    }
  }
}
