@import "src/styles/colors";
@import "src/styles/mixins";

.left_header {
  height: 67px;
  border-bottom: 1px solid $background-color;
  padding: 15px 16px 12px 23px;
  @include display-flex(space-between);
  position: fixed;
  width: 334px;
  background: rgba(248, 248, 250, 0.8);
  top: 0;
  left: 0;
  z-index: 2;

  & .back {
    position: absolute;
    background: #f8f8fa;
    //opacity: .3;
    width: 100%;
    height: 100%;
    z-index: 1;
    //filter: blur(10px);
  }

  & .burger {
    @include display-flex;

    & span {
      margin-left: 12px;
      @include fonts(600, 16px, 24px, $accent);
    }
  }

  & .notification {
    all: unset;
    cursor: pointer;
    @include display-flex;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 8px;

    & svg {
      width: 24px;
    }
  }
}
