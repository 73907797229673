@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  position: absolute;
  top: 56px;
  right: 210px;

  width: 370px;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

  z-index: 1000;

  .relative {
    padding: 24px 20px 24px 17px;
    position: relative;
    width: 100%;
    height: 100%;

    .influx {
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      top: 0;
      right: 54px;
      border-radius: 2px;
      transform: rotate(45deg);
      margin-top: -9px;
    }

    .block {
      @include display-flex(center, flex-start);
      flex-direction: column;

      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 16px;
      padding-bottom: 10px;

      .description {
        @include display-flex();
        margin-bottom: 21px;

        span {
          @include fonts(700, 14px, 16px, $carbon);
        }

        .marker {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #51c9c2;
          margin-right: 10px;
        }
      }

      .time {
        @include fonts(700, 12px, 14px, #787e91);
        width: 100%;
        text-align: right;
      }
    }

    .block:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 500px) {
    top: 56px;
    right: 0;
    padding: 0 19px;
    width: 100%;

    border: none;

    .relative {
      background: $ambrosia_cake;
      border-radius: 10px;

      .influx {
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: $ambrosia_cake;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        top: 0;
        left: calc(100% - 22px);
        border-radius: 2px;
        transform: rotate(45deg);
        margin-top: -6px;
      }

      .block {
        @include display-flex(center, flex-start);
        flex-direction: column;

        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 16px;
        padding-bottom: 10px;

        .description {
          @include display-flex();
          margin-bottom: 21px;

          span {
            @include fonts(700, 14px, 16px, $carbon);
          }

          .marker {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #51c9c2;
            margin-right: 10px;
          }
        }

        .time {
          @include fonts(700, 12px, 14px, #787e91);
          width: 100%;
          text-align: right;
        }
      }

      .block:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}
