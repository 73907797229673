@import "src/styles/colors";
@import "src/styles/mixins";

.dynamic {
  display: block;
  margin: 0 0 22px;
  text-transform: capitalize;

  & label {
    @include fonts(400, 14px, 20px, $accent);
  }

  & select, & input {
    width: 100%;

    & option {
      text-transform: capitalize;
    }

  }

  & input[type=number] {
    appearance: textfield;
  }
}

.image_input {
  display: none;
}

.image_button {
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 40px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  @include display-flex(center);
  @include fonts(400, 14px, 20px, $accent);

  transition: box-shadow 0.3s linear;
  &:hover {
    box-shadow: 0 5px 17px 2px $background-color;
  }
}
