@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/fonts";

.container {
  position: relative;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(248, 245, 230, 0.85);

  .background {
    position: absolute;
    height: 100%;
    width: 100%;

    background: linear-gradient(
        180deg,
        #fbfbfb 0.89%,
        rgba(234, 234, 234, 0.0001) 100%
    ),
    url("../../../assets/images/loginBackgr.png");
    background-size: cover;
    opacity: 0.2;
    mix-blend-mode: luminosity;

    display: flex;
  }
}

.form {
  z-index: 10;
  width: 446px;
  background-color: white;
  border-radius: 16px;
  padding: 38px 23px 30px;
  position: relative;

  & .return {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 22px;
    right: 22px;
    @include display-flex;

    & svg {
      width: 18px;
      height: 18px;
      color: $accent;
    }
  }

  .title {
    @include fonts(700, 24px, 20px, $accent);
    width: 123px;
    margin: 0 auto 25px;
  }

  @media screen and (max-width: 500px) {
    width: 337px;
    padding: 29px 18px 23px;

    & .return {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 18px;
      right: 18px;


      & svg {
        width: 16px;
        height: 16px;
        color: $accent;
      }
    }

    .title {
      @include fonts(700, 24px, 20px, $accent);
      width: 130px;
      margin: 0 auto;
    }
  }
}

