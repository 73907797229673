@import "src/styles/colors";
@import "src/styles/mixins";

.header {
  position: relative;
  background-color: $ambrosia_cake;
  height: calc(100vh - 92px);

  h1 {
    max-width: 570px;
    @include fonts(700, 72px, 84.38px, #2e2e30);
    margin-bottom: 33px;
  }

  p {
    @include fonts(400, 18px, 25px, #747474);
    max-width: 506px;
    margin-bottom: 57px;
  }

  .spiral {
    width: 30%;
    position: absolute;
    //transform: matrix(0.8, 0.6, 0.6, -0.8, 0, 0);
    bottom: 3%;
    right: 3%;
  }
}

.wrapper {
  height: calc(100vh - 92px);
  display: flex;
  justify-content: center;
  align-items: center;
  //padding-top: 142px;
  position: relative;
  z-index: 3;

  .point {
    margin-bottom: 73px;
  }

  .events {
    list-style-type: none;
    @include fonts(700, 18px, 25px, #888888);
    margin-top: 33px;
    position: relative;
    margin-left: 30px;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "";
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: $accent;
      opacity: 0.32;
      left: -30px;
      top: 0;
    }

    &:after {
      content: "";
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: $accent;
      left: -24px;
      top: 5.5px;
    }
  }
}

.button {
  @include fonts(500, 20px, 10.5px, transparent);
  padding: 22px 40px;
  border-radius: 15px;
}

.bordered {
  border: 1px solid $carbon;
  color: $carbon;

  &:hover {
    color: $carbon;
  }
}

.solid {
  background-color: $carbon;
  box-shadow: 2px 2px 5px 0px #00000030;
  box-shadow: -2px -2px 5px 0px #ffffff1a;
  color: white;
  margin-right: 24px;

  &:hover {
    color: white;
  }
}

.block {
  box-shadow: 0px 4px 14px 0px #0000001a;
  border-radius: 14px;
  max-width: 466px;
  margin: 7px 0;

  & img {
    object-fit: contain;
    border-radius: 14px;
    width: 466px;
  }
}

.arrow {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  & svg {
    width: 400px;
  }
}
