@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  width: 498px;
  height: 267px;
  background: $accent;
  border-radius: 15px;
  padding: 14px;
  cursor: pointer;
  margin-right: 27px;

  @include display-flex;

  &:last-child {
    margin-right: 0;
  }

  & img {
    width: 184px;
    height: 239px;
    border-radius: 15px;
    object-fit: cover;
  }

  & .text {
    width: 286px;
    height: 100%;
    padding: 14px 25px 22px 18px;

    & .country {
      @include display-flex(flex-start);

      & svg {
        height: 15px;
        margin-right: 7px;
      }

      & span {
        @include fonts(400, 16px, 19px, #c4c4c4);
        margin-top: 1px;
      }
    }

    & .from {
      margin-top: 35px;
      @include fonts(500, 14px, 27px, $jazzy_jade);
      letter-spacing: 0.02em;
    }

    & .title {
      margin-top: 5px;
      width: 271px;
      height: 54px;
      @include fonts(500, 18px, 27px, white);
      letter-spacing: 0.02em;
    }

    & .footer {
      margin-top: 49px;
      @include display-flex(space-between);

      & .date {
        @include fonts(400, 16px, 24px, #c4c4c4);
        letter-spacing: -0.011em;
      }

      & .price {
        @include display-flex;
        & span:first-child {
          @include fonts(500, 20px, 26px, #ffffff);
        }

        & span:last-child {
          @include fonts(400, 14px, 26px, #656668);
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: calc(100vw - 32px);
    height: 215px;
    border-radius: 12px;
    padding: 11px;
    margin-right: 16px;

    & img {
      width: 135px;
      height: 193px;
    }

    & .text {
      width: calc(100% - 135px);
      height: 100%;
      padding: 13px 4px 0 16px;

      & .country {
        & svg {
          height: 13px;
          margin-right: 6px;
        }

        & span {
          @include fonts(400, 12px, 14px, #c4c4c4);
          margin-top: 0;
          margin-bottom: 1px;
        }
      }

      & .from {
        margin-top: 16px;
        @include fonts(500, 13px, 27px, $jazzy_jade);
      }

      & .title {
        margin-top: 1px;
        width: 100%;
        height: 54px;
        @include fonts(500, 16px, 27px, white);
      }

      & .footer {
        margin-top: 11px;
        @include display-flex(flex-start, flex-start);
        flex-direction: column;

        & .date {
          @include fonts(400, 12px, 18px, #c4c4c4);
        }

        & .price {
          @include display-flex;
          & span:first-child {
            @include fonts(500, 16px, 26px, #ffffff);
          }

          & span:last-child {
            @include fonts(400, 12px, 26px, #656668);
            margin-top: 1px;
          }
        }
      }
    }
  }
}
