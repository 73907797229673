@import "src/styles/colors";
@import "src/styles/mixins";

.gallery {
  max-width: 1114px;
  margin: 24px auto 5px;

  & .title {
    @include fonts(700, 16px, 24px, $accent);
    margin-left: 24px;
  }

  .gallery_block {
    width: 100%;
    margin-top: 24px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    padding: 24px;
    @include display-flex(flex-start);
    flex-wrap: wrap;
    gap: 24px;

    & input {
      display: none;
    }

    .img_wraper {
      width: 248px;
      border: 1px solid #f5f5f5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px;
      position: relative;
      //margin-right: 21px;

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: 228px;
        object-fit: cover;
      }

      .hovered {
        position: absolute;
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
        background: rgba(255, 255, 255, 0.5);
        @include display-flex();

        & div svg {
          display: none;
          height: 20px;
        }

        & div svg:first-child {
          margin-right: 9px;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.27);

          & svg {
            display: inline;
            cursor: pointer;
            color: white;
          }
        }
      }
    }

    .gallery_upload {
      width: 248px;
      height: 248px;
      background: #fafafa;
      border: 1px dashed #d9d9d9;
      box-sizing: border-box;
      border-radius: 2px;
      cursor: pointer;

      @include display-flex();
      flex-direction: column;

      & svg {
        height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }

      & span {
        margin-top: 10px;
        @include fonts(400, 16px, 22px, rgba(0, 0, 0, 0.45));
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin: 20px auto 0;

    & .title {
      font-size: 14px;
      margin-left: 0;
    }

    .gallery_block {
      margin-top: 18px;
      border-radius: 8px;
      border: none;
      padding: 0;
      @include display-flex(flex-start);
      flex-wrap: wrap;
      gap: 14px;

      & input {
        display: none;
      }

      .img_wraper {
        width: calc(50% - 7px);
        border: 1px solid #f5f5f5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 6px;

        img {
          width: 100%;
          height: 149px;
          object-fit: cover;
        }

        .hovered {
          position: absolute;
          top: 6px;
          left: 6px;
          bottom: 6px;
          right: 6px;

          & div svg:first-child {
            margin-right: 8px;
          }

          &:hover {
            background: rgba(0, 0, 0, 0.27);

            & svg {
              display: inline;
              cursor: pointer;
              color: white;
            }
          }
        }
      }

      .gallery_upload {
        width: calc(50% - 7px);
        height: 161px;

        @include display-flex();
        flex-direction: column;

        & svg {
          height: 20px;
        }

        & span {
          margin-top: 8px;
          font-size: 14px;
        }
      }
    }
  }
}
