@import "src/styles/colors";
@import "src/styles/mixins";


.section {
  background-color: #FAFAFA;
  padding: 85px 0;


  @include tablet {
    background-color: #fff;
    padding: 30px 0;
  }

  .container {
    padding: 0 20px;
    width: 100%;

    @include tablet {
      padding: 0 30px;
    }

    .title {
        position: absolute;
        white-space: nowrap;
        width: 1px;
        height: 1px;
        overflow: hidden;
        border: 0;
        padding: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        margin: -1px;
      @include tablet {
        position: static;
        white-space: normal;
        width: auto;
        height: auto;
        overflow: unset;
        border: 0;
        padding: 0;
        clip: auto;
        clip-path: none;
        margin: 0 0 25px;
      }
    }
  }
}
