@import "src/styles/colors";
@import "src/styles/mixins";

.modal {
  width: calc(100vw - 40px);
  height: 324px;
  padding: 30px 20px 26px;

  //width: 564px;
  //padding: 52px 82px 63px;

  background: #FFFFFF;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 16px;
  text-align: center;
  @include display-flex(space-between);
  flex-direction: column;
  @include center_absolute;

  @media screen and (min-width: 480px) {
    width: 564px;
    padding: 52px 82px 63px;
  }

  .text{
    @include fonts(700, 18px, 20px, $accent);
    @media screen and (min-width: 480px) {
      @include fonts(700, 20px, 22px, $accent);
    }
  }
  .btn{
    width: 100%;
    //width: 400px;
    height: 48px;
    background: $jazzy_jade;
    border: 1px solid $jazzy_jade;
    cursor: pointer;
    border-radius: 5px;
    @include fonts(500, 16px, 22px, #ffffff)

  }
}



