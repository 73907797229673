@import "src/styles/colors";
@import "src/styles/mixins";



*,
*::before,
*::after {
  box-sizing: border-box;
}

//input {
//  all: unset;
//}

.app__flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.input_wraper {
  margin-top: 16px;

  label {
    @include fonts(400, 14px, 24px, $black_coral);

    input {
      margin-right: 11px;
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 11px;

    label {
      @include fonts(400, 14px, 24px, $black_coral);

      input {
        margin-right: 0;
      }
    }
  }
}

.input_title {
  display: flex;
  align-items: center;

  span {
    @include fonts(500, 14px, 22px, $accent);

    @media screen and (max-width: 500px) {
      @include fonts(500, 12px, 22px, $accent);
    }
  }

  span:last-child {
    color: $form_red;
  }
}

.input_form {
  height: 48px;
  width: 400px;

  background: white;
  border: 1px solid $pale_white;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 16px;
  letter-spacing: -0.25px;
  @include fonts(400, 14px, 24px, #a1a1a9);

  &:focus {
    border: 1px solid $pale_white;
    outline: none;
  }

  @media screen and (max-width: 500px) {
    height: 36px;
    width: 301px;
    margin: 0 auto;

    padding: 7px 12px;
    @include fonts(400, 12px, 24px, #a1a1a9);
  }
}

.title_wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;


  & > span {
    @include fonts(500, 12px, 22px, #0070f4);
    cursor: pointer;

    @media screen and (max-width: 500px) {
      @include fonts(500, 11px, 22px, #0070f4);
    }
  }
}

.input_block {
  position: relative;

  & .password_control {
    position: absolute;
    top: 17px;
    right: 28px;
    display: inline-block;
    width: 6px;
    height: 13px;
    background: url("../../assets/images/key.png") 0 0 no-repeat;
  }

  & .password_variant {
    position: absolute;
    top: 22px;
    right: 17px;
    width: 8px;
    height: 4px;
    //transform: rotate(90deg);
    background: url("../../assets/images/arrow.png") 0 0 no-repeat;
  }

  @media screen and (max-width: 500px) {
    & .password_control {
      top: 12px;
      right: 23px;
      display: inline-block;
      width: 6px;
      height: 13px;
      background: url("../../assets/images/key.png") 0 0 no-repeat;
    }

    & .password_variant {
      position: absolute;
      top: 17px;
      right: 12px;
      width: 8px;
      height: 4px;
      //transform: rotate(90deg);
      background: url("../../assets/images/arrow.png") 0 0 no-repeat;
    }
  }
}

.block_checkboxes {
  margin-top: 27px;
  align-items: center;

  & .checkbox {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: white;
    border: 1px solid $pale_white;
    margin-right: 11px;
    cursor: pointer;
  }

  & .title {
    @include fonts(400, 14px, 24px, $black_coral);
    letter-spacing: -0.25px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 0;

    & .checkbox {
      width: 12px;
      height: 12px;
      margin-right: 7px;
      margin-top: 2px;
      margin-bottom: 1.5px;
      cursor: pointer;

      & span {
        left: 1px !important;
        top: -17px !important;

        & svg {
          width: 8px;
        }
      }
    }
    & .title {
      @include fonts(400, 12px, 24px, $black_coral);
    }
  }
}

.title_wraper_check {
  @media screen and (max-width: 500px) {
    margin-top: 11px;
  }
}

.error {
  margin-top: 12px;
  @include fonts(500, 14px, 18px, $form_red);

  @media screen and (max-width: 500px) {
    @include fonts(500, 12px, 18px, $form_red);
  }
}

.button__sign_in,
.button__sign_google {
  width: 400px;
  height: 48px;
  border-radius: 5px;
  letter-spacing: -0.02em;

  @media screen and (max-width: 500px) {
    width: 301px;
    height: 36px;
  }
}

.button__sign_in {
  background-color: $jazzy_jade;
  border: 1px solid $jazzy_jade;
  @include fonts(500, 16px, 140%, white);
  cursor: pointer;
  margin-top: 28px;

  @media screen and (max-width: 500px) {
    margin-top: 11px;
    @include fonts(500, 14px, 20px, white);
  }
}

.button__sign_google {
  background: white;
  border: 1px solid #f1f1f2;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  span {
    margin-left: 8px;
    @include fonts(500, 14px, 16px, $accent);
  }

  @media screen and (max-width: 500px) {
    @include display-flex;

    span {
      margin-left: 6px;
      @include fonts(500, 12px, 14px, $accent);
    }

    & img {
      width: 15px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 37px;

  span {
    @include fonts(400, 14px, 24px, #666666);
    letter-spacing: -0.25px;
  }

  & .link {
    margin-left: 0.5rem;
    cursor: pointer;
    @include fonts(400, 14px, 24px, $jazzy_jade);
    letter-spacing: -0.25px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 21px;

    span {
      font-size: 12px;
    }

    & .link {
      font-size: 12px;
    }
  }
}

:global {
  .ant-divider-inner-text {
    @include fonts(400, 13px, 15px, #8f919c);
    letter-spacing: -0.02em;
  }

  .ant-divider-with-text {
    margin: 18.5px 0 !important;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .ant-modal {
    width: 480px !important;
    top: 25vh !important;

    @media screen and (max-width: 500px) {
      width: 339px !important;
      top: 20vh !important;
    }
  }

  .ant-modal-content {
    border-radius: 16px !important;
  }

  .ant-modal-close {
    top: 13px !important;
    right: 24px !important;

    @media screen and (max-width: 500px) {
      top: 4px !important;
      right: 4px !important;
    }
  }

  .ant-modal-close-x {
    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .ant-modal-body {
    padding: 55px 35px !important;

    @media screen and (max-width: 500px) {
      padding: 24px 20px !important;
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.spinner {
  @include display-flex;
  flex-direction: column;

  & h5 {
    margin-top: 11px;
    width: 100%;
    text-align: center;
    @include fonts(400, 13px, 15px, $jazzy_jade);
  }

  & svg {
    width: 100px;
  }
}

.content {

  & p {
    @include fonts(400, 14px, 20px, #5E5F71);
  }

  & .input_wraper {
    margin-top: 11px;

    label {
      @include fonts(400, 14px, 24px, $black_coral);

      input {
        margin-right: 11px;
      }
    }


    & .input_title {
      display: flex;
      align-items: center;

      span {
        @include fonts(500, 14px, 22px, $accent);

        @media screen and (max-width: 500px) {
          @include fonts(500, 12px, 22px, $accent);
        }
      }

      span:last-child {
        color: $form_red;
      }
    }

    & .input_form {
      margin-top: 11px;
      height: 48px;
      width: 400px;

      background: white;
      border: 1px solid $pale_white;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 12px 16px;
      letter-spacing: -0.25px;
      @include fonts(400, 14px, 24px, #a1a1a9);

      &:focus {
        border: 1px solid $pale_white;
        outline: none;
      }

      @media screen and (max-width: 500px) {
        height: 36px;
        width: 301px;
        margin: 0 auto;

        padding: 7px 12px;
        @include fonts(400, 12px, 24px, #a1a1a9);
      }
    }
  }

  & .button__sign_in {
    width: 400px;
    height: 48px;
    border-radius: 5px;
    letter-spacing: -0.02em;
    background-color: $jazzy_jade;
    border: 1px solid $jazzy_jade;
    @include fonts(500, 16px, 140%, white);
    cursor: pointer;
    margin-top: 28px;

    @media screen and (max-width: 500px) {
      width: 301px;
      height: 36px;
      margin-top: 11px;
      @include fonts(500, 14px, 20px, white);
    }
  }

  & .back{
    margin-top: 27px;
    background: inherit;
    border: none;
    cursor: pointer;

    & span{
      @include fonts(400, 14px, 24px, $accent);
      letter-spacing: -0.25px;
    }

    & span:first-child{
      margin-right: 15px;
      font-weight: 700;
      font-size: 16px;
    }

  }

  & .error {
    margin-top: 12px;
    @include fonts(500, 14px, 18px, $form_red);

    @media screen and (max-width: 500px) {
      @include fonts(500, 12px, 18px, $form_red);
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 11px;

    label {
      @include fonts(500, 16px, 22px, $black_coral);

      input {
        margin-right: 0;
      }
    }

  }
}
