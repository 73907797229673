@import "src/styles/colors";
@import "src/styles/mixins";

.bar {
  display: flex;
  border-radius: 4px;

  & span {
    display: inline-block;

    &:nth-child(2n + 1) {
      width: 12.5px;
      height: 8px;
      background-color: transparent;
    }

    &:nth-child(2n) {
      width: 1px;
      height: 8px;
      background-color: white;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
