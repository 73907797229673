@import "src/styles/colors";
@import "src/styles/mixins";

.account {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 18px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.icon {
  @include circle(32px, 0, none);
  margin-right: 8px;
  object-fit: cover;
}

.info {
  @include fonts(400, 14px, 16.41px, #2d3748);
  //margin-bottom: 7px;
}

.statistics {
  display: flex;
  align-items: center;
}

.progress {
  margin-left: 9px;
  @include fonts(500, 12px, 14.22px, $carbon);
}
