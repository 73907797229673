@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  max-width: 1444px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.col {
  @include display-flex;
}
