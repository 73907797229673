@import "src/styles/colors";
@import "src/styles/mixins";

.main {
  margin-top: 34px;

  & .title {
    padding: 0 20px;

    & h2 {
      @include fonts(500, 16px, 24px, $accent);
    }

    & h3 {
      margin-top: 6px;
      @include fonts(300, 14px, 16px, #787e91);
    }
  }

  & .img_field {
    @include display-flex(space-between);
    margin: 20px 25px 0 14px;
    padding: 13px 5px 13px 21px;
    border-top: 1px solid #ebeaea;
    width: calc(100% - 39px);

    & .img_block {
      width: 194px;
      height: 100%;
      @include display-flex;

      & img {
        height: 178px;
        width: 100%;
        object-fit: contain;
      }
    }

    & .all_img {
      width: 81px;
      height: 309px;
      //overflow-y: scroll;
      @include display-flex();
      flex-direction: column;
      row-gap: 7px;

      & .active {
        border: 1px solid $jazzy_jade;
      }

      & button {
        width: 81px;
        height: 72px;
        border: 1px solid #f5f5f5;
        //filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
        border-radius: 8px;
        @include display-flex;
        background: white;

        & img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
