@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  padding: 50px 0;
  background: #070233;

  @include desktop {
    padding: 189px 0;

  }

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;

    @include mobile {
      width: 500px;
    }
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1200px;
      padding: 0 52px;
    }

    .data {

      @include desktop {
        @include display-flex(space-between, flext-start);
      }

      .sponsor_block {
        padding-top: 66px;
        .title {
          @include fonts(700, 26px, 33px, #fdfdfd);
          letter-spacing: -0.022em;
          margin-bottom: 22px;
          @include tablet {
            @include fonts(700, 62px, 78px, #fdfdfd);
          }
        }
        .info {
          @include fonts(400, 18px, 32px, rgba(253, 253, 253, 0.8));
          margin-bottom: 37px;

          @include tablet {
            @include fonts(400, 24px, 36px, rgba(253, 253, 253, 0.8));
          }
          @include desktop {
            width: 480px;

          }
        }

        .btn {
          border: none;
          height: 52px;
          width: 133px;
          background-color: #2196F3;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          cursor: pointer;
          transition: border .25s cubic-bezier(0.4, 0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0, 0.2, 1);
          @include fonts(500, 16px, 30px, #fff);
          @include display-flex();

          &:focus, &:hover {
            border: 1px solid #1598FF;
            background-color: transparent;
          }

        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: rgba(240, 240, 240, 0.2);
        margin-top: 66px;

        @include desktop {
          width: 1px;
          height: 370px;
          margin: 0 20px;
        }
      }
    }
  }
}
