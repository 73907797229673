@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;

  @include display-flex;
}

.content {
  height: 581px;
  width: 800px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0.5);
  mix-blend-mode: normal;
}

.search {
  perspective: 1px;
  position: absolute;
  z-index: 1003;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.map {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1002;
}

.autocomplete {
  cursor: pointer;
  background-color: #fff;
  margin-left: 28px;
  width: 392px;
  height: 46px;
  border-radius: 8px 8px 0 0;
  padding: 10px 20px 10px 64px;
  @include fonts(400, 14px, 18.2px, $accent);
  display: flex;
  align-items: center;
  position: relative;

  &:before{
    content: "";
    position: absolute;
    width: 1px;
    height: 26px;
    background: #DDDDDD;
    top: 10px;
    left: 50px;
  }

  & svg {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 15px;
    left: 15px;
  }

  &__input {
    border: unset;

    &:focus {
      outline: unset;
    }
  }
}

.variances {
  margin-left: 28px;
  width: 392px;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 0 0 8px 8px;

  &__none {
    display: none;
  }

  &__loading {
    @include fonts(400, 12px, 16.2px, $accent);
  }

  &__item {
    cursor: pointer;
    transition: all 0.3s ease-in;
    background-color: #fff;
    padding-left: 65px;
    position: relative;

    & img{
      position: absolute;
      width: 12px;
      top: calc(50% - 8.5px);
      left: 14px;
    }

    & span {
      transition: all 0.3s ease-in;
      padding: 8px 10px 8px 0;
      @include fonts(400, 12px, 16.2px, $accent);
      border-top: 1px solid #FAFAFA;
      display: block;
    }

    &:hover span {
      color: $jazzy_jade;
    }

    &.active span {
      color: $jazzy_jade;
    }
  }
}

.navbar {
  width: 800px;
  height: 50px;
  background-color: #fff;
  padding: 0 24px;
  margin-bottom: 38px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__close {
    cursor: pointer;
    display: flex;
    width: 24px;
    height: 24px;
  }

  & button {
    all: unset;
    @include display-flex;
    cursor: pointer;
    width: 85px;
    height: 29px;
    background: #51C9C2;
    border: 1px solid #51C9C2;
    border-radius: 4px;
    @include fonts(500, 14px, 20px, white);
  }
}

@media screen and (max-width: 500px){

  .content {
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    position: relative;
    overflow: hidden;
  }

  .autocomplete {
    margin-left: 6px;
    width: 364px;
    padding: 10px 23px 10px 64px;
    @include fonts(400, 15px, 18px, $accent);
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0px -1px 0px rgba(0, 0, 0, 0.02);

    &:before{
      content: "";
      position: absolute;
      width: 1px;
      height: 26px;
      background: #DDDDDD;
      top: 10px;
      left: 50px;
    }

    & svg {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 15px;
      left: 15px;
    }

    &__input {
      border: unset;

      &:focus {
        outline: unset;
      }
    }
  }

  .variances {
    margin: 0 0 0 6px;
    width: 364px;
    background-color: #fff;
    padding:0;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0px -1px 0px rgba(0, 0, 0, 0.02);

    &__none {
      display: none;
    }

    &__loading {
      @include fonts(400, 12px, 16.2px, #92969B);
    }

    &__item {
      cursor: pointer;
      transition: all 0.3s ease-in;
      background-color: #fff;
      padding-left: 65px;
      position: relative;
      border-radius: 0 0 8px 8px;

      & img{
        position: absolute;
        width: 12px;
        top: calc(50% - 8.5px);
        left: 14px;
      }

      & span {
        transition: all 0.3s ease-in;
        padding: 8px 10px 8px 0;
        @include fonts(400, 12px, 16.2px, #92969B);
        border-top: 1px solid #FAFAFA;
        display: block;
      }

      &:hover span {
        color: $jazzy_jade;
      }

      &.active span {
        color: $jazzy_jade;
      }
    }
  }

  .navbar {
    width: 100%;
    margin-bottom: 23px;
  }
}
