@import "src/styles/colors";
@import "src/styles/mixins";

.profile_team_info {
  padding: 24px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: relative;
  margin-right: 24px;
  width: 410px;

  @include desktop {
    width: 784px;
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0 19px;
    border: none;

    & input,
    select,
    textarea {
      width: 100%;
    }
  }
}

.title {
  @include fonts(700, 16px, 24px, $carbon);
  margin-bottom: 16px;
}

.subtitle {
  @include fonts(400, 14px, 24px, $carbon);
  margin-bottom: 22px;
}

.content {
  width: 100%;
}

.nothing {
  padding: 130px 0;
  display: flex;
  justify-content: center;
  text-align: center;

  & div h5 {
    @include fonts(500, 14px, 18px, $carbon);
    margin: 0 0 8px;
    padding: 0;
  }

  & div span {
    margin: 0;
    padding: 0;
    @include fonts(400, 14px, 18px, $black_coral);

    &:last-child {
      font-weight: 500;
      color: $jazzy_jade;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 30px 0;
  }
}

.members {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
}
