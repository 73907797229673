@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  padding: 120px 0;
}

.header_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header_wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bottom {
  margin: 0 0 90px !important;
}

.toptitle {
  padding: 0;
  @include fonts(400, 20px, 23.44px, $jazzy_jade);
  margin: 0 0 21px;
}

.title {
  padding: 0;
  @include fonts(600, 48px, 1.25em, $carbon);
  letter-spacing: -2.2%;

  margin: 0 0 27px;
}

.subtitle {
  padding: 0;
  @include fonts(400, 24px, 1.25em, $carbon);
  max-width: 23.6em;
  letter-spacing: -0.25px;
  margin: 0 0 90px;
}

.content {
  display: flex;
  justify-content: center;
}
