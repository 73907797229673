@import "src/styles/colors";
@import "src/styles/mixins";

.slider_element {
  width: 597px;
  height: 335px;
  border-radius: 12px;
  @include display-flex;
  cursor: pointer;


  & img {
    object-fit: cover;
    border-radius: 12px 0 0 12px;
    width: 334px;
    height: 100%;
  }

  & .text {
    width: 263px;
    height: 100%;
    background-color: #333333;
    border-radius: 0 12px 12px 0;
    padding: 25px 25px 0 0;

    & .header {
      @include display-flex(space-between);

      & div:first-child {
        width: 145px;
        @include fonts(400, 14px, 26px, #999999);
        background: #3d3d3f;
        border-radius: 0 100px 100px 0;
        text-align: center;
        vertical-align: middle;
        padding: 3px 0 1px;
      }

      & div:last-child {
        @include display-flex;

        width: 55px;
        height: 26px;
        background: #ffffff;
        border-radius: 20px;

        & span {
          @include fonts(400, 14px, 16px, $accent);
          letter-spacing: 0.2px;
          margin-top: 2px;
        }

        & svg {
          margin-right: 5.5px;
        }
      }
    }

    & .price {
      margin: 45px 0 0 19px;

      & span:first-child {
        @include fonts(500, 20px, 26px, $midday_sun);
      }

      & span:last-child {
        @include fonts(400, 14px, 26px, #999999);
      }
    }

    & .title {
      width: 216px;
      margin: 11px 0 0 19px;
      @include fonts(700, 20px, 27px, #ffffff);
    }

    & button {
      margin: 48px 0 0 19px;
      width: 130px;
      height: 42px;

      background: #ffdc8e;
      border: 1px solid $midday_sun;
      border-radius: 12px;

      @include fonts(700, 16px, 19px, $accent);
      text-align: center;

      &:hover{
        color: $jazzy_jade;
        background: white;
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: calc(100vw - 32px);
    height: 215px;
    margin-right: 16px;

    & img {
      width: 150px;
    }

    & .text {
      width: calc(100% - 150px);
      padding: 11px 11px 0 0;

      & .header {
        @include display-flex(space-between, flex-start);

        & div:first-child {
          width: 90px;

          @include fonts(400, 12px, 26px, #999999);
          background: inherit;
          border: none;
          padding: 0;
          margin-top: 22px;
          margin-left: 8px;
        }

        & div:last-child {
          @include display-flex;

          width: 45px;
          height: 18px;

          & span {
            @include fonts(600, 12px, 16px, $accent);
          }
          & svg {
            margin-right: 5px;
            width: 10px;
            height: 10px;
          }
        }
      }

      & .price {
        margin: 8px 0 0 8px;
        height: 26px;
        @include display-flex(flex-start);

        & span:first-child {
          @include fonts(500, 16px, 26px, $midday_sun);
        }

        & span:last-child {
          @include fonts(400, 12px, 26px, #999999);
        }
      }

      & .title {
        width: 150px;
        margin: 3px 0 0 8px;
        @include fonts(500, 16px, 23px, #ffffff);
      }

      & button {
        margin: 37px 0 11px 8px;
        width: 79px;
        height: 29px;
        border: none;
        border-radius: 8px;

        @include fonts(500, 12px, 10px, $accent);
        text-align: center;
      }
    }
  }
}
