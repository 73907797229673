@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  max-width: 1440px;
  margin: 118px auto 0 auto;
  position: relative;
  height: 288px;
  background: rgba(255, 244, 234, 0.9);
  @include display-flex;

  & .filter_img {
    position: absolute;
    height: 100%;
    width: 100%;

    background-image: url("../../../assets/images/EventLanding/EventLandingBack.png");
    background-color: rgba(248, 245, 230, 0.89);
    background-size: cover;
    opacity: 0.2;
    mix-blend-mode: luminosity;
  }

  & .content {
    @include display-flex;
    flex-direction: column;

    & h2 {
      @include fonts(700, 32px, 45px, $accent);
    }

    & p {
      margin-top: 12px;
      width: 575px;
      @include fonts(400, 20px, 36px, $accent);
      text-align: center;
    }

    & button {
      margin-top: 22px;
      width: 131px;
      height: 50px;
      background: $jazzy_jade;
      border-radius: 15px;
      @include display-flex;
      @include fonts(600, 18px, 18px, white);
      border: none;
      z-index: 1;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 96px auto 0 auto;
    height: 466px;

    & .content {
      padding: 0 23px;

      & h2 {
        @include fonts(700, 24px, 34px, $accent);
      }

      & p {
        margin-top: 20px;
        width: 336px;
        @include fonts(400, 18px, 32px, $accent);
        text-align: center;
      }

      & button {
        margin-top: 37px;
        width: 119px;
        height: 43px;
        border-radius: 12px;
        @include fonts(500, 14px, 10px, white);
        border: none;
      }
    }
  }
}
