@import "../../../../../../styles/colors";
@import "../../../../../../styles/mixins";

.card {
  padding: 12px 26px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 355px;
}

.header {
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  & svg {
    width: 50px;
    border-radius: 50%;
    margin-right: 26px;
    margin-bottom: 16px;
  }

  border-right: 1px solid #f5f5f5;
}

.top {
  margin-left: 17px;
  margin-bottom: 16px;
}

.name {
  @include fonts(700, 15px, 24px, #2d3748);
  margin-bottom: 9px;
}

.text {
  @include fonts(400, 14px, 20px, #787e91);
}

.content {
  padding-top: 7px;
}

.row {
  position: relative;

  & svg {
    width: 22px;
    color: #727a8499;
    margin-right: 27px;
    margin-left: 8px;
  }

  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
}

.option {
  width: 32px;
  height: 32px;
  background-color: #33333314;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  & svg {
    transition: all 0.1s linear;
    width: 24px;
    color: #787e91;
    transform: rotate(90deg);
  }

  z-index: 2;
}

.open__option svg {
  transform: rotate(0deg);
}

.modal {
  width: 161px;
  height: 100%;
  padding: 12px 0;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;

  position: absolute;
  z-index: 3;
  right: 0;
  bottom: -32px;

  & span {
    &:hover {
      background-color: $jazzy_jade;
      color: #f4f9fd;

      & svg {
        color: #f4f9fd;
      }
    }

    & svg {
      width: 17px;
      color: #727a84;
      margin-right: 13px;
    }

    display: flex;
    align-items: center;
    padding: 10px 20px;
    @include fonts(400, 14px, 21px, #727a84);
    cursor: pointer;
  }
}

.drop {
  display: flex;
  align-items: center;
  transition: all 0.1s linear;

  & svg {
    width: 12px;
    color: #727a84;
  }

  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.reverse_drop {
  transform: rotateX(180deg) translateY(50%);
}
