@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  max-width: 1114px;
  margin: 30px auto 0;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  padding: 24px;

  & .title {
    @include fonts(500, 16px, 24px, $accent);
  }

  @media screen and (max-width: 500px) {
    border: none;
    padding: 0;

    & .title {
      @include fonts(700, 14px, 24px, $accent);
    }
  }

  & .medias__group {
    margin-top: 24px;
    @include display-flex(stretch, flex-end);
    gap: 24px;

    & div {
      margin: 0;
      flex: 0 1 50%;
      & div {
        width: 100%;
      }
    }
    @media screen and (max-width: 500px) {
      margin-top: 12px;
      @include display-flex;
      flex-direction: column;
      gap: 12px;

      & div {
        margin: 0;
        flex: 0 1 100%;
        width: 100%;

        & div {
          width: 100%;
        }
      }
    }
  }
  & .wrapper_medias {
    margin-top: 23px;
    @media screen and (max-width: 500px) {
      margin-top: 6px;
    }
  }
}
