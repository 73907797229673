@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  @include display-flex(flex-start, center);
  flex-direction: column;
  margin: 32px auto 0 auto;
  position: relative;
  max-width: 1114px;

  .line {
    width: 1px;
    height: 48px;
    border-left: 1px solid #f5f5f5;
  }

  .added_sponsors {
    padding: 10px 24px;
    background-color: white;
    border-radius: 20px;

    span {
      @include fonts(400, 14px, 20px, #595959);
    }
    span:last-child {
      font-weight: 600;
    }
  }
}

.marketing {
  padding: 30px 24px 0 24px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  background-color: white;

  h4 {
    @include fonts(700, 16px, 24px, $accent);
  }

  .card_block {
    @include display-flex(flex-start);
    flex-wrap: nowrap;
  }

  .pagination {
    @include display-flex(space-between);
    margin-top: 25px;

    button.arrow_prev,
    button.arrow_next {
      padding: 20px 24px;
      line-height: 18px;

      svg {
        height: 22px;
      }
    }

    .arrow_prev {
      border-right: 1px solid #ededed;
      cursor: pointer;
    }

    .arrow_prev_end {
      color: #51c9c2;
    }

    .arrow_next {
      border-left: 1px solid #ededed;
      cursor: pointer;
    }
    button {
      border: none;
      background-color: inherit;
    }

    .pagination_button {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 8px;
      @include fonts(400, 14px, 16px, $accent);
      cursor: pointer;

      &:hover {
        border: 1px solid #f4f4f4;
      }
    }

    .pagination_button:last-child {
      margin-right: 0;
    }
  }
}
