@import "src/styles/colors";
@import "src/styles/mixins";

.organizations {
  padding: 50px 100px;

  h3 {
    @include fonts(700, 28px, 44.5px, $carbon);
    max-width: 734px;
    margin-bottom: 63px;
  }
}

.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & img {
    height: 80px;
  }
}
