@import "src/styles/colors";
@import "src/styles/mixins";

.section {
  padding-top: 35px;
  padding-bottom: 78px;
  .total {
    position: absolute;
    top: -30px;
    right: 23px;
    display: block;
    @include fonts(400, 18px, 32px, #787E91);
  }

  .container {
    margin: 0 auto;
    padding: 0 20px;
    width: 1392px;
    display: flex;

    .wrapper {
      width: 100%;
      position: relative;
      margin-right: 15px;

      .roster {
        margin-bottom: 15px;
      }

      .btn {
        width: 100%;
        height: 60px;
        border: 1px solid #EAECEE;
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        @include fonts(500, 20px, 23px, $accent);
        @include display-flex();
        align-content: center;

        .icon{
          margin-right: 16px;

        }

        .btnText {
          position: relative;
          top: 2px;

        }
      }
    }
  }
}
