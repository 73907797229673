@import "src/styles/colors";
@import "src/styles/mixins";

.card {
  height: 126px;
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding-left: 7px;
  @include display-flex(space-between);

  max-width: 1114px;
  margin: 23px auto 0;

  & figure {
    @include display-flex(space-between);
    margin-bottom: 0;

    & img {
      width: 124px;
      height: 114px;
      border: 1px solid #eaecee;
      border-radius: 8px;
      object-fit: cover;
    }

    & figcaption {
      margin-left: 17px;
      @include display-flex(center, flex-start);
      flex-direction: column;

      & button{
        background-color: $jazzy_jade;
        border-radius: 5px;
        border: none;
        @include fonts(600, 12px, 14px, #ffffff);
        @include display-flex;
        padding: 0 10px;
        height: 25px;
      }

      & .title {
        @include fonts(600, 16px, 19px, $accent);
        margin-top: 21px;
      }

      & .description {
        @include fonts(400, 12px, 18px, #676b79);
        margin-top: 6px;
      }
    }
  }

  & .quantity {
    @include display-flex();
    height: 100%;

    & .quantity_block {
      @include display-flex();
      height: 100%;
      padding: 24px 0;

      & span {
        @include fonts(500, 14px, 16px, #676b79);
      }

      & .cost {
        @include display-flex();
        flex-direction: column;

        & span:first-child {
          @include fonts(600, 16px, 19px, $accent);
          margin-bottom: 8px;
        }
      }

      & .line {
        height: 100%;
        width: 1px;
        border-left: 1px solid #f5f5f5;
        margin: 0 50px;
      }

      & .available {
        @include display-flex();
        flex-direction: column;
        margin-right: 195px;

        & span:first-child {
          @include fonts(600, 18px, 21px, $accent);
          margin-bottom: 7px;
        }
      }
    }

    & .more_mini {
      align-self: flex-start;
      margin-top: 15px;
      cursor: pointer;

      & svg {
        height: 22px;
        cursor: pointer;
        margin-right: 17px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 337px;
    height: 202px;
    padding: 6px 7px;
    @include display-flex(flex-start, flex-start);
    flex-direction: column;

    max-width: 1114px;
    margin: 23px auto 0;

    & figure {
      @include display-flex(space-between);
      margin-bottom: 0;

      & img {
        width: 124px;
        height: 114px;
        border: 1px solid #eaecee;
        border-radius: 8px;
        object-fit: cover;
      }

      & figcaption {
        margin-left: 11px;

        & .title {
          @include fonts(600, 14px, 16px, $accent);
          margin-top: 12px;
        }

        & .description {
          width: 130px;
          @include fonts(400, 12px, 18px, #676b79);
          margin-top: 5px;
        }
      }
    }

    & .quantity {
      @include display-flex();
      height: 83px;
      width: 100%;
      position: relative;

      & .quantity_block {
        @include display-flex();
        height: 100%;
        width: 100%;
        margin-top: 3px;
        padding: 19px 0;

        & span {
          @include fonts(500, 14px, 16px, #676b79);
        }

        & .cost {
          @include display-flex();
          flex-direction: column;

          & span:first-child {
            @include fonts(600, 16px, 19px, $accent);
            margin-bottom: 8px;
          }
        }

        & .line {
          height: 100%;
          width: 1px;
          border-left: 1px solid #f5f5f5;
          margin: 0 50px;
        }

        & .available {
          @include display-flex();
          flex-direction: column;
          margin-right: 0;

          & span:first-child {
            @include fonts(600, 18px, 21px, $accent);
            margin-bottom: 7px;
          }
        }
      }

      & .more_mini {
        position: absolute;
        width: 22px;
        height: 22px;
        align-self: flex-start;
        margin-top: 0;
        top: -104px;
        right: 6px;
        cursor: pointer;

        & svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
