@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  padding: 24px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: relative;

  @media screen and (max-width: 500px) {
    padding: 0;
    border: none;
  }
}
