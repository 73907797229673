@import "../../../../../../styles/colors";
@import "../../../../../../styles/mixins";

.content {
  display: flex;
  align-items: center;

  & svg {
    width: 50px;
    margin-right: 23px;
  }

  margin-top: 24px;
  margin-left: 12px;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    margin: 0;
  }
}

.button {
  display: flex;
  align-items: center;
  padding: 13px;

  & svg {
    width: 24px;
    color: #1818196b;
    margin-right: 17px;
  }

  @include fonts(400, 16px, 22.4px, $carbon);
  border: 1px dashed #e5e5e5;
  border-radius: 8px;

  @media screen and (max-width: 500px) {
    padding: 12px;
    width: calc(100% - 73px);

    & svg {
      width: 22px;
      margin-right: 12px;
    }

    @include fonts(400, 14px, 20px, $carbon);
  }
}
