.event {
  padding: 22px 20px 0;
  background: transparent;
  position: relative;
  max-width: 1126px;
  width: 100%;
  margin: 0 auto;
  //z-index: -1;

  @media screen and (max-width: 1050px) {
    padding: 33px 19px 66px 19px;
  }
}
