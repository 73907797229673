@import "colors";
@import "mixins";

//==========
// Buttons
//==========
.button {
  @include fonts(500, 20px, 0.525em, transparent);
  padding: 1.1em 2em;
  border-radius: 0.75em;
}

.bordered {
  border: 0.05em solid $carbon;
  color: $carbon;

  &:hover {
    color: $carbon;
  }
}

.solid {
  background-color: $carbon;
  box-shadow: 0.1em 0.1em 0.25em 0 #00000030;
  box-shadow: -0.1em -0.1em 0.25em 0 #ffffff1a;
  color: white;

  &:hover {
    color: white;
  }
}

// =========
// Titles
// =========

.landing_title {
  @include fonts(600, 48px, 1.25em, $carbon);
  letter-spacing: -2.2%;
}

.landing_subtitle {
  margin: 36px 0 0;
  padding: 0;
  @include fonts(400, 24px, 1.25em, $carbon);
  max-width: 23.6em;
  letter-spacing: -0.25px;
}
