@import "src/styles/colors";
@import "src/styles/mixins";

.wrapper {
  padding: 22px 44px 0 32px;
  background: #fdfdfd;
  width: 100%;
  //height: 100%;
  position: relative;

  & .tools {
    margin-top: 40px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 19px 66px 19px;
  }

  & .tools {
    margin-top: 24px;
  }
}
