@import "src/styles/colors";
@import "src/styles/mixins";

.item {
  padding: 13px 11px;
  width: 169px;
  height: 60px;
  background: #E5E5E5;
  border-radius: 8px;
  margin-right: 31px;
  border: 1px solid #979797;
  @include desktop {
    width: 258px;
    height: 122px;
    padding: 31px 23px;


  }

  .data {
    @include display-flex(flex-start);
    .img {
      width: 30px;
      height: 30px;

      @include desktop {
        width: 60px;
        height: 60px;
      }
    }
    .meta {
      margin-left: 6px;

      @include desktop{
        margin-left: 15px;
      }

      .stats {
        @include fonts(700, 16px, 19px, $accent);
        margin-bottom: 1px;

        @include desktop {
          @include fonts(700, 24px, 28px, $accent);
          margin-bottom: 5px;
        }
      }
      .text {
        @include fonts(400, 12px, 14px, $accent);

        @include desktop {
          @include fonts(400, 15px, 18px, $accent);
        }
      }

    }
  }
}
