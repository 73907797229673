@import "src/styles/colors";
@import "src/styles/mixins";

.block {
  padding: 16px 0 12px;

  .container {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    @include tablet {
      width: 740px;
    }
    @include desktop{
      width: 1165px;
      padding: 0 32px;
    }

    .list {
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 20px;
      overflow-x: scroll;
      @include tablet{
        align-items: center;
        justify-content: center;
        grid-template-columns: auto auto;
        overflow-x: unset;

      }
      @include desktop {
        gap: 30px;
        grid-template-columns: auto auto auto auto;

      }
    }
  }
}
