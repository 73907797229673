@import "src/styles/colors";
@import "src/styles/mixins";

.blank {
  padding: 130px 0;
  display: flex;
  justify-content: center;
  text-align: center;

  & div h5 {
    @include fonts(500, 14px, 18px, $carbon);
    margin: 0 0 8px;
    padding: 0;
  }

  & div span {
    margin: 0;
    padding: 0;
    @include fonts(400, 14px, 18px, $black_coral);

    &:last-child {
      font-weight: 500;
      color: $jazzy_jade;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 30px 0;
  }
}
