@import "src/styles/colors";
@import "src/styles/mixins";

.container {
  padding: 0 20px;
  width: 100%;

  @include tablet {
    padding: 0 30px;
  }

  .list {
    padding: 8px 0;
    border: 1px solid #C9CCD3;
    border-radius: 5px;
    list-style: none;
    background-color: #ffffff;
    margin-bottom: 29px;
    @include display-flex();
    @include tablet {
      padding: 0;
      margin: 0;
      border: 0;
      @include display-flex(flex-start);
    }

    .item {

      &:not(:last-child) {
        margin-right: 8px;

        @include tablet {
          margin-right: 30px;
        }
      }
      .link {
        @include display-flex();
        width: 150px;
        height: 32px;
        letter-spacing: 0.01em;
        background-color: #F2F2F2;
        border-radius: 5px;
        @include fonts(500, 12px, 25px, #777D90);

        @include tablet {
          width: unset;
          height: unset;
          background-color: transparent;
          border-radius: unset;
          @include fonts(500, 14px, 20px, #787E91);
          margin-bottom: 20px;

        }

      }
      .link.active {

        background-color: #51C9C2;
        @include fonts(500, 12px, 25px, #ffffff);

        @include tablet {
          position: relative;
          background-color: transparent;
          @include fonts(600, 14px, 20px, $accent);

          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 3px;
            background-color: #51C9C2;
            border-radius: 3px;
            bottom: -20px;
          }
        }

      }
    }

  }
}
.line {
  display: none;

  @include tablet {
    display: block;
    position: relative;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #EDF2F7;
    margin-bottom: 20px;
  }
}
