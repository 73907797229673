@import "src/styles/colors";
@import "src/styles/mixins";

.list {
  list-style: none;
  @include tablet {
    display: flex;
  }
  .height_block {
    @include tablet {
      border-radius: 10px 0 0 10px;
      overflow: hidden;
    }

    @include tablet {
      margin-right: 5px;
    }


    .first {
      height: 673px;
      width: 335px;

      @include less-tablet {
        margin: 0 auto;
      }

      &__slide {
        position: relative;
        display: flex!important;
        overflow: hidden;

      @include less-tablet {
        border-radius: 10px 10px 0 0;

      }


        .img1 {
          height: 673px;

        }

        .back {
          background: linear-gradient(180deg, rgba(7, 2, 51, 0.51) 0%, rgba(7, 2, 51, 0.51) 91.67%, rgba(7, 2, 51, 0.51) 100%);;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .first__name {
          @include fonts(400, 20px, 24px, #fff);
          position: absolute;
          top: 585px;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: 0.42px;
          z-index: 6;
          text-align: center;
        }

        .first__duties {
          @include fonts(400, 16px, 24px, rgba(255, 255, 255, 0.65));
          letter-spacing: 0.42px;
          position: absolute;
          top: 615px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 6;
          text-align: center;
        }
      }
    }
  }

  .square_block {
      overflow: hidden;
    @include tablet {
      border-radius: 0 10px 10px 0;

    }

    .second {
      height: 334px;
      width: 335px;
      margin-bottom: 5px;

      @include less-tablet {
        margin: 0 auto;
      }

      &__slide {
        position: relative;

        .img2 {
          height: 334px;
          width: 335px;
        }

        .back {
          background: linear-gradient(180deg, rgba(7, 2, 51, 0.08) 31.25%, rgba(7, 2, 51, 0.78) 100%);
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .second__name {
          @include fonts(400, 20px, 24px, #fff);
          position: absolute;
          top: 243px;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: 0.42px;
          z-index: 6;
          text-align: center;
        }

        .second__duties {
          @include fonts(400, 16px, 24px, rgba(255, 255, 255, 0.65));
          letter-spacing: 0.42px;
          position: absolute;
          top: 273px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 6;
          text-align: center;
        }
      }
    }

    .third {
      height: 334px;
      width: 335px;

      @include less-tablet {
        margin: 0 auto;
      }

      &__slide {
        position: relative;
      @include less-tablet {
        overflow: hidden;
        border-radius: 0 0 10px 10px;

      }
        .img2 {
          height: 334px;
          width: 335px;
        }

        .back {
          background: linear-gradient(180deg, rgba(7, 2, 51, 0.08) 31.25%, rgba(7, 2, 51, 0.78) 100%);
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .third__name {
          @include fonts(400, 20px, 24px, #fff);
          position: absolute;
          top: 243px;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: 0.42px;
          z-index: 6;
          text-align: center;
        }

        .third__duties {
          @include fonts(400, 16px, 24px, rgba(255, 255, 255, 0.65));
          letter-spacing: 0.42px;
          position: absolute;
          top: 273px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 6;
          text-align: center;
        }
      }
    }
  }
}
