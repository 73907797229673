@import "src/styles/colors";
@import "src/styles/mixins";

.content {
  position: absolute;
  @include display-flex(flex-start);
  flex-wrap: nowrap;
  top: 58px;

  @media screen and (max-width: 500px) {
    top: 0;
    margin-left: 16px;
  }
}

.button {
  margin-top: 312px;
  width: 100%;
  @include display-flex(flex-end);
  padding-right: 54px;

  & span {
    @include fonts(600, 17px, 20px, rgba(51, 51, 51, 0.8));
    margin-right: 22px;
  }

  .svg_cicl {
    & circle,
    path {
      transition: all 1s ease-in-out;
    }
  }

  & .arrow {
    width: 32px;
    height: 32px;
    background: transparent;
    cursor: pointer;
    position: relative;
    border: none;
    z-index: 3;

    &:nth-child(2) {
      margin-right: 13px;
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 236px;
    width: 100%;
    @include display-flex(flex-end);
    padding-right: 18px;

    & span {
      @include fonts(600, 14px, 16px, rgba(51, 51, 51, 0.8));
      margin-right: 10px;
    }

    & .svg_cicl {
      position: absolute;
      top: -4px;
      left: -4px;
      transform: scale(0.75);
    }

    & .arrow {
      width: 23px;
      height: 23px;

      &:nth-child(2) {
        margin-right: 16px;
      }
    }
  }
}
